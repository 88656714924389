/* eslint-disable no-useless-escape */
/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { TextField, Button, Grid } from "@material-ui/core";
import FWLogo from "../../assets/TheFamilyWinsLogo.png";
import { TransverseLoading } from "react-loadingg";
import "./login.scss";
import { connect } from "react-redux";
import GetData from "./loginFetch";
import GetDataUser from '../../user/fetchDataGet';
import Fade from "react-reveal/Fade";
import { setUser, setDate,setActualDate } from "../../store/actions";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [errorValue, setErrorValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    if(loading===true)
    {
      return false;
    }
    if (Errors() == true) {
      return;
    }

    let req = {
      Method: "LOCAL",
      MethodIdentity: email,
      MethodPasscode: password,
      DeviceKey: "MyPC",
      ApplicationKey: "MyBrowser",
      Platform: "DESKTOP",
    };
    // console.log(props.HerokuHost)
    // let req="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiIxIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZSI6Ikd1ZXN0IiwicHJvZmlsZWlkIjoiMSIsInVzZXJuYW1lIjoiZGFubnkiLCJleHAiOjE1OTU4NzQ3ODYsImlzcyI6ImRpZ2l0YWwtd2F6ZS5jb20iLCJhdWQiOiJkaWdpdGFsLXdhemUuY29tIn0.PpQ33aJmVnW9YmlPG0fGMKW6iGBF2Kw8rqVG4H6q6PY"
    setLoading(true);
    GetData(props.HerokuHost + "/account/login", 200, req, checkLogin);
    // props.history.push("temp-auth")
  };

  const ValidateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(mail)) {
      return true;
    }
    return false;
  };

  const Errors = () => {
    if (email === "" || email === " ") {
      setError(true);
      setErrorValue("Please enter email");
      return true;
    } else if (!ValidateEmail(email)) {
      setError(true);
      setErrorValue("Email badly formatted");
      return true;
    } else if (password === "" || password === " ") {
      setError(true);
      setErrorValue("Please enter password");
      return true;
    }
    return false;
  };

  const CheckZero = (number) => {
    if (number <= 9) {
      return "0" + number;
    } else return number;
  };

  const setCookie = (cname, cvalue, exmins) => {
    var d = new Date();
    d.setMinutes(d.getMinutes() + exmins);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  const checkLogin = (response, token) => {

    // console.log('here')
    if (response.responseCode === "Success") {
      if (response.response) {
       

        GetDataUser(
          props.HerokuHost + "/account/challenge/progress",
          200,
          token,
          (res)=>{ 
            // console.log(res);
            let date = new Date();
            let Formatted_date =CheckZero(date.getMonth() + 1) +
              "-" +
              CheckZero(date.getDate()) +
              "-" +
              date.getFullYear();
    
            let user = response.response.user;
            // console.log('user' , user);
            // console.log('rchallenge' , res.response.challenge);

            let Newuser= { ...user, token: token,challenge:res.response.challenge,lastActivityOn:res.response.lastActivityOn};

            props.setUser(Newuser);
            props.setDate(Formatted_date);
            props.setActualDate(date);

            setCookie('token',token,30);
            setCookie('user',JSON.stringify(Newuser),30);
            // console.log(res.response.challenge);
            // setCookie('challenge',JSON.stringify(res.response.challenge),30);           
            // setLoading(false);
            // props.history.push("/dashboard"); 
          } ,
          onFailed
        );
      }
    } else if (response.statusText === "Unauthorized" || response.title === "Unauthorized" || response.status?.toString() === "401") {
      console.log("error on login => ", response);
      setError(true);
      setLoading(false);
      setErrorValue("Email or Password wrong.");
    }
  };

  const onFailed = (response) => {
    console.log("error on login challenge => ", response);
    setLoading(false);
    alert("Something went wrong...");
  };

  const handleChange = (event) => {
    if (event.target.id === "email") {
      setError(false);
      setErrorValue("");
      setEmail(event.target.value);
    } else if (event.target.id === "password") {
      setError(false);
      setErrorValue("");
      setPassword(event.target.value);
    }
  };
  return (
    <Grid className="Login-Main-Wrapper" container>
      <div className="Login-Main-Container">
        <div className="Login-Logo-Container">
          <img src={FWLogo} alt="Logo" className="Login-Logo" />
        </div>

        <form onSubmit={handleLogin}>
          <div className="Login-Form-Container">
            <div className="Login-Container">
              <div className="Login-Email">
                <TextField
                  id="email"
                  label="Email"
                  fullWidth
                  value={email}
                  onChange={handleChange}
                />
              </div>
              <div className="Login-Password">
                <TextField
                  id="password"
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={handleChange}
                  fullWidth
                />
              </div>
              <Fade bottom when={error}>
                <div className="login-error"> {errorValue} </div>
              </Fade>
              <div
                className="Login-Forgot-Password"
                onClick={() => props.history.push("./forgot")}
              >
                Forgot my password
              </div>
              <div className="Login-Btn">
                <Button
                  id="Login-Button"
                  variant="contained"
                  fullWidth
                  // onClick={handleLogin}
                  disabled={loading}
                  style={{ padding: loading ? "30px 0" : "15px 0" }}
                  type="submit"
                >
                  {loading ? (
                    <TransverseLoading color="#225b76" size="large" />
                  ) : (
                    "Sign In"
                  )}
                </Button>
              </div>
            </div>
            <div className="Login-NewAccount-Cont">
              <div className="Login-NewAccount-Text">Not a member?</div>
              <div className="Login-CreateAcc-Btn">
                <Button
                  id="CreatAccBtn"
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => props.history.push("./signup")}
                  disabled={loading}
                >
                  Create Account
                </Button>
              </div>
            </div>
          </div>
        </form>
        
      </div>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    user:state.setUser,
    HerokuHost: state.setHerokuHost,
  };
};

export default connect(mapStateToProps, { setUser, setDate,setActualDate })(Login);
