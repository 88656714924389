import React from "react";
import ReactPlayer from "react-player/lazy";
import ShowMoreText from "react-show-more-text";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import IconButton from "@material-ui/core/IconButton";

import {
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Card,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    maxWidth: 310,
    minWidth: 310,
    minHeight: 290,
    margin: "12px",
    boxShadow: "none",
  },
  cardContentRoot: {
    padding: 0,
    paddingTop: 8,
  },
  heading: {
    fontSize: "1.15rem",
    fontWeight: "bold",
  },
});

const VideoCard = (props) => {
  const classes = useStyles();
  const  {handleToggleFavourite,isFavourite,video} = props;

  const handleCardClick = () => {
    props.openDialoge();
  };


  return (
    <Card className={classes.root}>
      <CardActionArea onClick={handleCardClick}>
        <CardMedia
          component={() => (
            <ReactPlayer
              url={video?.url}
              light
              controls
              width="100%"
              height={168.75}
            />
          )}
          alt="Video"
          title="Watch video"
        />

        {
          !isFavourite  &&
          <div id="favourite-icon-div">
            <IconButton aria-label="delete">
              {
                video?.isFavourite ?
                <FavoriteIcon style={{color:"#225b76"}} onClick={(e)=>handleToggleFavourite(e,video.id, video?.isFavourite)} /> :
                <FavoriteBorderIcon style={{color:"#225b76"}} onClick={(e)=>handleToggleFavourite(e,video.id,video?.isFavourite)}/>
              }
            </IconButton>
          </div>
        }
      </CardActionArea>

      <CardContent classes={{ root: classes.cardContentRoot }}>
        <Typography
          classes={{ root: classes.heading }}
          variant="h6"
          component="h1"
        >
          {video?.title}
        </Typography>
        <Typography variant="body2" color="textPrimary" component="div">
          <ShowMoreText lines={6} width={300} expanded={false}>
            {video?.description}
          </ShowMoreText>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default VideoCard;
