import React from "react";
import "./StudyGroups.scss";
import { connect } from "react-redux";


const Message = (props) => {

  const isByMe = props.message.user.userId.toString() === props.userId.toString() ? true : false;
  const isByAdmin = props.message.isByAdmin;
  // var generatedTime =   new Date(new Date(props.message.postDate).toLocaleString() + " UTC");

  // const time = generatedTime.getHours();
  // const hours =  generatedTime.getHours();
  // const mins = generatedTime.getMinutes() < 10 ? '0'+generatedTime.getMinutes() : generatedTime.getMinutes() ; 
  // const AmorPm = hours > 12 ? 'PM' : 'AM';
  // const calculatedHours = hours > 12 ? hours-12 : hours;
  return (
    <div
      className="Msg-wrapper"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent:  isByMe ? "flex-end" : "flex-start",
      }}
    >
      <div
        className="Msg-Container"
        style={
          isByMe
            ? {
                backgroundColor: "#2c7178",
                color: "white",
                boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.5)",
              }
            
            : isByAdmin ? {
                backgroundColor: "#F8FAE2",
                color: "black",
                boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.2)",
              }
            : {
                backgroundColor: "white",
                boxShadow: "-1px 2px 3px 1px rgba(0, 0, 0, 0.2)",
              }
        }
      >
        <div className="Msg-SenderName">{props.message.user.name} {isByAdmin? " (Group Leader)" :null }</div>
        <div className="Msg-Content" style={{paddingRight:!isByMe?'50px':''}}>
          {props.message.text}
          <span className={isByMe ? "Msg-Date-Me" : "Msg-Date-Others" }>
            {props.calculatedHours}:{props.mins} {" "} {props.AmorPm}
          </span>
        </div>
        
       
        { isByMe ? (
          <div className="Msg-triangle-right"></div>
        ) : (
          <div className={isByAdmin?"Msg-triangle-left-admin":"Msg-triangle-left"} ></div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.setUser.userId,
  };
};

export default connect(mapStateToProps, {})(Message);

