import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import UserDashboard from "./dashboard/dashboard.jsx";
import { Grid, Drawer } from "@material-ui/core";
import GoDeeper from "./GoDeeper/GoDeeper.jsx";
import Journal from "./Journal/Journal.jsx";
import Watch from "./Watch/Watch.jsx";
import SideBar from "../containers/sidebar/sideBar";
import Header from "../containers/header/header";
import Listen from "./listen/listen.jsx";
import ReadAndReflect from "./ReadAndReflect/ReadAndReflect";
import Comments from "./Comments/Comments.jsx";
import StudyGroups from "./StudyGroups/StudyGroups.jsx";
import Companions from "./Companions/Companions.jsx";
import TrackProgress from "./TrackProgress/TrackProgress.jsx";
import EditProfile from "./EditProfile/EditProfile.jsx";
import Worship from "./Worship/Worship";
import Logo from '../assets/TheFamilyWinsLogo.png'

import "./userRoutes.css";
import DateComp from "../containers/dateComp/dateComp.jsx";
import Favourites from "./Favourites/Favourites.jsx";

class UserRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DrawerState: false,
    };
  }

  componentDidMount() {
    // console.log("User Routes");
  }

  handleToggleDrawer = (isOpen) => {
    this.setState({ DrawerState: isOpen });
  };

  render() {
    return (
      <Grid container direction="row">
        <Grid item sm={2} md={2} lg={2}>
          <SideBar history={this.props.history} isOpen={this.state.DrawerState} closeDrawer={this.handleToggleDrawer} />
        </Grid>
        <Grid item sm={12} md={12} lg={12}>
          <Header openDrawer={this.handleToggleDrawer} history={this.props.history}/>
         
          {
            this.props.history?.location?.pathname !== "/favourites" &&
            <DateComp />
          }
          <main className="route-main">
            <Switch>
              <Route path="/dashboard" component={UserDashboard} />
              <Route path="/go-deeper" component={GoDeeper} />
              <Route path="/journal" component={Journal} />
              <Route path="/listen" component={Listen} />
              <Route path="/read-and-reflect" component={ReadAndReflect} />
              <Route path="/watch" component={Watch} />
              <Route path="/worship" component={Worship} />
              <Route path="/comments" component={Comments} />
              <Route path="/study-groups" component={StudyGroups} />
              <Route path="/companions" component={Companions} />
              <Route path="/progress" component={TrackProgress} />
              <Route path="/edit-profile" component={EditProfile} />
              <Route path="/favourites" component={Favourites} />

              <Redirect to="/dashboard" />
            </Switch>
          </main>
        </Grid>
      </Grid>
    );
  }
}

export default UserRoutes;
