import React, { Component } from "react";
import { Button, TextField, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { setUser } from "../../store/actions";
import { TransverseLoading } from "react-loadingg";
import GetData from "../fetchDataGet.jsx";
import PostData from "../fetchDataPost";
import "./Journal.scss";
import Slide from 'react-reveal/Slide';


class Journal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      editMode: false,
      btnLoading: false,
      journal: null,
      editedContent: "",
    };
  }

  componentDidMount() {
    this.getJournal(this.props.date);
  }

  componentDidUpdate(oldprops) {
    if (oldprops.date !== this.props.date) {
      this.getJournal(this.props.date);
    }
  }

  getJournal = (date) => {
    this.setState({ loading: true, journal: null });
    GetData(
      this.props.HerokuHost + `/account/journal/${date}`,
      200,
      this.props.token,
      (res) =>
        this.setState({
          journal: res.response.journal,
          loading: false,
          editedContent: res.response.journal
            ? res.response.journal.content
            : null,
        }),
      this.onFailed
    );
  };

  postJournal = () => {
    if (!this.state.editMode) {
      return;
    }
    this.setState({ btnLoading: true });
    const newJournal = this.state.editedContent;
    PostData(
      this.props.HerokuHost + `/account/journal/${this.props.date}`,
      newJournal,
      200,
      this.props.token,
      (res) => {
        this.setState({
          journal: { ...this.state.journal, content: this.state.editedContent },
          btnLoading: false,
        });
        this.changeMode(false);
      },
      this.onFailed
    );
  };

  onFailed = (response) => {
    console.log(response);
    alert("Failed response");
    this.setState({ btnLoading: false });
  };

  changeMode = (bool) => {
    this.setState({ editMode: bool });
  };

  handleEditChange = (e) => {
    this.setState({ editedContent: e.target.value });
  };

  render() {
    const { classes } = this.props;

    const rows = ((window.document.body.clientHeight-320-100-37)/19) < 3 ? 3 : ((window.document.body.clientHeight-320-100-37)/19) ;
  //  console.log(rows)
    return this.state.loading ? (
      <div className="journal-loader-wrraper">
        <TransverseLoading color="#225b76" size="large" />
      </div>
    ) : (
      <Slide left>
      <div className="JO-Wrapper">
        <div className="JO-Container">
          <div className="JO-Title">My Journal</div>
            {this.state.editMode ? (
              <div
                className="JO-EditJournal"
                onClick={() => this.changeMode(false)}
              >
                Back
              </div>
            ) : (
              <div
                className="JO-EditJournal"
                onClick={() => this.changeMode(true)}
              >
                Edit Journal
              </div>
            )}

          {this.state.editMode ? (
            <div className="JO-Edit-Content">
              <TextField
                label=""
                variant="outlined"
                fullWidth
                multiline
                rows={rows}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                value={this.state.editedContent}
                onChange={this.handleEditChange}
              />
            </div>
          ) : (
            <div className="JO-Content">
              {this.state.journal
                ? this.state.journal.content.split("\n").map((item, i) => {
                    return <p key={i}>{item}</p>;
                  })
                : <span className="jo-content-no-journal"> Your Journal for <span className="jo-content-no-journal-date"> {this.props.date} </span>  goes here...  </span>}
            </div>
          )}

          <Button
            id="JO-Button"
            variant="contained"
            style={{ padding: this.state.btnLoading ? "23px 0" : "11px 0" }}
            disabled={this.state.btnLoading}
            onClick={this.postJournal}
          >
            {this.state.btnLoading ? (
              <TransverseLoading color="#ffffff" size="large" />
            ) : this.state.editMode ? (
              "Update"
            ) : (
              "save"
            )}
          </Button>
        </div>
      </div>
      </Slide>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.setUser.userId,
    userName: state.setUser.userName,
    HerokuHost: state.setHerokuHost,
    token: state.setUser.token,
    date: state.setDate.date,
  };
};

const style = (theme) => ({
  notchedOutline: {
    borderWidth: "1.5px",
    borderColor: "#989898",
  },
});

export default connect(mapStateToProps, { setUser })(
  withStyles(style)(Journal)
);
