/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import {
  // Dialog,
  // DialogTitle,
  // DialogContent,
  // DialogContentText,
  withStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import { TransverseLoading } from "react-loadingg";
import { animateScroll,Link } from "react-scroll";

import "../../user/Comments/Comments.scss";
import { Grid, TextField, Button } from "@material-ui/core";
import Comment from "./comment.jsx";
import GetData from "../../user/fetchDataGet.jsx";
import PostData from "../../user/fetchDataPost.jsx";
import DeleteData from "../../user/fetchDataDelete";
import Slide from "react-reveal/Slide";
import Skeleton from "../../user/Comments/CommentsSkeleton";

// import { backend } from "../../user/Comments/dummycomments";

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      dialogOpen: false,
      commentsArray: [],
      ReplyCommentIDs: null,
      commentText: "",
      ReplyCommentUsername: "",
      // dialogLikes: [],
    };
    this.textInput = React.createRef();
    this.focusTextInput = this.focusTextInput.bind(this);
  }

  componentDidMount() {
    this.getComments(this.props.date);
  }

  componentDidUpdate(oldprops) {
    if (oldprops.date !== this.props.date) {
      this.getComments(this.props.date);
    }
  }

  getComments = (Date) => {
    this.setState({
      loading: true,
      dialogOpen: false,
      commentsArray: [],
      ReplyCommentIDs: null,
      commentText: "",
      ReplyCommentUsername: "",
    });
    GetData(
      this.props.HerokuHost + `/comments/${Date}`,
      200,
      this.props.token,
      (res) => {
        console.log(res.response.comments);
        // it will change the threaded comment array format into a linear comment array format
        res.response.comments.map((ParentComment) => {
          let arr = [];
          this.Recursive(ParentComment.replies, arr);
          let DateSortedArr = this.sorter(arr);
          ParentComment.replies = DateSortedArr;
          arr = [];
        });
        this.setState({ commentsArray: res.response.comments, loading: false },(()=>{this.scrollToBottom()}));
      },
      this.onFailed
    );
  };

  onFailed = (response) => {
    console.log(response);
    alert("Failed response");
  };

  postHeart = (ParentCmtID, ReplyCmtID, ChangeHeart) => {
    // send the post api to backend with [parentCmtID] or [ReplyCmtID] or both depend on the comment/reply
    // when the backend is successfully updated then you can change your frontEnd here by calling this function
    // frontEnd and Backend will at the same state
    this.change_FrontEnd_State_After_PostHeart(ParentCmtID, ReplyCmtID, ChangeHeart);
    PostData(
      this.props.HerokuHost + `/comments/${ReplyCmtID ? ReplyCmtID : ParentCmtID}/like`,
      "",
      200,
      this.props.token,
      (data) => {
        data.responseCode === "Success"
          ? null
          : this.change_FrontEnd_State_After_PostHeart(ParentCmtID, ReplyCmtID, ChangeHeart);
      },
      this.onFailed
    );
  };

  postComment = () => {
    // send the api to backend to push new comment in parrent comments. if its a reply then send this.state.ReplyCommentIDs
    // to push new reply to that specific parent comment
    // when the backend is successfully updated then you can change your frontEnd here by calling this function
    // frontEnd and Backend will at the same state
    let api;
    let ReplyCommentIDsCopy = this.state.ReplyCommentIDs;
    this.state.ReplyCommentIDs !== null
      ? this.state.ReplyCommentIDs.reply === null
        ? (api = `/comments/${this.state.ReplyCommentIDs.parent}/reply`)
        : (api = `/comments/${this.state.ReplyCommentIDs.reply}/reply`)
      : (api = `/comments/${this.props.date}`);
    PostData(
      this.props.HerokuHost + api,
      this.state.commentText,
      200,
      this.props.token,
      (data) => {
        this.change_FrontEnd_State_After_PostComment(data.response.comment, ReplyCommentIDsCopy);
        console.log("data:", data);
      },
      this.onFailed
    );
  };

  handleRemoveComment = (ParentCmtID, ReplyCmtID) => {
    let confirm = window.confirm("Are you sure you want to delete this comment?");
    confirm ? this.RemoveComment(ParentCmtID, ReplyCmtID) : null;
  };

  RemoveComment = (ParentCmtID, ReplyCmtID) => {
    // send the api to backend to remove comment. if its a parent comment then replyCmtID will be null
    // when the backend is successfully updated then you can change your frontEnd here by calling this function
    // frontEnd and Backend will at the same state
    DeleteData(
      this.props.HerokuHost + `/comments/${ReplyCmtID ? ReplyCmtID : ParentCmtID}`,
      200,
      this.props.token,
      (data) => {
        if (data.responseCode === "Success") {
          this.change_FrontEnd_State_After_RemoveComment(ParentCmtID, ReplyCmtID);
        } else {
          alert("problem occured while deleting the article");
        }
      },
      this.onFailed
    );
  };

  handleDialogeClose = () => this.setState({ dialogOpen: false, dialogLikes: [] });

  focusTextInput() {
    this.textInput.current.focus();
  }

  handleReply = (ParentCmtID, ReplyCmtID, username) => {
    // it will set the state to comment id for which we are currently replying
    this.focusTextInput();
    this.setState({
      ReplyCommentIDs: { parent: ParentCmtID, reply: ReplyCmtID },
      ReplyCommentUsername: username,
    });
  };

  handleCommentText = (event) => {
    this.setState({ commentText: event.target.value });
  };

  //--------UnComment when you want to show the names of users and change it according to the data structure coming from backend------------
  // popUserFromLikedArray = (userId, likedArray) => {
  //   return likedArray.filter((user) => userId !== user[Object.keys(user)[0]]);
  // };

  // checkAlreadyLiked = (userId, Commentindex) => {
  //   let usersLikedArray;
  //   if (Commentindex.length !== undefined) {
  //     usersLikedArray = this.state.commentsArray[Commentindex[0]].replies[
  //       Commentindex[1]
  //     ].likes.usersLiked;
  //     for (let c = 0; c < usersLikedArray.length; c++) {
  //       if (userId === usersLikedArray[c][Object.keys(usersLikedArray[c])[0]]) {
  //         return true;
  //       }
  //     }
  //     return false;
  //   } else {
  //     usersLikedArray = this.state.commentsArray[Commentindex].likes.usersLiked;
  //     for (let i = 0; i < usersLikedArray.length; i++) {
  //       if (userId === usersLikedArray[i][Object.keys(usersLikedArray[i])[0]]) {
  //         return true;
  //       }
  //     }
  //     return false;
  //   }
  // };

  change_FrontEnd_State_After_PostHeart = (ParentCmtID, ReplyCmtID, ChangeHeart) => {
    // commentsCopy here is deep copy of commentsArray state
    let commentsCopy = copyCommentsArray(this.state.commentsArray);
    let index, oldLikes, UserAlreadyLiked;
    // if replyCmtID exist it means user has liked a reply of some comment so [if] clause will run
    // if replyCmtID is null it means user has liked a parent comment and [else] clause will run
    if (ReplyCmtID) {
      // it will find the index of the parent comment and then its replied comment
      // index will be array here contains index of parent comment and reply comment [parentCommentIndex, replyCommentIndex]
      index = this.findCommentIndex(ParentCmtID, ReplyCmtID);
      // get old likes numbers
      oldLikes = commentsCopy[index[0]].replies[index[1]].likes;
      // checking if user has already liked this comment
      UserAlreadyLiked = commentsCopy[index[0]].replies[index[1]].isLiked;
      // if user has already liked then we have to minus the number and remove user from likedArray
      // if user has not already liked then we will add the number and add user into likedArray
      if (UserAlreadyLiked) {
        commentsCopy[index[0]].replies[index[1]].likes = oldLikes - 1;
        commentsCopy[index[0]].replies[index[1]].isLiked = false;
      } else {
        commentsCopy[index[0]].replies[index[1]].likes = oldLikes + 1;
        commentsCopy[index[0]].replies[index[1]].isLiked = true;
      }
      // change the heart icon its a callback function that is executing in commentStructure.jsx
      ChangeHeart();
      // update the new commentArray
      this.setState({ commentsArray: commentsCopy });
    } else {
      index = this.findCommentIndex(ParentCmtID);
      oldLikes = commentsCopy[index].likes;
      UserAlreadyLiked = commentsCopy[index].isLiked;
      if (UserAlreadyLiked) {
        commentsCopy[index].likes = oldLikes - 1;
        commentsCopy[index].isLiked = false;
      } else {
        commentsCopy[index].likes = oldLikes + 1;
        commentsCopy[index].isLiked = true;
      }
      ChangeHeart();
      this.setState({ commentsArray: commentsCopy });
    }
  };

  change_FrontEnd_State_After_PostComment = (newComment, ReplyCommentIDs) => {
    // deep copy of commentsArray
    let commentsCopy = copyCommentsArray(this.state.commentsArray);
    // if its not a reply comment then newComment will be added to the array of parent Comments
    // it its a reply comment then newComment will be added to the array of reply comments of that specific parent comment
    if (ReplyCommentIDs === null) {
      commentsCopy.push(newComment);
    } else {
      const index = this.findCommentIndex(ReplyCommentIDs.parent);
      commentsCopy[index].replies.push(newComment);
    }
    // updating the new commentArray
    this.setState({ commentsArray: commentsCopy, commentText: "" });
  };

  change_FrontEnd_State_After_RemoveComment = (ParentCmtID, ReplyCmtID) => {
    let commentsCopy = copyCommentsArray(this.state.commentsArray);
    if (ReplyCmtID === null) {
      let poppedArray = PopParentCommentFromArray(commentsCopy, ParentCmtID);
      commentsCopy = poppedArray;
    } else {
      const index = this.findCommentIndex(ParentCmtID);
      let poppedReplies = commentsCopy[index].replies.filter((reply) => reply.commentId !== ReplyCmtID);
      commentsCopy[index].replies = poppedReplies;
    }
    // updating the new commentArray
    this.setState({ commentsArray: commentsCopy, commentText: "" });
  };

  findCommentIndex = (CommentIDToFind, ReplyCommentIDToFind) => {
    if (ReplyCommentIDToFind) {
      let parentIndex = this.findCommentIndex(CommentIDToFind);
      let parentRepliesArr = this.state.commentsArray[parentIndex].replies;
      for (let r = 0; r < parentRepliesArr.length; r++) {
        if (parentRepliesArr[r].commentId === ReplyCommentIDToFind) {
          return [parentIndex, r];
        }
      }
    } else {
      for (let j = 0; j < this.state.commentsArray.length; j++) {
        if (this.state.commentsArray[j].commentId === CommentIDToFind) {
          return j;
        }
      }
    }
  };

  Recursive = (Replies, arr) => {
    Replies.map((reply) => {
      arr.push({ ...reply, replies: [] });
      if (reply.replies.length > 0) {
        this.Recursive(reply.replies, arr);
      }
    });
  };

  sorter = (arr) => {
    return arr.sort(function (a, b) {
      return a.commentId - b.commentId;
    });
  };

  scrollToBottom = () => {
    animateScroll.scrollToBottom({
      to: "target",
    });
  };


  render() {
    const { classes } = this.props;
    return this.state.loading ? (
      // <TransverseLoading color="#225b76" size="large" />
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    ) : (
      <div className="Comments-Main-Wrapper">
        <Slide left>
          {this.state.commentsArray.length > 0 ? (
            // all the comments are iterated and rendered here through Comment.jsx component
            // Comment.jsx component represent a single parent comment which will contain its replies too if they have any replies
            this.state.commentsArray.map((comment, index) => {
              return (
                <Comment
                  key={index}
                  CurrentUser={this.props.userId}
                  commentId={comment.commentId}
                  username={comment.user.name}
                  commentUserId={comment.user.userId}
                  avatar={comment.user.photoUrl}
                  likes={comment.likes}
                  isLiked={comment.isLiked}
                  text={comment.text}
                  replies={comment.replies}
                  handleReply={(ParentCmtID, ReplyCmtID, username) =>
                    this.handleReply(ParentCmtID, ReplyCmtID, username)
                  }
                  handleHeart={(ParentCmtID, ReplyCmtID, ChangeHeart) =>
                    this.postHeart(ParentCmtID, ReplyCmtID, ChangeHeart)
                  }
                  handleRemove={(ParentCmtID, ReplyCmtID) => this.handleRemoveComment(ParentCmtID, ReplyCmtID)}
                  // showLikes={(likes) =>
                  //   this.setState({ dialogLikes: likes, dialogOpen: true })
                  // }
                />
              );
            })
          ) : (
            <div className="CM-NoComments">
              No Comments for{" "}
              <span className="CM-NoComments-Day"> {this.props.date}</span>.
            </div>
          )}
          <div className="CM-WriteComment-Container">
            <Grid container xs={11} className="CM-WriteComment-div">
              <Grid item xs={12} sm={12} md={9} className="CM-WriteComment-Input-div">
                <TextField
                  id="outlined-textarea"
                  label=""
                  placeholder={
                    this.state.ReplyCommentUsername
                      ? `Reply to ${this.state.ReplyCommentUsername}'s Comment`
                      : "Write a Comment"
                  }
                  multiline
                  variant="outlined"
                  fullWidth
                  className="CM-WriteComment-Input"
                  InputProps={{
                    classes: {
                      root: classes.inputRoot,
                      focused: classes.inputFocus,
                    },
                  }}
                  inputRef={this.textInput}
                  onChange={this.handleCommentText}
                  value={this.state.commentText}
                  onBlur={() =>
                    setTimeout(() => {
                      this.setState({
                        ReplyCommentUsername: "",
                        ReplyCommentIDs: null,
                      });
                    }, 500)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} className="CM-WriteComment-Btn-div">
                <Button id="CM-WriteComment-Btn" variant="contained" fullWidth onClick={this.postComment}>
                  Post Comment
                </Button>
              </Grid>
            </Grid>
          </div>
        
        <Link to="target"></Link>

        </Slide>
      </div>
    );
  }
}

const style = (theme) => ({
  paper: {
    maxHeight: "80vh !important",
  },
  inputRoot: {
    borderRadius: "0px !important",
    height: "50px",
  },
});

const mapStateToProps = (state) => {
  return {
    userId: state.setUser.userId,
    userName: state.setUser.userName,
    HerokuHost: state.setHerokuHost,
    token: state.setUser.token,
    date: state.setDate.date,
  };
};

export default React.memo(connect(mapStateToProps, null)(withStyles(style)(Comments)));

export const copyCommentsArray = (commentsArray) => {
  return commentsArray.map((c) => ({
    ...c,
    replies: c.replies.map((r) => ({
      ...r,
      // likes: {
      //   numbers: r.likes.numbers,
      //   usersLiked: r.likes.usersLiked.map((l) => ({ ...l })),
      // },
    })),
  }));
};

export const PopParentCommentFromArray = (commentsArray, ParentCmtID) => {
  let arr = [];
  for (let i = 0; i < commentsArray.length; i++) {
    if (commentsArray[i].commentId !== ParentCmtID) {
      arr.push({
        ...commentsArray[i],
        replies: commentsArray[i].replies.map((r) => ({
          ...r,
          // likes: {
          //   numbers: r.likes.numbers,
          //   usersLiked: r.likes.usersLiked.map((l) => ({ ...l })),
          // },
        })),
      });
    }
  }
  return arr;
};
