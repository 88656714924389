import React, { Component } from "react";
import {
  Button,
  Grid,
  withStyles,
  Avatar,
  GridList,
  GridListTile
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import CheckMark from "../../assets/check-mark.png";
import PopperImage from "../../assets/popper.png";
import DeleteData from "../fetchDataDelete";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import { connect } from "react-redux";
import { compose } from "redux";
import PostData from "../fetchDataPost";
import GetData from "../fetchDataGet";



// import "../../user/EditProfile/EditProfile.scss";

const groups = [

    {
        name:'This is Dummy Data'
    },
    {
        name:'Group Name 2 (Leader)'
    },
    {
        name:'Group Name 3'
    },
    {
        name:'Group Name 4'
    },
]
// const trackList = [
//     {
//       Date: "May 1, 2020",
//       Message: "You've completed Day 19 of 21",
//       Type: "Completed",
//     },
//     {
//       Date: "May 2, 2020",
//       Message: "You've completed Day 20 of 21",
//       Type: "Completed",
//     },
//     {
//       Date: "May 3, 2020",
//       Message: "You've completed Day 21 of 21",
//       Type: "Completed",
//     },
//     {
//       Date: "May 3, 2020",
//       Message1: "YOU FINISHED YOUR 21 CONSECUTIVE DAYS,",
//       Message2: "YOU'RE NOW ONTO THE 90 DAYS CHALLENGE.",
//       Type: "Congratulations",
//     },
//     {
//       Date: "May 4, 2020",
//       Message: "You've completed Day 1 of 90",
//       Type: "Completed",
//     },
//     {
//       Date: "May 5, 2020",
//       Message: "You've completed Day 2 of 90",
//       Type: "Completed",
//     },
//     {
//       Date: "May 6, 2020",
//       Message: "You've completed Day 3 of 90",
//       Type: "Completed",
//     },
    
//   ];

class UserSpecific extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();

    this.state = {
      loadedImage: "",
      name: "",
      email: "",
      password: "",
      birthdate: "",
      cityState: "",
      reminderOption: "",
      progressPrivacyOption: "",
      trackList:[],
      groups,
      disabled: true,
      changesShow: false,
      backdrop:false,
    };
  }

  componentWillMount()
  {

    GetData(this.props.HerokuHost+`/account/${this.props.user.userId}/progress`,200,this.props.token,this.setProgress,this.onFailed);
    
    this.setState({disableSave:true,  
      name: this.props.user.name,
      email: this.props.user.userName,
      password: "",
      birthdate: this.props.user.birthDate.split("T")[0],
      address: this.props.user.address,
      notificationFrequency: this.props.user.notificationFrequency,
      privacyLevel: this.props.user.privacyLevel,
      photoUrl:this.props.user.photoUrl,

      oldName: this.props.user.name,
      oldEmail: this.props.user.userName,
      oldBirthdate: this.props.user.birthDate.split("T")[0],
      oldAddress: this.props.user.address,
      oldNotificationFrequency: this.props.user.notificationFrequency,
      oldPrivacyLevel: this.props.user.privacyLevel,
    })
  }

  setProgress = (response) =>
  {
    console.log(response.response)
    if(response.response)
    {
      let progressTrack=response.response.progressTrack
      this.setState({trackList:progressTrack})
    }
  }


  handleChange = (e) =>
  {
    this.setState({
      [e.target.id]:e.target.value, disabled: false,
      changesShow: false,
    })
  }

  updateImage = (newPhotoUrl) => {
    this.setState({ backdrop: true });
    const UpdatedUser = {
      userId: this.props.user.userId,
      photoUrl: newPhotoUrl ? newPhotoUrl : this.props.user.photoUrl,
      userName: this.state.oldEmail,
      name: this.state.oldName,
      privacyLevel: this.state.oldPrivacyLevel,
      address: this.state.oldAddress,
      notificationFrequency: this.state.oldNotificationFrequency,
      birthDate: this.state.oldBirthdate,
    };
    PostData (
      this.props.HerokuHost + `/account/${this.props.user.userId}/save`,
      UpdatedUser,
      200,
      this.props.token,
      (data) => {
        
        this.setState({ loadedImage: "",photoUrl:newPhotoUrl ? newPhotoUrl : this.state.photoUrl,
        backdrop: false,
        changesShow: "ImageSuccess", });
      },
      this.onImageFailed
    );
  };

  onImageFailed = (response) => {
    console.log(response);
    this.setState({ backdrop: false, changesShow: "ImageError" });
  };
  
  onFailed = (response) => {
    console.log(response);
    this.setState({ backdrop: false,changesShow: "Error" });
  };

  updateProfile = () => {
    this.setState({ backdrop: true });
    const UpdatedUser = {
      userId: this.props.user.userId,
      userName: this.state.email,
      password: this.state.password,
      name: this.state.name,
      photoUrl:this.state.photoUrl,
      privacyLevel: this.state.privacyLevel,
      address: this.state.address,
      notificationFrequency: this.state.notificationFrequency,
      birthDate: this.state.birthdate,
    };
    PostData(
      this.props.HerokuHost + `/account/${this.props.user.userId}/save`,
      UpdatedUser,
      200,
      this.props.token,
      (data) => {
        this.setState({
        disabled: true,
        backdrop: false,
        changesShow: "Success",
        oldName: this.state.name,
        oldEmail: this.state.email,
        oldPassword: this.state.password,
        oldBirthdate: this.state.birthdate,
        oldAddress: this.state.address,
        oldNotificationFrequency: this.state.notificationFrequency,
        oldPrivacyLevel: this.state.privacyLevel,
        oldPhotoUrl:this.state.photoUrl});
      },
      this.onFailed
    );
  };

  UploadDP = async (file) => {
    this.setState({ backdrop: true });
    let fd = new FormData();
    fd.append("file", file);
    await PostData(
      this.props.HerokuHost + "/account/upload/photo",
      fd,
      200,
      this.props.token,
      (data) => {
        this.updateImage(data.response.url);
      },
      this.onImageFailed,
      true
    );
  };
  
  

  handleImageChnage = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.addEventListener("load", () => {
        this.setState({ loadedImage: reader.result, imageFile: file });
        this.UploadDP(file)
      });
    }
  };

  // handleUploadImage = () => {
  //   if (this.avatarEditor) {
      

  //     this.avatarEditor.getImageScaledToCanvas().toBlob((blob) => {
  //       let imageUrl = URL.createObjectURL(blob);
  //     });
  //   }
  // };

  handleDeleteUser = () =>
  {
    DeleteData(this.props.HerokuHost + `/account/${this.props.user.userId}`,
    200,
    this.props.token,
    (data) => {
      
      this.props.handleToggleView();
    },
    this.onFailed)
  }

  month(number)
  {
    var month = new Array();
    month[1] = "January";
    month[2] = "February";
    month[3] = "March";
    month[4] = "April";
    month[5] = "May";
    month[6] = "June";
    month[7] = "July";
    month[8] = "August";
    month[9] = "September";
    month[10] = "October";
    month[11] = "November";
    month[12] = "December";
    return month[number];
  }

  render() {
    const { classes } = this.props;
    return (
      <div className="EP-Wrapper">
        <Grid container>

          <Grid item lg={7} xl={7} sm={7} md={7}>

            <GridList cols={7}>

              <GridListTile cols={5} style={{height:'100%'}}>
                <div className="header-avatar-div">
                  <Avatar alt="Image" src={this.state.photoUrl || null} className="header-avatar" max-width="60px" />
                </div>
                <div className="header-user-name">
                  User : {this.state.oldName.split(" ")[0]} <br />{" "}
                  {this.state.oldName.split(" ")[1]}
                </div>
              </GridListTile>
           
              <GridListTile cols={2} style={{height:'100%'}}>   
                <div className="user-specific-change-profile-picture">
                  <div style={{marginBottom:'10px'}}>Change Profile Picture</div>
                  <input
                  type="file"
                  id="user-specific-data-change-profile-button"
                  onChange={this.handleImageChnage}
                  style={{display:'none'}}
                  ref={this.myRef}
                  />
              
                  <Button  variant="contained" onClick={()=>this.myRef.current.click()}>
                    Upload
                  </Button>
                </div>
              </GridListTile>
              <GridListTile cols={7} style={{height:'100%'}} className="EP-FormContainer">
                <InputElement
                id="name"
                label="Name"
                value={this.state.name}
                type="text"
                onChange={this.handleChange}
                />
                <InputElement
                id="email"
                label="Email"
                value={this.state.email}
                type="email"
                onChange={this.handleChange}
                />
                <InputElement
                id="password"
                label="Password"
                value={this.state.password}
                type="password"
                onChange={this.handleChange}
                />
                <InputElement
                id="birthdate"
                label="Date of Birth"
                value={this.state.birthdate}
                type="date"
                onChange={this.handleChange}
                />
                <InputElement
                id="address"
                label="City, State"
                value={this.state.address}
                type="text"
                onChange={this.handleChange}
                />
              </GridListTile>
            </GridList>
          </Grid>
                      
          <Grid  item lg={5} md={5} xl={5}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="groups-participating-div">
                <div className="track-my-progress-header">Groups Participating: </div>
                {this.state.groups.map((group,id)=>
                <div className="groups-participating-name">
                    {group.name}
                </div>)
                }
              </div>
              

              
              <div className="track-my-progress-header">Progress</div>
              <div className="companions-new-request-list">
                <List
                component="companion-request-list"
                className={classes.tracklistRoot}
                aria-label="track-my-progress-list"
                >
                {this.state.trackList.map((track, key) => (
                  <ListItem key={key} style={{ position: "relative" }}>
                  <ListItemAvatar>
                    <Avatar className={classes.largeAvatar}>
                      <img
                        width="30px"
                        src={
                          track.targetAchieved === false ? CheckMark : PopperImage
                        }
                        alt="Tracker"
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      track.targetAchieved === false ? (
                        <span className="track-list-date">
                          {" "}
                          { this.month(parseInt(track.progressDate.split('T')[0].split('-')[1])) + " "} {track.progressDate.split('T')[0].split('-')[2] + ", " + track.progressDate.split('T')[0].split('-')[0]  } {" "}
                        </span>
                      ) : (
                        <span className="track-list-congrats">
                          {" "}
                          CONGRATULATIONS! <br /> {track.Message1} <br />{" "}
                          {track.Message2}{" "}
                        </span>
                      )
                    }
                    secondary={
                      track.targetAchieved === false ? (
                        <span className="track-list-message">
                          {" "}
                          {track.caption}{" "}
                        </span>
                      ) : null
                    }
                  />
                  {track.Type === "Congratulations" ? (
                    <div className="track-my-progress-line-cong-up"></div>
                  ) : null}

                  {key!==this.state.trackList.length-1?
                  <div
                    className={
                      track.Type === "Congratulations"
                        ? "track-my-progress-cong-line"
                        : "track-my-progress-line"
                    }
                  ></div>:null}
                </ListItem>
                  ))}
                </List>
              </div>
            </Grid>

          </Grid>  

        </Grid> 

        <div style={{display:'inline-block'}}>
          <div style={{display:'inline-block'}}>
            <Button
              id="EP-SaveButton"
              variant="contained"
              onClick={this.updateProfile}
              disabled={this.state.disabled}
              style={{ background: this.state.disabled ? "rgb(228, 88, 1,0.5)" : "rgb(228, 88, 1,1)" }}

              >
              Save Changes
            </Button>
          </div>

          <div style={{display:'inline-block'}} className="user-specific-delete-button-div">
            <Button
              id="user-specific-delete-button"
              onClick={this.handleDeleteUser}
            >
              Delete User
            </Button>
          </div>
         
        </div>

        {this.state.changesShow === "Success" ? (
            <div className={classes.changeSaved}>
              Changes Saved Successfully.
            </div>
          ) : null}
          {this.state.changesShow === "Error" ? (
            <div className={classes.changeSaved}>
              Error Changing Profile Data! Please try again later.
            </div>
          ) : null}
           {this.state.changesShow === "ImageSuccess" ? (
            <div className={classes.changeSaved}>
              Image Uploaded Successfully.
            </div>
          ) : null}

          {this.state.changesShow === "ImageError" ? (
            <div className={classes.changeSaved}>
              Error Changing Profile Picture! Please try again later.
            </div>
          ) : null}

          <Backdrop className={classes.backdrop} open={this.state.backdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>


      </div>
    );
  }
}

const style = (theme) => ({
  radioRoot: {
    color: "#989898",
  },
  radioChecked: {
    color: "#989898 !important",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  changeSaved: {
    display:'inline-block',
    color: "#E25601",
    marginLeft:'10px',
    // font-size
  },
});

export const InputElement = (props) => {
  return (
    <div className="Ep-InputContainer">
      <input
        id={props.id}
        value={props.value}
        className="EP-Input"
        type={props.type}
        onChange={props.onChange}
      />
      <div className="EP-InputLabel">{props.label}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    HerokuHost: state.setHerokuHost,
    date: state.setDate.date,
    token: state.setUser.token,
  };
};

export default compose(
  connect(mapStateToProps, {}),
  withStyles(style)
)(UserSpecific);

