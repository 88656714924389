import React from "react";
import "./signup.scss";
import {
  Grid,
} from "@material-ui/core";
import Slide from "react-reveal/Slide";



const Signup4 = () => {
  return (
    <Slide left>
        <Grid item xs={8} md={4} lg={4}>
            <div className="Signup4-HeadingQuestion">
                Letting You In...
            </div>
        </Grid>
    </Slide>
  );
};

export default Signup4;
