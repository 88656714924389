/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { AddCircleOutline } from "@material-ui/icons";
import GetData from "../fetchDataGet";
import { connect } from "react-redux";
import PutData from '../fetchDataPut';

export class AddMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myCompanionsDone:false,
      myCompanions:[],
      pendingRequests:[],
      pendingRequestsDone:false,
      AddMembers:[],
      groupMembersDone:false,
      groupMembers:[],
      setMembers:false
    };
  }

  componentDidMount() {
    //will take prop of group id
    this.getThisGroupMembers();
    this.getMyComapnions();
    this.getPendingRequest();
  }

  componentDidUpdate(prevProps,prevState)
  {
    if(this.state.error===true)
    {
      alert('Some error occured! Please try again later...')
      this.props.swipe(1)
    }

    if(this.state.pendingRequestsDone===true && this.state.myCompanionsDone===true && this.state.groupMembersDone===true && this.state.setMembers===false)
    {
      console.log(this.state.pendingRequests)
      console.log(this.state.myCompanions)
      console.log(this.state.groupMembers)
      let AddMembers=[];
      for(let i=0; i<this.state.myCompanions.length; i++)
      {
        if(this.state.groupMembers.find( groupMember => groupMember.userId.toString() === this.state.myCompanions[i].userId.toString() ) == null)
        {
          let AddMember = this.state.myCompanions[i];

          if(this.state.pendingRequests.find( requestMember => requestMember.userId.toString() === this.state.myCompanions[i].userId.toString() ) == null)
          {
            AddMember.pendingRequest=false
          }

          else AddMember.pendingRequest=true

          AddMembers.push(AddMember)
        }

      }

      this.setState({AddMembers,setMembers:true})
    }

  }

  getMyComapnions = () => {
    GetData(this.props.HerokuHost + `/account/companion`, 200, this.props.token, this.setMyComapnions, this.onFailed);
  };

 

  getPendingRequest = () =>
  {
    GetData(this.props.HerokuHost + `/account/group/${this.props.activeGroup.groupId}/members/pending`, 200, this.props.token, this.setPendingRequest, this.onFailed);
  }


   setMyComapnions = (response) => {
 
    console.log('myCompanionsDone => ', response)

    if(response.responseCode==="Success")
    {
      this.setState({myCompanions:response.response.users,myCompanionsDone:true})
    }

    else
    {
      alert('Something went wrong...')
      console.log(response);
      this.setState({error:true})

    }
  };



  setPendingRequest = ( response ) =>
  {
    console.log('pendingRequestsDone => ', response)
    if(response.responseCode==="Success")
    {
      this.setState({pendingRequests:response.response.members,pendingRequestsDone:true})
    }

    else
    {
      alert('Something went wrong...')
      console.log(response);
      this.setState({error:true})
    }
    
  }

    getThisGroupMembers = () => {
    GetData(
      this.props.HerokuHost + `/account/group/${this.props.activeGroup.groupId}/members`,
      200,
      this.props.token,
      this.setThisGroupMembers,
      this.onFailed
    );
  };

    setThisGroupMembers = (response) => {

    console.log('groupMembersDone => ', response)
    if(response.responseCode==="Success")
    {
      this.setState({groupMembers:response.response.members,groupMembersDone:true})
    }

    else
    {
      alert('Something went wrong...')
      console.log(response);
      this.setState({error:true})

    }

  };

  onFailed = (response) => {
    console.log(response);
    alert("Failed response");
  };

  RemoveUser = (id) =>
  {
    let AddMembers=this.state.AddMembers;
    AddMembers=AddMembers.filter(AddMember =>  AddMember.userId.toString() !== id.toString())
    this.setState({AddMembers})
  }

  
  // renderRows2 = (myComp, thisGrpComp) => {
  //   console.log("render rows chalra", myComp);
  //   return myComp.map((member) => {
  //     console.log("each member", member);
  //     return <Members member={member} />;
  //   });
  // };
  render() {
    return (
      <div className="SG-AddMember-Wrapper">
        <div className="SG-AddMember-header">
          <div className="SG-AddMember-name">Add Members </div>
          <div className="SG-AddMember-back" onClick={() => this.props.swipe(1)}>
            Back
          </div>
        </div>
        <div className="SG-AddMember-List-div">

          {this.state.AddMembers.map((AddMember,id) => <Members key={id} RemoveUser={(id)=>{this.RemoveUser(id)}} groupId={this.props.activeGroup.groupId} HerokuHost={this.props.HerokuHost} token={this.props.token} member={AddMember} />)}
          {/* {this.state.AddMembers.length > 0 ? (
            this.renderRows2(this.state.AddMembers, this.state.thisGrpComp)
          ) : (
            <div>you have no companions</div>
          )} */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.setUser.userId,
    userName: state.setUser.userName,
    HerokuHost: state.setHerokuHost,
    token: state.setUser.token,
    date: state.setDate.date,
  };
};

export const Members = (props) => {

  const [loader, setLoader] = React.useState(false);

  const Add = (userId) =>
  {
    setLoader(true)
    PutData(props.HerokuHost+`/account/group/${props.groupId}/members/add`,[userId],200,props.token,MemberAdded,onFailed);
  }

  const MemberAdded = (response) =>
  {
    console.log('Member Added => ', response)
    if(response.responseCode==="Success")
    {
      // props.RemoveUser(props.member.userId);
      props.member.pendingRequest=true
    }

    else
    {
      alert('Something went wrong...')
      console.log(response);
    }
    setLoader(false)
    
  }


  const onFailed = (response) => {
    console.log(response);
    alert("Failed response");
  };

  return (
    <div className="SG-AddMember-member" style={{opacity:loader===true?'0.5':'1'}}>
      <div className="SG-AddMember-member-name" style={{color:(loader===false && props.member.pendingRequest.toString()==="true")?"rgb(206, 204, 204)":''}}>{props.member.name}</div>
      {
        props.member.pendingRequest.toString()==="true"?
        <div className="SG-AddMember-member-Addicon">
          <AddCircleOutline className="SG-AddMember-member-Addicon-disable" fontSize="large" disabled={true}  />
        </div>
        : 
        <div className="SG-AddMember-member-Addicon">
          <AddCircleOutline className="SG-AddMember-member-Addicon-enable" style={{color:loader?'#989898':''}} fontSize="large" disabled={loader}  onClick={()=>Add(props.member.userId)}/>
        </div>
      }
      
    </div>
  );
};

export default connect(mapStateToProps, null)(AddMembers);

