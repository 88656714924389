import React, { Component } from 'react';

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import DefaultDP from "../../assets/defaultDP.png";
import PutData from '../fetchDataPut';
import { Button } from '@material-ui/core';
import Zoom from "react-reveal/Zoom";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";


const styles = (theme) => ({
   
    largeAvatar: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
   
  });

class CompanionRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }

    componentWillMount()
    {
        this.setState({req:this.props.req})
    }

    handleAccept = (id) => {
        // this.setState({}) loader on of request
        PutData(
            this.props.HerokuHost + `/account/companion/request/${id}/accept`,
            {},
            200,
            this.props.token,
            this.handleAcceptRequest,
            this.onFailed
        );
    };
    
    handleAcceptRequest = (response) => {
        console.log(response);
        if(response.responseCode==="Success")
        {
            // console.log(response.response.requests[0].companion.userId);
            this.props.handleOtherCompUpdate({state:'accept',id:this.state.req.companion.userId});
            // this.props.req.companion.hasPendingCompanionRequest=false;
        }
        this.setState({loader:false})
    };
    
    handleDecline = (id) => {
        this.setState({loader:true})
        PutData(
            this.props.HerokuHost + `/account/companion/request/${id}/decline`,
            {},
            200,
            this.props.token,
            this.handleDeclineRequest,
            this.onFailed
        );
    };

    handleDeclineRequest = (response) => {
        console.log(response);
        if(response.responseCode==="Success")
        {
            this.props.handleOtherCompUpdate({state:'decline',id:this.state.req.companion.userId});
        }
        this.setState({loader:false})

    };


    render() { 

       const req = this.props.req;
       const {classes} = this.props;
        return ( 
            <Zoom>
                <ListItem key={req.companion.userId}>
                    <ListItemAvatar>
                        <Avatar className={classes.largeAvatar}>
                            <img
                            src={
                                req.companion.photoUrl
                                ? req.companion.photoUrl
                                : DefaultDP
                            }
                            alt={DefaultDP}
                            width={"64px"}
                            />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                    primary={
                        <span className="companions-request-name">
                        {" "}
                        {req.companion.name}{" "}
                        </span>
                    }
                    secondary={
                        <React.Fragment>
                        <Button
                            variant="contained"
                            className="companions-request-button companions-request-confirm-button"
                            onClick={() =>
                            this.handleAccept(req.companion.userId)
                            }
                        >
                            {" "}
                            Confirm{" "}
                        </Button>
                        <Button
                            variant="contained"
                            className="companions-request-button companions-request-decline-button"
                            onClick={() =>
                            this.handleDecline(req.companion.userId)
                            }
                        >
                            {" "}
                            Decline{" "}
                        </Button>
                        </React.Fragment>
                    }
                    />
                </ListItem>
            </Zoom>
        );
    }
}

const mapStateToProps = (state) => {
    return {
      HerokuHost: state.setHerokuHost,
      token: state.setUser.token,
    };
  };
  
  export default React.memo(
    connect(mapStateToProps, null)(withStyles(styles)(CompanionRequest))
  );
 
