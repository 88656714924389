import React from "react";
import "./StudyGroups.scss";
import { connect } from "react-redux";


const Message = (props) => {

  const isByMe = props.message.user.userId.toString() === props.userId.toString() ? true : false;
  const isByAdmin = props.message.isByAdmin;
  return (
    <div
      className="Msg-wrapper"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent:  isByMe ? "flex-end" : "flex-start",
      }}
    >
      <div
        className="Msg-Container"
        style={
          isByMe
            ? {
                backgroundColor: "#2c7178",
                color: "white",
                boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.5)",
              }
            
            : isByAdmin ? {
                backgroundColor: "#F8FAE2",
                color: "black",
                boxShadow: "1px 1px 2px 1px rgba(0, 0, 0, 0.2)",
              }
            : {
                backgroundColor: "white",
                boxShadow: "-1px 2px 3px 1px rgba(0, 0, 0, 0.2)",
              }
        }
      >
        <div className="Msg-SenderName">{props.message.user.name} {isByAdmin? " (Group Leader)" :null }</div>
        <div className="Msg-Content">
          {props.message.text}
        </div>
        { isByMe ? (
          <div className="Msg-triangle-right"></div>
        ) : (
          <div className="Msg-triangle-left"></div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.setUser.userId,
  };
};

export default connect(mapStateToProps, {})(Message);

