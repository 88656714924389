import React, { Component } from "react";
import {
  withStyles,fade
} from "@material-ui/core/styles";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';


import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import { connect } from "react-redux";
import { compose } from "redux";
import GetData from "../../fetchDataGet";
import Slide from "react-reveal/Slide";
import Paper from '@material-ui/core/Paper';
import { CSVLink  } from "react-csv";
import {  Button, Fab } from "@material-ui/core";
import CSVImage from '../../../assets/csv.png';

const style = theme => ({
  table: {
    minWidth: 650,
  },
  search: {
    position: 'relative',
    borderRadius: '0px',
    border:'1px solid lightgray',
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'grey',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '20ch',
      '&:focus': {
        width: '30ch',
      },
    },
  },
  space:
  {
    flexGrow:1
  },
  appbar:
  {
    backgroundColor:'transparent',
    marginTop:'-20px',

  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
})

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    fontWeight:'bold',
    fontSize:17,
    borderBottom:'0px'

  },
  body: {
    fontSize: 14,
    borderBottom:'0px'
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    // cursor:'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);



class ViewOrganaizationCodeBatch extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();

    this.state = {
      loading:true,
      users:[],
      codes:[],
      rowsPerPage:10,page:1,totalPages:0,totalRecords:0

    };
    this.csvRef = React.createRef();

  }

  componentWillMount()
  {
   this.GetCodes(1);
  }

  GetCodes = (page=this.state.page,rowsPerPage=this.state.rowsPerPage) => {
    this.setState({loading:true})
    GetData(this.props.HerokuHost+`/organization/getcodesbybatch?batchId=${this.props.batchCodeId}&pageNumber=${page}&pageSize=${rowsPerPage}`, 200, this.props.token, this.handleCodes, this.onFailed);   
    // {"organizationId": this.props.user.id,
    // "pageSize": 10,
    // "pageNumber": 1});
  }

  handleCodes = (response) => {
    console.log("response",response)
    if(response.response)
    {
      let codes=response.response?.registrationCodes?.codes;

      let {pageNumber,pageSize,totalPages,totalRecords} = response.response.registrationCodes;     
      this.setState({loading:false,codes,page:pageNumber,rowsPerPage:pageSize,totalPages:totalPages,totalRecords:totalRecords})
      
      // console.log(users)

    }
    else if (response.validationErrorsList.length > 0) {
      this.setState({loading:false})
      alert(response.validationErrorsList[0].errorMessage);
      console.log("validation error response on user-data => ", response);
    } else {
      this.setState({loading:false})
      alert("Unknown error occured!");
      console.log("unknown error response on user-data => ", response);
    }
  }

  
  onFailed = (response) => {
    console.log(response);
    this.setState({ backdrop: false,changesShow: "Error" });
  };

  handleCsvDownload = () =>
  {
    this.csvRef.current.link.click();
  }

  handleChangePage = (event, newPage) => {
    this.GetCodes(newPage + 1);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({rowsPerPage:parseInt(event.target.value)})
    this.GetCodes(1,parseInt(event.target.value))
  };



  render() {
    const { classes } = this.props;
    const {rowsPerPage,page} = this.state;

    return (
      <div className="EP-Wrapper">
        
        {this.state.loading
          ?
          <Backdrop className={classes.backdrop} open={this.state.loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          :
          <Slide left>

            <TableContainer component={Paper}>
              {/* margin for csv button overlapping with table date */}

              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead styld={{fontWeight:'bold'}}>
                  <TableRow>
                    <StyledTableCell align="left">Promo Code</StyledTableCell>
                    <StyledTableCell align="left">Qr Code</StyledTableCell>
                    <StyledTableCell align="left">Is Activated</StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>   
                  {this.state.codes.map((row) => (
                    <StyledTableRow key={row.id}  
                      // onClick={()=>this.props.handleSelectedUser(row)}
                    >
                      <StyledTableCell align="left">{row.promoCode}</StyledTableCell>
                      <StyledTableCell align="left">{row.qrCode}</StyledTableCell>
                      <StyledTableCell align="left">{row?.isActive?.toString() === "true" ? "false" : "true"  || " "}</StyledTableCell>
                      
                      

                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{marginRight:"50px",marginBottom:'80px'}}>

              {
                console.log('pagesa',page)
              }
              <TablePagination
                rowsPerPageOptions={[10, 50, 100]}
                component="div"
                count={this.state.totalRecords}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}

              />
            </div>


          </Slide>
        }

        <Button id="W-Back-Button" variant="contained" onClick={() => this.props.goBack()}>
          Back
        </Button>

        { this.state.codes.length>0 ?
          <div>
            <CSVLink
              ref={this.csvRef}
              style={{display:'none'}}
              data={this.state.codes}
              filename= {`${this.props.user?.title || "Unknown Org"}-codes.csv`}
            >
            </CSVLink>
            <div className="csv-download-wrapper">
              <Fab onClick={this.handleCsvDownload} className="csv-download-button">
                <img src={CSVImage} width="32px"/>
              </Fab>
            </div>
           
          </div>
          :null
        }

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    HerokuHost: state.setHerokuHost,
    date: state.setDate.date,
    token: state.setUser.token,
  };
};

export default compose(
  connect(mapStateToProps, {}),
  withStyles(style)
)(ViewOrganaizationCodeBatch);

