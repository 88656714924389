import React, { useState } from "react";
import { connect } from "react-redux";
import { withStyles, Drawer, List, ListItem, ListItemText, useMediaQuery } from "@material-ui/core";
import { logoutUser } from "../../store/actions";
import { ThemeColor } from "../../shared/colors";
import FWLogo from "../../assets/TheFamilyWinsLogo.png";
import './sideBar.css'

const drawerWidth = 240;

const style = (theme) => ({
  root: {
    backgroundColor: ThemeColor,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#225b76",
  },
  listItemTextRoot: {
    color: "white",
  },
  ListItemBtn: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
  },
  ListItemText2: {
    marginLeft: "20px",
  },
  logo: {
    width: "180px",
    height: "97px",
  },
});

const UserOptions = [
  { name: "Go Deeper", link: "/go-deeper" },
  { name: "Listen", link: "/listen" },
  // { name: "Read And Reflect", link: "/read-and-reflect" },
  { name: "Watch", link: "/watch" },
  { name: "Worship", link: "/worship" },
  { name: "Journal", link: "/journal" },
  { name: "Comments", link: "/comments" },
  { name: "Study Groups", link: "/study-groups" },
  { name: "Companions", link: "/companions" },
  { name: "Track Progress", link: "/progress" },
  { name: "Favourites", link: "/favourites" },
  { name: "Edit Profile", link: "/edit-profile" },
  { name: "Log out", link: "/logout" },
];

const AdminOptions = [
  { name: "Go Deeper", link: "/go-deeper" },
  { name: "Listen", link: "/listen" },
  // { name: "Read And Reflect", link: "/read-and-reflect" },
  { name: "Watch", link: "/watch" },
  { name: "Worship", link: "/worship" },
  { name: "Comments", link: "/comments" },
  { name: "Study Groups", link: "/study-groups" },
  { name: "User Data", link: "/user-data" },
  { name: "Edit Profile", link: "/edit-profile" },  
  { name: "Organizations", link: "/organizations" },

  
  { name: "Log out", link: "/logout" },
];

const SideBar = (props) => {
  const [active, setActive] = useState("/dashboard");
  const matches = useMediaQuery("(min-width:600px)");

  const setCookie = (cname, cvalue, exmins) => {
    var d = new Date();
    d.setMinutes(d.getMinutes() + exmins);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  };

  const handleNav = (link) => {
    props.closeDrawer(false);
    if (link === "/logout") {
      setCookie("user", "");
      setCookie("token", "");
      props.logoutUser();
    } else {
      setActive(link);
      props.history.push(link);
    }
  };
  const { classes } = props;
  const type = props.admin ? AdminOptions : UserOptions;

  return (
    <Drawer
      // className={classes.drawer}
      className="sidebar-list"
      variant={matches ? "permanent" : "temporary"}
      classes={{
        paper: classes.drawerPaper,
      }}
      open={props.isOpen}
      onClose={() => props.closeDrawer(false)}
      anchor="left">
      <List>
        <div
          style={{
            textAlign: "center",
            marginBottom: "30px",
            marginTop: "15px",
           
          }}>
          <img
            src={FWLogo}
            alt="Logo"
            style={{ width: "160px", height: "86px", cursor: "pointer" }}
            onClick={() => {
              props.closeDrawer(false);
              props.history.push("./dashboard");
            }}
          />
        </div>
        {type.map((nav, index) => (
          <ListItem button key={index} classes={{ button: classes.ListItemBtn }} onClick={() => handleNav(nav.link)}>
            <ListItemText
              classes={{ root: classes.listItemTextRoot }}
              primary={nav.name}
              style={{
                color: active == nav.link ? "#34ADEB" : "white",
              }}
              primaryTypographyProps={{
                classes: { root: classes.ListItemText2 },
              }}
            />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default React.memo(connect(null, { logoutUser })(withStyles(style)(SideBar)));
