import React, { Component } from "react";
import { fade,withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from "react-reveal/Slide";
import { CSVLink  } from "react-csv";
import { Button, Fab } from "@material-ui/core";
import CSVImage from '../../assets/csv.png';
import GetData from "../fetchDataGet";
import { connect } from "react-redux";
import { compose } from "redux";

import './userdata.css'

const style = theme => ({
  table: {
    minWidth: 650,
  },
  search: {
    position: 'relative',
    borderRadius: '0px',
    border:'1px solid lightgray',
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'grey',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '20ch',
      '&:focus': {
        width: '30ch',
      },
    },
  },
  space:
  {
    flexGrow:1
  },
  appbar:
  {
    backgroundColor:'transparent',
    marginTop:'-20px',

  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
})

function createData(fname, lname, email, phone, city,progress,lastlogin,id) {
  return { fname, lname, email, phone, city,progress,lastlogin,id };
}

// const rows = [
//   createData('Muhammad','Ammar', 'ammarnaeem77@gmail.com', '+11-111-1111', 'Khi-Sindh', '10 of 21','08/08/2020','12345'),
//   createData('Muhammad','Ammar', 'ammarnaeem77@gmail.com', '+11-111-1111', 'Khi-Sindh', '10 of 21','08/08/2020','12345'),
//   createData('Muhammad','Ammar', 'ammarnaeem77@gmail.com', '+11-111-1111', 'Khi-Sindh', '10 of 21','08/08/2020','12345'),
//   createData('Muhammad','Ammar', 'ammarnaeem77@gmail.com', '+11-111-1111', 'Khi-Sindh', '10 of 21','08/08/2020','12345'),
//   createData('Muhammad','Ammar', 'ammarnaeem77@gmail.com', '+11-111-1111', 'Khi-Sindh', '10 of 21','08/08/2020','12345'),
//   createData('Muhammad','Ammar', 'ammarnaeem77@gmail.com', '+11-111-1111', 'Khi-Sindh', '10 of 21','08/08/2020','12345'),
//   createData('Muhammad','Ammar', 'ammarnaeem77@gmail.com', '+11-111-1111', 'Khi-Sindh', '10 of 21','08/08/2020','12345'),
//   createData('Muhammad','Ammar', 'ammarnaeem77@gmail.com', '+11-111-1111', 'Khi-Sindh', '10 of 21','08/08/2020','12345'),
//   createData('Muhammad','Ammar', 'ammarnaeem77@gmail.com', '+11-111-1111', 'Khi-Sindh', '10 of 21','08/08/2020','12345'),
//   createData('Muhammad','Ammar', 'ammarnaeem77@gmail.com', '+11-111-1111', 'Khi-Sindh', '10 of 21','08/08/2020','12345'),
//   createData('Muhammad','Ammar', 'ammarnaeem77@gmail.com', '+11-111-1111', 'Khi-Sindh', '10 of 21','08/08/2020','12345'),
//   createData('Muhammad','Ammar', 'ammarnaeem77@gmail.com', '+11-111-1111', 'Khi-Sindh', '10 of 21','08/08/2020','12345'),
//   createData('Muhammad','Ammar', 'ammarnaeem77@gmail.com', '+11-111-1111', 'Khi-Sindh', '10 of 21','08/08/2020','12345'),
//   createData('Muhammad','Ammar', 'ammarnaeem77@gmail.com', '+11-111-1111', 'Khi-Sindh', '10 of 21','08/08/2020','12345'),
//   createData('Muhammad','Ammar', 'ammarnaeem77@gmail.com', '+11-111-1111', 'Khi-Sindh', '10 of 21','08/08/2020','12345'),
//   createData('Muhammad','Ammar', 'ammarnaeem77@gmail.com', '+11-111-1111', 'Khi-Sindh', '10 of 21','08/08/2020','12345'),
//   createData('Muhammad','Ammar', 'ammarnaeem77@gmail.com', '+11-111-1111', 'Khi-Sindh', '10 of 21','08/08/2020','12345'),
//   createData('Muhammad','Ammar', 'ammarnaeem77@gmail.com', '+11-111-1111', 'Khi-Sindh', '10 of 21','08/08/2020','12345'),
//   createData('Muhammad','Ammar', 'ammarnaeem77@gmail.com', '+11-111-1111', 'Khi-Sindh', '10 of 21','08/08/2020','12345'),
//   createData('Muhammad','Ammar', 'ammarnaeem77@gmail.com', '+11-111-1111', 'Khi-Sindh', '10 of 21','08/08/2020','12345'),
//   createData('Muhammad','Ammar', 'ammarnaeem77@gmail.com', '+11-111-1111', 'Khi-Sindh', '10 of 21','08/08/2020','12345'),
//   createData('Muhammad','Ammar', 'ammarnaeem77@gmail.com', '+11-111-1111', 'Khi-Sindh', '10 of 21','08/08/2020','12345'),
//   createData('Muhammad','Ammar', 'ammarnaeem77@gmail.com', '+11-111-1111', 'Khi-Sindh', '10 of 21','08/08/2020','12345'),
//   createData('Muhammad','Ammar', 'ammarnaeem77@gmail.com', '+11-111-1111', 'Khi-Sindh', '10 of 21','08/08/2020','12345'),
//   createData('Muhammad','Ammar', 'ammarnaeem77@gmail.com', '+11-111-1111', 'Khi-Sindh', '10 of 21','08/08/2020','12345'),
// ];

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    fontWeight:'bold',
    fontSize:17,
    borderBottom:'0px'

  },
  body: {
    fontSize: 14,
    borderBottom:'0px'
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    cursor:'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);



class UserData extends Component {
  constructor(props) {
    super(props);
    this.state = { users:[],loading:true,onSearch:false,searchText:'',rowsPerPage:10,page:1,totalPages:0,totalRecords:0 };
    this.csvRef = React.createRef();

  }

  componentDidMount()
  {
    GetData(this.props.HerokuHost+`/account/all?page=${this.state.page}&pageSize=${this.state.rowsPerPage}`, 200, this.props.token, this.handleUserData, this.onFailed);
  }

  getUserData(page=this.state.page,rowsPerPage=this.state.rowsPerPage)
  {
    GetData(this.props.HerokuHost+`/account/all?page=${page}&pageSize=${rowsPerPage}`, 200, this.props.token, this.handleUserData, this.onFailed);
  }

  handleUserData = (response) =>
  {
    if(response.response)
    {
      let users=response.response.users;

      let {pageNumber,pageSize,totalPages,totalRecords} = response.response;
      
      this.setState({loading:false,users,page:pageNumber,rowsPerPage:pageSize,totalPages:totalPages,totalRecords:totalRecords})
      
    }
    else if (response.validationErrorsList.length > 0) {
      this.setState({loading:false})
      alert(response.validationErrorsList[0].errorMessage);
      console.log("validation error response on user-data => ", response);
    } else {
      this.setState({loading:false})
      alert("Unknown error occured!");
      console.log("unknown error response on user-data => ", response);
    }
  }

  onFailed = (response) => {
    console.log(response);
    alert("Failed response");
  };

  handleSearch = () =>
  {
    if(this.state.searchText && this.state.searchText!=="" && this.state.searchText!==" " && this.state.searchText!=null)
    {
      this.setState({ users:[],loading:true,onSearch:true,})
      GetData(this.props.HerokuHost+`/account/all?search=${this.state.searchText}`, 200, this.props.token, this.handleUserData, this.onFailed);
    }

    else if(this.state.searchText===" " || this.state.searchText==="")
    {
      if(this.state.onSearch===true)
      {
        this.setState({ users:[],loading:true,onSearch:false,})
        GetData(this.props.HerokuHost+`/account/all?search=${this.state.searchText}`, 200, this.props.token, this.handleUserData, this.onFailed);
      }
    }

    return;
  }

  handleChangeSearch = (e) =>
  {
    this.setState({ [e.target.id || e.target.name]:e.target.value })
  }


  handleCsvDownload = () =>
  {
    this.csvRef.current.link.click();
  }

  handleChangePage = (event, newPage) => {
    this.getUserData(newPage);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({rowsPerPage:parseInt(event.target.value)})
    this.getUserData(1,parseInt(event.target.value))
  };

  

  render() {
    
    const {classes} = this.props;
    const {rowsPerPage,page} = this.state;
    return (
      <div>



        <AppBar position="static" className={classes.appbar} elevation={0}>
          <Toolbar>
            <div className={classes.space}>
              
            </div>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search Database"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                value={this.state.searchText}
                onChange={this.handleChangeSearch}
                id="searchText"
              />
            </div>
            <div className="user-data-search-button-div">
              <Button variant="contained" className="user-data-search-button" onClick={this.handleSearch}> Search </Button>
            </div>

          </Toolbar>
        </AppBar>

        {this.state.loading
          ?
          <Backdrop className={classes.backdrop} open={this.state.loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          :
          <Slide left>

            <TableContainer component={Paper}>
              {/* margin for csv button overlapping with table date */}

              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead styld={{fontWeight:'bold'}}>
                  <TableRow>
                    <StyledTableCell align="left">First Name</StyledTableCell>
                    <StyledTableCell align="left">Last Name</StyledTableCell>
                    <StyledTableCell align="left">Email</StyledTableCell>
                    <StyledTableCell align="left">Phone</StyledTableCell>
                    <StyledTableCell align="left">City, State</StyledTableCell>
                    <StyledTableCell align="left">Progress</StyledTableCell>
                    <StyledTableCell align="left">Last Login</StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>   
                  {this.state.users.map((row) => (
                    <StyledTableRow key={row.userId}  onClick={()=>this.props.handleSelectedUser(row)}>
                      <StyledTableCell component="th" scope="row">
                        {row.name.split(' ')[0]}
                      </StyledTableCell>
                      <StyledTableCell align="left">{row.name.split(' ')[1]}</StyledTableCell>
                      <StyledTableCell align="left">{row.userName}</StyledTableCell>
                      <StyledTableCell align="left">{row.phone || " "}</StyledTableCell>
                      <StyledTableCell align="left">{row.address}</StyledTableCell>
                      <StyledTableCell align="left">{row.progress}</StyledTableCell>
                      <StyledTableCell align="left">{row.lastLogin?row.lastLogin.split('T')[0] : ""}</StyledTableCell>

                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{marginRight:"50px",marginBottom:'80px'}}>
              <TablePagination
                rowsPerPageOptions={[10, 50, 100]}
                component="div"
                count={this.state.totalRecords}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}

              />
            </div>
            
          </Slide>
        }

        { this.state.users.length>0 ?
          <div>
            <CSVLink
              ref={this.csvRef}
              style={{display:'none'}}
              data={this.state.users}
              filename= {"user-data.csv"}
            >
            </CSVLink>
            <div className="csv-download-wrapper">
              <Fab onClick={this.handleCsvDownload} className="csv-download-button">
                <img src={CSVImage} width="32px"/>
              </Fab>
            </div>
           
          </div>
          :null
        }

      </div>
    );
  }
  
}

const mapStateToProps = (state) => {
  return {
    token: state.setUser.token,
    HerokuHost: state.setHerokuHost,
  };
};

export default compose(
  connect(mapStateToProps, {}),
  withStyles(style)
)(UserData);


