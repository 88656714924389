import React from "react";
import "./StudyGroups.scss";
import { Button } from "@material-ui/core";
import PostData from "../fetchDataPost";
import { connect } from "react-redux";




const GroupRequest = (props) => {

  const [loader,setLoader] = React.useState(false);


  const Accept = (invitationId) =>
  {
    setLoader(true)
    PostData(props.HerokuHost+`/account/group/invitation/${invitationId}/accept`,{},200,props.token,checkAccept,onFailed);
  }

  const checkAccept = (response) =>
  {
    if(response.responseCode==="Success")
    {
      props.handleRemoveGroupRequest(props.invitation.invitationId);
    }

    else if( (response.responseCode==="Error") || (response.responseCode==="Failed"))
    {
      alert('Error on group request = > ' ,response.validationErrorList[0].errorMessage)
      console.log(response)
    }

    else 
    {
      alert('Something went wrong... ' ,response.validationErrorList[0].errorMessage)
      console.log(response)
    }

    setLoader(false)

  }

  const onFailed = (response) =>
  {
    console.log(response)
    alert('Something went wrong on request')
  }

  const checkDecline = (response) =>
  {
    if(response.responseCode==="Success")
    {
      props.handleRemoveGroupRequest(props.invitation.invitationId);
    }

    else if( (response.responseCode==="Error") || (response.responseCode==="Failed"))
    {
      alert('Error on group request = > ' ,response.validationErrorList[0].errorMessage)
      console.log(response)
    }

    else 
    {
      alert('Something went wrong... ' ,response.validationErrorList[0].errorMessage)
      console.log(response)
    }

    setLoader(false)

  }

  const Decline = (invitationId) =>
  {
    setLoader(true)
    PostData(props.HerokuHost+`/account/group/invitation/${invitationId}/decline`,{},200,props.token,checkDecline,onFailed);
  }



  return (
    <div className="SG-GN-Group-Container" style={{opacity:loader===true?'0.5':'1'}}>
      <div className="SG-GN-noti-div"></div>
      <div className="SG-GN-details-div">
        <div className="SG-GN-name">
          {props.invitation.invitedBy.name} has invited you to join his <br /> Study Group ({props.invitation.group.title})
        </div>
        <div className="SG-GN-members">{props.invitation.groupMembers}</div>
        <div className="SG-GN-buttons">
          <Button id="SG-GN-buttons-accept" variant="contained" onClick={()=>Accept(props.invitation.invitationId)}>
            Accept
          </Button>
          <Button id="SG-GN-buttons-reject" variant="contained" onClick={()=>Decline(props.invitation.invitationId)}>
            decline
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    HerokuHost: state.setHerokuHost,
    token: state.setUser.token,
  };
};

export default connect(mapStateToProps, null)(GroupRequest)