import React, { Component } from "react";
import { fade,withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';

import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from "react-reveal/Slide";
import { CSVLink  } from "react-csv";
import { Button, Fab } from "@material-ui/core";
import CSVImage from '../../assets/csv.png';
import GetData from "../fetchDataGet";
import { connect } from "react-redux";
import { compose } from "redux";
import GetAppIcon from '@material-ui/icons/GetApp';
import QueueIcon from '@material-ui/icons/Queue';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import DeleteIcon from '@material-ui/icons/Delete';
import swal from '@sweetalert/with-react'
import EditIcon from '@material-ui/icons/Edit';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';

import './orgdata.css'
import DeleteData from "../fetchDataDelete";
import PostData from "../fetchDataPost";

const style = theme => ({
  table: {
    minWidth: 650,
  },
  search: {
    position: 'relative',
    borderRadius: '0px',
    border:'1px solid lightgray',
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'grey',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '20ch',
      '&:focus': {
        width: '30ch',
      },
    },
  },
  space:
  {
    flexGrow:1
  },
  appbar:
  {
    backgroundColor:'transparent',
    marginTop:'-20px',

  },
  backdrop: {
    zIndex: 1400,
    color: '#fff',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
})

function createData(id, orgName, prefix) {
  return { id, orgName, prefix };
}

const rows = [
  createData('1','ADDY DOWN', 'AD'),
];

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    fontWeight:'bold',
    fontSize:17,
    borderBottom:'0px'

  },
  body: {
    fontSize: 14,
    borderBottom:'0px'
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    // cursor:'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const genModalConfigInitial = {show:false, row:null, numberOfCodes:null, validFor:null, genCodeType:null, ignorePrefix:true, isCustomName:false, customName:null}



class OrganaizationData extends Component {
  constructor(props) {
    super(props);
    this.state = { users:[],loading:true,onSearch:false,searchText:'',rowsPerPage:10,page:1,totalPages:0,totalRecords:0,showGenerateCodeModal:false, row:null, genModalConfig: { ...genModalConfigInitial}  };
    this.csvRef = React.createRef();

  }

  componentDidMount()
  {
    this.getUserData();
  }

  onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  getUserData = () =>  {
    this.setState({loading:true})
    GetData(this.props.HerokuHost+'/organization/getall', 200, this.props.token, this.handleUserData, this.onFailed);
  }

  handleUserData = (response) =>
  {
    if(response.response)
    {
      let users=response.response.organizations;
     
      this.setState({loading:false,users})
      
    }
    else if (response.validationErrorsList.length > 0) {
      this.setState({loading:false})
      alert(response.validationErrorsList[0].errorMessage);
      console.log("validation error response on user-data => ", response);
    } else {
      this.setState({loading:false})
      alert("Unknown error occured!");
      console.log("unknown error response on user-data => ", response);
    }
  }

  onFailed = (response) => {
    console.log(response);
    alert("Failed response");
    this.setState({loading:false})
  };

  handleSearch = () =>
  {
    if(this.state.searchText && this.state.searchText!=="" && this.state.searchText!==" " && this.state.searchText!=null)
    {
      this.setState({ users:[],loading:true,onSearch:true,})
      GetData(this.props.HerokuHost+`/organization?search=${this.state.searchText}`, 200, this.props.token, this.handleUserData, this.onFailed);
    }

    else if(this.state.searchText===" " || this.state.searchText==="")
    {
      if(this.state.onSearch===true)
      {
        this.setState({ users:[],loading:true,onSearch:false,})
        GetData(this.props.HerokuHost+`/organization?search=${this.state.searchText}`, 200, this.props.token, this.handleUserData, this.onFailed);
      }
    }

    return;
  }


  getOrgData = (response) =>
  {
    console.log('res',response)
    if(response.response)
    {
      let users=response.response.organizations;
     
      this.setState({loading:false,users})
      
      // console.log(users)

    }
    else if (response.validationErrorsList.length > 0) {
      this.setState({loading:false})
      alert(response.validationErrorsList[0].errorMessage);
      console.log("validation error response on user-data => ", response);
    } else {
      this.setState({loading:false})
      alert("Unknown error occured!");
      console.log("unknown error response on user-data => ", response);
    }
  }


  handleOpenGenerateCodeModal = (row) => {
    this.setState({ genModalConfig: { ...genModalConfigInitial, show:true, row:row} })
  }

  handleCloseGenModal = () => {
    this.setState({ genModalConfig: { ...genModalConfigInitial,show:false } })
  }



  handleChangeSearch = (e) =>
  {
    this.setState({ [e.target.id || e.target.name]:e.target.value })
  }


  handleCsvDownload = () =>
  {
    this.csvRef.current.link.click();
  }

  handleAdd = () => {
    this.props.handleToggleView("AddOrganaization");
  }

  GenerateCodes = () => {
    this.setState({loading:true}) 

    const genModalConfigT = this.state.genModalConfig;

    let body = {
      "organizationId": genModalConfigT.row.id,
    }

    if(genModalConfigT.genCodeType?.toString()==="1")
    {
      body["codeType"] = 1;
      body["numberOfCodes"] = parseInt(genModalConfigT.numberOfCodes);

    }
    else if(genModalConfigT.genCodeType?.toString()==="2") {
      body["codeType"] = 2;
      body["validFor"] = parseInt(genModalConfigT.validFor);
    }

    body["ignorePrefix"] = genModalConfigT.ignorePrefix;

    if(genModalConfigT.isCustomName)
    {
      body["customCode"] = genModalConfigT.customName;
    }
    


    PostData(this.props.HerokuHost+`/organization/generatecodes`, body ,200,this.props.token,this.handleGenerateCodes,this.onFailed)
  }

  handleGenerateCodes = (response) => {

    console.log('res',response)
    if(response.response)
    {     
      this.setState({loading:false, genModalConfig:{ show:false, ...genModalConfigInitial }})
      swal("Generate Codes", "Code Generated Succesfully!", "success", {
        button: "Done!",
      });
    }
    else if (response.validationErrorsList.length > 0) {
      this.setState({loading:false})
      alert(response.validationErrorsList[0].errorMessage);
      console.log("validation error response on user-data => ", response);
    } else {
      this.setState({loading:false})
      alert("Unknown error occured!");
      console.log("unknown error response on user-data => ", response);
    }

  }

  handleViewCodes = (row) => {
    // this.setState({loading:true})
    this.props.handleSelectedUser(row,"OrganaizationViewCodes");
  }

  handleDownloadCodes = (row) => {
    // this.setState({loading:true})
    this.props.handleSelectedUser(row,"OrganaizationCodes");
  }

  handleEditOrg = (row) => {
    this.props.handleSelectedUser(row,"OrganaizationEdit");

  }

  handleDelete = (row) => {
    swal({
      content: <div>
        <h4>
          {"DELETE ORGANIZATION"}
        </h4>
        Are you sure you want to delete {row.title} Organization? <br />
        
      </div>
      ,buttons: { a: "No.", b: "Yes, i am sure." },
    }).then((a, b, c) => {
      if (a === "a") {
        //Overlap
        return;
      }
      else if (a === "b") {
        //ReCreate
        this.setState({loading:true})
        DeleteData(this.props.HerokuHost + `/organization/${row.id}`,
        200,
        this.props.token,
        (data) => {
          
         this.getUserData();
        },
        this.onFailed)
        return;
      }
    });
  }


  handleChangeGenModal = (e) => {
    if(e.target.name === 'ignorePrefix')
    {
      this.setState({genModalConfig: {...this.state.genModalConfig, ignorePrefix:e.target.checked}})
    }
    else if(e.target.name === 'isCustomName')
    {
      if(e.target.checked)
      {
        this.setState({genModalConfig: {...this.state.genModalConfig, numberOfCodes: 1,  isCustomName:e.target.checked}})
      }
      else  this.setState({genModalConfig: {...this.state.genModalConfig, isCustomName:e.target.checked}})

    }
    else  this.setState({genModalConfig: {...this.state.genModalConfig, [e.target.name]:e.target.value}})
  }

  

  render() {
    
    const {classes} = this.props;
    return (
      <div>

        <Modal
          aria-labelledby="gen-modal-title"
          aria-describedby="gen-modal-description"
          className={classes.modal}
          open={this.state.genModalConfig.show}
          onClose={this.handleCloseGenModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.genModalConfig.show}>
            <div className={classes.paper}>
              <h2 id="transition-modal-title">{`Generate Codes For ${this.state.genModalConfig.row?.title}`}</h2>
              <div id="transition-modal-description">
                <div>
                  Code Type: 
                  
                  <Select
                    labelId="gen-code-type-label"
                    id="gen-code-type"
                    name="genCodeType" 
                    value={this.state.genModalConfig.genCodeType}
                    onChange={this.handleChangeGenModal}
                    input={<Input />}
                    MenuProps={MenuProps}
                    minWidth = {250}
                    style={{minWidth:"250px", marginLeft:"20px"}}
                  >
                    {this.props.CodeTypes.map((CodeTypes) => (
                      <MenuItem key={CodeTypes.name} value={CodeTypes.id}>
                        {CodeTypes.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <div>
                  Custom Code Name: 
                  <Checkbox name="isCustomName" id="isCustomName"  checked={this.state.genModalConfig.isCustomName} onChange={this.handleChangeGenModal}>Custom Code Name</Checkbox>
                </div>
                 
                {
                  this.state.genModalConfig.genCodeType?.toString() === "2" &&
                  <div>
                    Valid For: <Input style={{marginLeft:"20px"}} type="number" value={this.state.genModalConfig.validFor} name="validFor" onChange={this.handleChangeGenModal} />
                  </div>
                }

                {
                  this.state.genModalConfig.genCodeType?.toString() === "1" && 
                  
                  <div>
                    Number of Codes: <Input disabled={this.state.genModalConfig.isCustomName} style={{marginLeft:"20px"}} type="number" value={this.state.genModalConfig.numberOfCodes} name="numberOfCodes" onChange={this.handleChangeGenModal} />
                  </div>
                }

                {
                  this.state.genModalConfig.isCustomName &&
                  <div>
                    Custom Code Name: <Input style={{marginLeft:"20px"}} type="text" value={this.state.genModalConfig.customName} name="customName" onChange={this.handleChangeGenModal} />
                  </div>
                }

                <div>
                  Ignore Prefix: 
                  <Checkbox name="ignorePrefix" id="ignorePrefix"  checked={this.state.genModalConfig.ignorePrefix} onChange={this.handleChangeGenModal}>Ignore Prefix</Checkbox>
                </div>
                  
                <Button variant="contained" className="gen-code-modal-button" onClick={this.GenerateCodes}> Generate </Button>
              </div>
            </div>
          </Fade>
        </Modal>

        <AppBar position="static" className={classes.appbar} elevation={0}>
          <Toolbar>
            <div className={classes.space}>
              
            </div>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search Database"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                value={this.state.searchText}
                onChange={this.handleChangeSearch}
                id="searchText"
                disabled
              />
            </div>
            <div className="user-data-search-button-div">
              <Button variant="contained" className="user-data-search-button disabled-button" onClick={this.handleSearch} disabled> Search </Button>
            </div>

           
 
          </Toolbar>

          <Toolbar>
            <div className={classes.space}>
              
            </div>
            <div className="user-data-search-button-div">
              <Button variant="contained" className="user-data-search-button" onClick={this.handleAdd}> Add Organization </Button>
            </div>
          </Toolbar>

         
        </AppBar>

        {this.state.loading
          ?
          <Backdrop className={classes.backdrop} open={this.state.loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          :
          <Slide left>

            <TableContainer component={Paper} style={{marginBottom:'80px'}}>
              {/* margin for csv button overlapping with table date */}

              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead styld={{fontWeight:'bold'}}>
                  <TableRow>
                    <StyledTableCell align="left">Organization Id</StyledTableCell>
                    <StyledTableCell align="left">Organization Name</StyledTableCell>
                    <StyledTableCell align="left">Prefix</StyledTableCell>
                    {/* <StyledTableCell align="left">Active Registration Codes</StyledTableCell> */}
                    <StyledTableCell align="left">View Codes</StyledTableCell>

                    <StyledTableCell align="left">Generate Codes</StyledTableCell>
                    {/* <StyledTableCell align="left">Get Codes</StyledTableCell> */}
                    <StyledTableCell align="left">Edit Organization</StyledTableCell>
                    <StyledTableCell align="left">Delete Organization</StyledTableCell>


                  </TableRow>
                </TableHead>

                <TableBody>   
                  {this.state.users.map((row) => (
                    <StyledTableRow key={row.id}  
                      // onClick={()=>this.props.handleSelectedUser(row)}
                    >
                      <StyledTableCell align="left">{row.id}</StyledTableCell>
                      <StyledTableCell align="left">{row.title}</StyledTableCell>
                      <StyledTableCell align="left">{row.codePrefix || " "}</StyledTableCell>
                      {/* <StyledTableCell align="left">{row.activeRegistrationCodes || " "}</StyledTableCell> */}
                      <StyledTableCell align="left" style={{cursor:"pointer"}}> <ViewComfyIcon  onClick={()=>this.handleViewCodes(row)} /> </StyledTableCell>

                      
                      <StyledTableCell align="left" style={{cursor:"pointer"}}> <QueueIcon  onClick={()=>this.handleOpenGenerateCodeModal(row)} /> </StyledTableCell>
                      {/* <StyledTableCell align="left" style={{cursor:"pointer"}}> <GetAppIcon   onClick={()=>this.handleDownloadCodes(row)} /> </StyledTableCell> */}
                      <StyledTableCell align="left" style={{cursor:"pointer"}}> <EditIcon   onClick={()=>this.handleEditOrg(row)} /> </StyledTableCell>

                      
                      <StyledTableCell align="left" disabled> <DeleteIcon  disabled  onClick={()=>{}} /> </StyledTableCell>

                      

                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Slide>
        }

        {/* { rows.length>0 ?
          <div>
            <CSVLink
              ref={this.csvRef}
              style={{display:'none'}}
              data={rows}
              filename= {"user-data.csv"}
            >
            </CSVLink>
            <div className="csv-download-wrapper">
              <Fab onClick={this.handleCsvDownload} className="csv-download-button">
                <img src={CSVImage} width="32px"/>
              </Fab>
            </div>
           
          </div>
          :null
        } */}

      </div>
    );
  }
  
}

const mapStateToProps = (state) => {
  return {
    token: state.setUser.token,
    HerokuHost: state.setHerokuHost,
  };
};

export default compose(
  connect(mapStateToProps, {}),
  withStyles(style)
)(OrganaizationData);


