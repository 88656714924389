import React from 'react';

// import React, { Component } from 'react';
// import { connect } from "react-redux";
// import GetData from "../fetchDataGet";


// class GroupNamesComp extends Component {
//   constructor(props) {
//     super(props);
//     this.state = { groups:[] }
//   }

//   componentDidMount()
//   {

//   }

//   setGroups = (response) => {
//     console.log("response => ", response);
//     if (response.response) {
//       let groups = response.response.groups;
//       this.setState({ groups, groupsLoader: false });
//     } else if (response.validationErrorsList) {
//       console.log("Error on study group => ", response.validationErrorsList[0].errorMessage);
//     }
//     let global=this;
//     setTimeout(()=>{global.setState({groupsLoader:false})},2000)
//   } 

  
//   getGroups = () => {
//     this.setState({ groupsLoader: true });
//     GetData(this.props.HerokuHost + `/account/group`, 200, this.props.token, this.setGroups, this.onFailed);
//   };


//   render() { 
//     return ( this.state.groups.map((group,id)=><GroupNames group={group}/>) );
//   }
// }

// const mapStateToProps = (state) => {
//   return {
//     HerokuHost: state.setHerokuHost,
//     token: state.setUser.token,
//   };
// };

// export default connect(mapStateToProps, {})(GroupNamesComp);
 
const GroupNames = (props) => {

  const active = props.activeGroup?props.activeGroup.groupId.toString()===props.group.groupId.toString()?true:false:false

  return (
    <div className="SG-GN-Group-Container" style={{background:active?'rgb(218, 217, 217)':''}} onClick={()=>{props.group.hasChange=false; props.setActiveGroup(props.group)}}
    >
       {props.group.hasChange===true?
          <div className="SG-GN-noti-div">
            <div className="SG-GN-noti-dot"></div> 
          </div>
        :null}
      <div className="SG-GN-details-div">
        <div className="SG-GN-name">{props.group.title}</div>
        <div className="SG-GN-members">{props.group.totalMembers} Members</div>
        <div className="SG-GN-msg">{props.group.lastMessage}</div>
      </div>
    </div>
  );
};


export default GroupNames;

