import React, { Component } from "react";
import { Divider, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { TransverseLoading } from "react-loadingg";
import GetArticle from "../fetchDataGet";
import "./GoDeeper.scss";
import Slide from "react-reveal/Slide";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PostData from "../fetchDataPost";
import DeleteData from "../fetchDataDelete";

const ContentTypeID = 1;
class GoDeeper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      id: "",
      title: "",
      author: "",
      content: "",
      imageUrl: "",
      isFavourite:null
    };
  }

  componentDidUpdate(oldprops) {
    if (oldprops.date !== this.props.date) {
      this.getArticle(this.props.date);
    }
  }

  componentDidMount() {
    this.getArticle(this.props.date);
  }

  handleToggleFavourite = (e,id,isArticleFavourite) => {
    this.setState({
      loading: true,
    });


    isArticleFavourite?
    DeleteData(
      
      this.props.HerokuHost + `/contents/deletefavourite/${ContentTypeID}/${id}`
      ,
      {},
      200,
      this.props.token,
      (data) => {
        this.onToggleSuccess(data,id)
      },
      this.onFailed
    )
    :
    PostData(
      this.props.HerokuHost + `/contents/addtofavourite/${ContentTypeID}/${id}`
      ,
      {},
      200,
      this.props.token,
      (data) => {
        this.onToggleSuccess(data,id)
      },
      this.onFailed
    );

    
  }

  onToggleSuccess = (data,id) => {
    console.log("Working", data);
    if (data.responseCode === "Success") {

      let newArticles = JSON.parse(JSON.stringify(this.state.articles));
      let findedRow = newArticles.find((a)=>a?.id?.toString() === id.toString());
      findedRow.isFavourite = !findedRow.isFavourite;

     this.setState({
      loading:false,
      articles:newArticles
     })
    }
  }

  month(number) {
    var month = [];
    month[1] = "January";
    month[2] = "February";
    month[3] = "March";
    month[4] = "April";
    month[5] = "May";
    month[6] = "June";
    month[7] = "July";
    month[8] = "August";
    month[9] = "September";
    month[10] = "October";
    month[11] = "November";
    month[12] = "December";
    return month[number];
  }

  getArticle = (date) => {
    this.setState({
      loading: true,
      id: "",
      title: "",
      author: "",
      content: "",
      imageUrl: "",
      isArticleFavourite:null,
    });

    

    GetArticle(

      this.props.isFavourite? 
      this.props.HerokuHost + `/contents/article/getallfavourtes` : 
      this.props.HerokuHost + `/contents/article/${date}`,
      200,
      this.props.token,
      this.setArticle,
      this.onFailed
    );
  };

  setArticle = (data) => {
    const articles = data.response.contents;
    data.response.contents.length > 0
      ? this.setState({
          loading: false,
          // id: article.id,
          // title: article.title,
          // author: article.author,
          // content: article.content,
          // imageUrl: article.imageUrl,
          // isArticleFavourite: article.isFavourite,
          articles
        })
      : this.setState({
          loading: false,
        });
  };

  onFailed = (response) => {
    console.log(response);
    alert("Failed response");
  };

  render() {
    const {loading,articles} = this.state;
    const {isFavourite} = this.props;
    return loading ? (
      <div className="journal-loader-wrraper">
        <TransverseLoading color="#225b76" size="large" />
      </div>
    ) : (
      <Slide left>
        <>
          <div className="GD-Wrapper">
            {(articles && articles.length > 0) ? articles.map((article,index) => (
              <div className="GD-RightContainer">
                {article.imageUrl ? (
                  <Paper
                    variant="elevation"
                    elevation={2}
                    style={{ backgroundImage: `url(${article.imageUrl})` }}
                    className="GD-ImageContainer"
                  ></Paper>
                ) : null}

                {
                  isFavourite && 
                  <div className="GD-DATE-BLOCK">
                    Date: {this.month(parseInt(article.postDate?.split("T")[0].split("-")[1])) + " "} {" "} {article.postDate?.split("T")[0].split("-")[2]}
                  </div>
                  
                }
                <div className="GD-Title">
                  {article.title}
                  {
                    !isFavourite  &&
                    <div id="favourite-icon-div">
                      <IconButton aria-label="delete">
                        {
                          article.isFavourite ?
                          <FavoriteIcon style={{color:"#225b76"}} onClick={(e)=>this.handleToggleFavourite(e,article.id, article?.isFavourite)} /> :
                          <FavoriteBorderIcon style={{color:"#225b76"}} onClick={(e)=>this.handleToggleFavourite(e,article.id, article?.isFavourite)}/>
                        }
                      </IconButton>
                    </div>
                  }
                </div>
                
                {article.author ? (
                  <div className="GD-Author">{article.author}</div>
                ) : null}
                <div className="GD-Content">
                  {article.content
                    ? article.content.split("\n").map((item, i) => {
                        return <p key={i}>{item}</p>;
                      })
                    : null}
                </div>

                {
                  (articles.length > 0 &&  index !== articles.length - 1) &&
                  <div style={{marginBottom:"20px"}}>
                    <Divider />
                  </div>
                }


              </div>
            )) : (
              <div className="GD-NoArticle">
                No Article for{" "}
                <span className="GD-NoArticle-Day"> {this.props.date}</span>.
              </div>
            )}
          </div>
        </>
      </Slide>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.setUser.userId,
    userName: state.setUser.userName,
    HerokuHost: state.setHerokuHost,
    token: state.setUser.token,
    date: state.setDate.date,
  };
};

export default connect(mapStateToProps, null)(GoDeeper);
