import React, { Component } from 'react';
import ForgotEmail from './forgot-email';
import ForgotSuccess from './forgot-success';


class ForgotController extends Component {
    constructor(props) {
        super(props);
        this.state = { reset:false,email:null  }
    }

    handleChange = ( email ) =>
    {
        this.setState({reset:true,email})
    }

    render() { 
        return (       
        this.state.reset===false?
            <ForgotEmail handleChange={(email)=>this.handleChange(email)}/>
        :   <ForgotSuccess history={this.props.history} email={this.state.email}/> );
    }
}
 
export default ForgotController;