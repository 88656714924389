import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";
import GoDeeperImage from "../../assets/goDeeper2.jpg";
import ListenImage from "../../assets/listen2.png";
import JournalImage from "../../assets/journal2.png";
import CompanionsImage from "../../assets/companions2.jpg";
import WatchImage from "../../assets/watch2.png";
import TrackImage from "../../assets/track-progress2.png";
import StudyImage from "../../assets/study-groups2.png";
import CommentsImage from "../../assets/comments2.png";
import ReadAndReflect from "../../assets/read-and-reflect.png";
import Worship from "../../assets/worship.png";
import Zoom from "react-reveal/Zoom";

import "../../admin/dashboard/dashboard.css";

const style = (theme) => ({
  root: {
    maxWidth: 360,
    width: "calc(100% - 15px)",
    height: "calc(50vh - 183px)",
    boxShadow: "-3px 6px 9px -2px rgba(0, 0, 0, 0.6)",
    borderRadius: "18px",
  },
  media: {
    height: "calc(50vh - 183px)",
  },
});

const dashboardCards = [
  { name: "Go Deeper", link: "/go-deeper", image: GoDeeperImage },
  { name: "Listen", link: "/listen", image: ListenImage },
  { name: "Watch", link: "/watch", image: WatchImage },
  { name: "Journal", link: "/journal", image: JournalImage },
  { name: "Comments", link: "/comments", image: CommentsImage },
  // { name: "Study Groups", link: "/study-groups", image: StudyImage },
  { name: "Companions", link: "/companions", image: CompanionsImage },
  // { name: "Read And Reflect", link: "/read-and-reflect", image: ReadAndReflect },
  { name: "Worship", link: "/worship", image: Worship },
  // { name: "Favourites", link: "/favourites", image: Worship },

];

class UserDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleNav = (link) => {
    this.props.history.push(link);
  };

  render() {
    const { classes } = this.props;
    return (
      <Zoom>
        <Grid container direction="row">
          {dashboardCards.map((card, key) => (
            <Grid key={key} item xs={12} sm={6} md={4} lg={3} xl={3} style={{ marginBottom: "15px" }}>
              <Card className={classes.root}>
                <CardActionArea onClick={() => this.handleNav(card.link)}>
                  <CardMedia className={classes.media} image={card.image} title={card.name} />
                  <div className="dashboard-card-typography">
                    <div>{card.name}</div>
                  </div>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Zoom>
    );
  }
}

export default withStyles(style)(UserDashboard);
