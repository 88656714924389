import React, { Component } from 'react';
import UserData from './UserData';
import UserSpecific from './UserSpecific';

class UserMain extends Component {
    constructor(props) {
        super(props);
        this.state = { view:'Users',selectedUser:null }
    }

    handleToggleView = () =>
    {
        if(this.state.view==="Users")
        this.setState({view:'UserSpecific'});

        else if(this.state.view==="UserSpecific")
        this.setState({view:'Users'});
    }

    handleSelectedUser = (selectedUser) =>
    {
        this.setState({selectedUser,view:'UserSpecific'})
    }
    render() { 
        return ( 
        
        <div>

            {
                this.state.view==="Users"?<UserData handleToggleView={this.handleToggleView} handleSelectedUser={(user)=>this.handleSelectedUser(user)} />
            :
                <UserSpecific handleToggleView={this.handleToggleView} user={this.state.selectedUser} />
            }

        </div> );
    }
}
 
export default UserMain;