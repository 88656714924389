import React, { Component } from "react";
import "../../user/Watch/Watch.scss";
import { videoArray } from "../../user/Watch/videoArray";
import { Button } from "@material-ui/core";
import WatchVideos from "./WatchVideos.jsx";
import AddVideo from "./AddVideo.jsx";
import Slide from "react-reveal/Slide";


class Watch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: videoArray,
      AddVideoMode: false,
      WatchMode: true,
    };
  }

  render() {
    return (
      <Slide left>

      <div className="W-Wrapper">
        

        {this.state.WatchMode ? (
          <WatchVideos videos={this.state.videos} />
        ) : null}

        {this.state.WatchMode ? (
          <div className="W-AddVideo-Btn-Div">
            <Button
              id="W-AddVideo-Btn"
              variant="contained"
              onClick={() =>
                this.setState({ AddVideoMode: true, WatchMode: false })
              }
            >
              Add Video
            </Button>
          </div>
        ) : null}
        {this.state.AddVideoMode ? (
          <AddVideo
            goBack={() =>
              this.setState({ AddVideoMode: false, WatchMode: true })
            }
          />
        ) : null}
      </div>
      </Slide>
    );
  }
}

export default Watch;
