import React, { Component } from "react";
import Message from "./Message";
import { TextField, IconButton } from "@material-ui/core";
import { animateScroll } from "react-scroll";
import { MoreHoriz, Send } from "@material-ui/icons";
import "./StudyGroups.scss";
import GetData from "../fetchDataGet";
import { connect } from "react-redux";
import PostData from "../fetchDataPost";


class GroupChat extends Component {
  
  constructor(props) {
    super(props);
    this.state = {  message:'',thread:[] ,buttonDisable:true}
  }

  componentDidMount()
  {
    this.scrollToBottom();
  }

  componentDidUpdate()
  {
    this.scrollToBottom();
  }
    


  handleChange = (e) =>
  {
    if(e.target.value.length>0)
    {
      this.setState({[e.target.id || e.target.name]:e.target.value,buttonDisable:false})
    }

    else this.setState({[e.target.id || e.target.name]:e.target.value})
  }

  postMessage = () =>
  {
    this.setState({textLoader:true,buttonDisable:true})
    if(this.state.message.length>0)
    {
      PostData(this.props.HerokuHost+`/account/group/${this.props.activeGroup.group.groupId}/thread`,this.state.message,200,this.props.token,this.setMessage,this.onFailed)
    }
  }

  setMessage = (response) =>
  {
    if(response.responseCode==="Success")
    {
      this.setState({message:'',buttonDisable:true})
      this.props.setThread(response.response.thread)
    }

    else
    {
      alert('Something went wrong...')
      console.log(response);
      this.setState({message:'',buttonDisable:false})

    }

    this.setState({textLoader:false})
  }

  onFailed = (response) =>
  {
    alert('Something went wrong.')
    console.log('onFailed => failure at getting chat thread =>',response)
  }

  scrollToBottom = () => {
    animateScroll.scrollToBottom({
      containerId: "scrollbottomdiv",
    });
  };


  render() {
    return (
      
      <div className="SG-GroupChat-Wrapper">
        <div className="SG-GroupChat-header">
          <div className="SG-GroupChat-name">{this.props.activeGroup?this.props.activeGroup.group.title:''}</div>
          <IconButton onClick={() => this.props.swipe(1)}>
            <MoreHoriz
              style={{ color: "#989898", cursor: "pointer" }}
              fontSize="large"
            />
          </IconButton>
        </div>
        <div className="SG-GroupChat-Messages" id="scrollbottomdiv">
          {(this.props.activeGroup && this.props.activeGroup.thread)?this.props.activeGroup.thread.map((message,id)=><Message key={id} message={message} />):null}
        </div>
        <div className="SG-GroupChat-input-Container">
          <div className="SG-GroupChat-WriteComment-Input-div">
            <TextField
              id="message"
              label=""
              onChange={this.handleChange}
              value={this.state.message}
              placeholder="Type Message Here..."
              multiline
              variant="outlined"
              fullWidth
              className="SG-GroupChat-WriteComment-Input"
              disabled={this.state.textLoader}
            />
          </div>
          <div className="SG-GroupChat-WriteComment-Btn-div">
            <button
              id="SG-GroupChat-WriteComment-Btn"
              variant="contained"
              onClick={this.postMessage}
              disabled={this.state.buttonDisable}
              style={{opacity:this.state.buttonDisable?'0.5':'1'}}
            >
              <Send />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    HerokuHost: state.setHerokuHost,
    token: state.setUser.token,
  };
};

export default connect(mapStateToProps, {})(GroupChat);

