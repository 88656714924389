import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';
import { Grid, TextField, Button} from '@material-ui/core';
import FWLogo from "../../assets/TheFamilyWinsLogo.png";

class ForgotSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <Fade>
                <Grid className="Signup-Main-Wrapper" container sm={12} md={12} lg={12}>
                    <div className="Signup-Main-Container">
                        <div className="Signup-Logo-Container">
                            <img src={FWLogo} alt="Logo" className="Signup-Logo" />
                        </div>
                        <div className="Signup-Heading-Text">Reset Password</div>
                        <div className="Signup-Form-Container" style={{width:'100%',textAlign:'center'}}>
                            <Grid item xs={12} md={12}>
                                <div className="Signup2-Name">
                                   Password reset successfull for email <span className="forgot-success-email"> {this.props.email} </span>
                                </div>

                                <div className="forgot-otp-button-wrapper">
                                    <Button variant="contained" style={{}} onClick={this.props.history.push('./login')} className="forgot-otp-button">
                                        Go to Login
                                    </Button>
                                </div>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Fade>
        );
    }
}
 
export default ForgotSuccess;