import React, { Component } from "react";
import Message from "./Message";
import { TextField, IconButton } from "@material-ui/core";
import { animateScroll } from "react-scroll";
import { MoreHoriz, Send } from "@material-ui/icons";
import "./StudyGroups.scss";
import { connect } from "react-redux";
import PostData from "../fetchDataPost";


class GroupChat extends Component {
  
  constructor(props) {
    super(props);
    this.state = {  message:'',threadLength:0 ,buttonDisable:true,newMessagesLength:0 }
  }

  componentDidMount()
  {
    this.scrollToBottom();
    document.getElementById('scrollbottomdiv').addEventListener('scroll' , this.onScroll)
    // let scrollHeight = document.getElementById('scrollbottomdiv').scrollHeight;
    this.setState({lastMessageId:this.props.activeGroup.thread[this.props.activeGroup.thread.length-1].commentId,threadLength:this.props.activeGroup.thread.length,scrollHeight:0})
  }

  onScroll = (e) =>
  {
    let scrollTop = e.target.scrollTop
    // console.log('scrollHeight: ',e.target.scrollHeight)

    if(scrollTop === 0)
    {
      this.setState({scrollHeight:e.target.scrollHeight})
      this.props.getMessages();
    } 
  }

  componentWillUpdate(nextProps)
  {
    if(this.state.threadLength !== nextProps.activeGroup.thread.length)
    {
      if(nextProps.activeGroup.thread[nextProps.activeGroup.thread.length-1].commentId === this.state.lastMessageId)
      {
        // console.log('scrollingTop')
        document.getElementById('scrollbottomdiv').scrollTop = document.getElementById('scrollbottomdiv').scrollHeight - this.state.scrollHeight;
        // this.scrollToBottom();
        // this.setState({lastMessageId:nextProps.activeGroup.thread[nextProps.activeGroup.thread.length-1].commentId , threadLength:nextProps.activeGroup.thread.length})
      }
      // this.setState({threadLength:this.props.activeGroup.thread.length,newMessagesLength:this.props.activeGroup.thread.length - this.state.threadLength})
      // else 
      // {
      //   document.getElementById('scrollbottomdiv').scrollTop = this.state.scrollHeight;
      // }
    }
  }

  componentDidUpdate(prevProps,prevState)
  {

   

    if(this.props.activeGroup.thread[this.props.activeGroup.thread.length-1].commentId !== this.state.lastMessageId)
    {
      // console.log('scrollingToBottom')
      this.scrollToBottom();
      this.setState({lastMessageId:this.props.activeGroup.thread[this.props.activeGroup.thread.length-1].commentId})
    }

    // if(this.state.threadLength !== this.props.activeGroup.thread.length)
    // {
    //   this.setState({threadLength:this.props.activeGroup.thread.length,newMessagesLength:this.props.activeGroup.thread.length - this.state.threadLength})
    // }

  }
    


  handleChange = (e) =>
  {
    if(e.target.value.length>0)
    {
      this.setState({[e.target.id || e.target.name]:e.target.value,buttonDisable:false})
    }

    else this.setState({[e.target.id || e.target.name]:e.target.value})
  }

  postMessage = () =>
  {
    this.setState({textLoader:true,buttonDisable:true})
    if(this.state.message.length>0)
    {
      PostData(this.props.HerokuHost+`/account/group/${this.props.activeGroup.group.groupId}/thread`,this.state.message,200,this.props.token,this.setMessage,this.onFailed)
    }
  }

  setMessage = (response) =>
  {
    if(response.responseCode==="Success")
    {
      this.setState({message:'',buttonDisable:true})
      // this.props.setThread(response.response.thread)
    }

    else
    {
      alert('Something went wrong...')
      console.log(response);
      this.setState({message:'',buttonDisable:false})

    }

    this.setState({textLoader:false})
  }

  onFailed = (response) =>
  {
    alert('Something went wrong.')
    console.log('onFailed => failure at getting chat thread =>',response)
  }

  scrollToBottom = () => {
    animateScroll.scrollToBottom({
      containerId: "scrollbottomdiv",
    });
  };


  render() {
    return (
      
      <div className="SG-GroupChat-Wrapper">
        <div className="SG-GroupChat-header">
          <div className="SG-GroupChat-name">{this.props.activeGroup?this.props.activeGroup.group.title:''}</div>
          <IconButton onClick={() => this.props.swipe(1)}>
            <MoreHoriz
              style={{ color: "#989898", cursor: "pointer" }}
              fontSize="large"
            />
          </IconButton>
        </div>
        <div className="SG-GroupChat-Messages" id="scrollbottomdiv">
          {(this.props.activeGroup && this.props.activeGroup.thread)?this.props.activeGroup.thread.map((message,id)=>
          {
            let date = message.postDate;
            let removedUtcTime = date.replace(/[+]00:00/gi, "")
            var generatedTime =   new Date(new Date(removedUtcTime).toLocaleString() + " UTC");
            const hours =  generatedTime.getHours();
            const mins = generatedTime.getMinutes() < 10 ? '0'+generatedTime.getMinutes() : generatedTime.getMinutes() ; 
            const AmorPm = hours > 12 ? 'PM' : 'AM';
            const calculatedHours = hours > 12 ? hours-12 : hours;
            const nextMessageTime =  id === this.props.activeGroup.thread.length - 1 ? generatedTime : new Date(new Date(this.props.activeGroup.thread[id + 1].postDate).toLocaleString() + " UTC")
            // console.log('message generatedTime ',generatedTime.getDate())
            // console.log('id ',id)
            // if(id===this.props.activeGroup.thread.length - 1)
            // {
            //   console.log('here message post date =>',generatedTime)
            //   console.log('here last time =>',calculatedHours)
            // }
            return(
              <React.Fragment key={id}>
                {id===0?
                <MessageDateHeader date={generatedTime.toLocaleDateString()}/> :
                 null}

                {/* {
                  console.log( 'generated Time  ', generatedTime.getDate().toString())
                }
                {
                  console.log( 'next Message Time  ', nextMessageTime.getDate().toString())
                } */}
                
                

                <Message key={id} message={message} AmorPm={AmorPm} calculatedHours={calculatedHours} mins={mins} />
                {
                id !== (this.props.activeGroup.thread.length) -1 ?
                generatedTime.getFullYear().toString() !== nextMessageTime.getFullYear().toString() ? <MessageDateHeader date={nextMessageTime.toLocaleDateString()}/> :
                generatedTime.getMonth().toString()!== nextMessageTime.getMonth().toString() ? <MessageDateHeader date={nextMessageTime.toLocaleDateString()}/> :
                generatedTime.getDate().toString() !== nextMessageTime.getDate().toString() ? <MessageDateHeader date={nextMessageTime.toLocaleDateString()}/> :
                null:null}
                
              </React.Fragment>
            )
          }
          ):null}
        </div>
        <div className="SG-GroupChat-input-Container">
          <div className="SG-GroupChat-WriteComment-Input-div">
            <TextField
              id="message"
              label=""
              onChange={this.handleChange}
              value={this.state.message}
              placeholder="Type Message Here..."
              multiline
              variant="outlined"
              fullWidth
              className="SG-GroupChat-WriteComment-Input"
              disabled={this.state.textLoader}
            />
          </div>
          <div className="SG-GroupChat-WriteComment-Btn-div">
            <button
              id="SG-GroupChat-WriteComment-Btn"
              variant="contained"
              onClick={this.postMessage}
              disabled={this.state.buttonDisable}
              style={{opacity:this.state.buttonDisable?'0.5':'1'}}
            >
              <Send />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const MessageDateHeader = (props) =>
{
  return (
    <div className="MessageDateHeader-main-div">
      {props.date}
    </div>

  );
}

const mapStateToProps = (state) => {
  return {
    HerokuHost: state.setHerokuHost,
    token: state.setUser.token,
  };
};

export default connect(mapStateToProps, {})(GroupChat);

