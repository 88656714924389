import React, { Component } from 'react';

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import DefaultDP from "../../assets/defaultDP.png";
import PutData from '../fetchDataPut';
import { Button } from '@material-ui/core';
import Zoom from "react-reveal/Zoom";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import PostData from '../fetchDataPost';
import DeleteData from '../fetchDataDelete';


const styles = (theme) => ({
   
    largeAvatar: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
   
  });

class OtherCompanion extends Component {
    constructor(props) {
        super(props);
        this.state = { loader:false }
    }

    componentWillMount()
    {
        this.setState({comp:this.props.comp})
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        console.log(nextProps.newOtherCompUpdate)
        if(nextProps.newOtherCompUpdate.id && nextProps.newOtherCompUpdate.id!==this.props.newOtherCompUpdate.id)
        {
            if(nextProps.newOtherCompUpdate.id.toString()===this.state.comp.userId.toString())
            {
                console.log('update other comp => ',this.state.comp.userId)
                if(nextProps.newOtherCompUpdate.state==='accept')
                {
                    nextProps.comp.isCompanion=true;
                    let comp = this.state.comp;
                    comp.isCompanion=true;
                }
    
                else if(nextProps.newOtherCompUpdate.state==='decline')
                {
                    nextProps.comp.isCompanion=false;
                    nextProps.comp.hasPendingCompanionRequest=false;

                    let comp = this.state.comp;
                    comp.isCompanion=false;
                    comp.hasPendingCompanionRequest=false

                }
                return true;
            }
        }


        

        else if(this.state.comp.hasPendingCompanionRequest!==nextState.comp.hasPendingCompanionRequest || this.state.comp.isMyRequest!==nextState.comp.isMyRequest)
        {

            console.log('true');
            return true;
        }

        else if(this.state.loader!==nextState.loader)
        {
            return true;
        }

        console.log(this.state.comp.hasPendingCompanionRequest);
        console.log(nextState.comp.hasPendingCompanionRequest);

       

        return false;
    }


    handleCancelRequest = (id) => {
        console.log(id);
        this.setState({loader:true}) 

        DeleteData(
          this.props.HerokuHost + `/account/companion/request/${id}`,
          200,
          this.props.token,
          this.handleDeleteRequest,
          this.onFailed
        );
      };

   



    handleAccept = (id) => {
        this.setState({loader:true}) 
        PutData(
            this.props.HerokuHost + `/account/companion/request/${id}/accept`,
            {},
            200,
            this.props.token,
            this.handleAcceptRequest,
            this.onFailed
        );
    };

    handleDeleteRequest = (response) => {
        console.log(response);
        if(response.responseCode==="Success")
        {
            // console.log(response.response.requests[0].companion.userId);
            // this.props.comp.hasPendingCompanionRequest=false;

            let comp = JSON.stringify(this.state.comp);
            comp = JSON.parse(comp);

            comp.isCompanion=false;
            comp.hasPendingCompanionRequest=false;
            comp.isMyRequest=false;

            this.setState({comp});

        }
        else if (response.responseCode === "Failed" || response.responseCode === "Error")
        {
            alert(response.validationErrorsList[0].errorMessage);
        } 
        
        else alert("Something went wrong!");
        
        this.setState({loader:false})
    };
    
    handleAcceptRequest = (response) => {
        console.log(response);
        if(response.responseCode==="Success")
        {
            // console.log(response.response.requests[0].companion.userId);
            // this.props.comp.hasPendingCompanionRequest=false;

            let comp = JSON.stringify(this.state.comp);
            comp = JSON.parse(comp);

            comp.isCompanion=true;
            comp.hasPendingCompanionRequest=false;
            this.setState({comp});

            this.props.handleRequestUpdate({state:'accept',id:this.state.comp.userId});

        }
        else if (response.responseCode === "Failed" || response.responseCode === "Error")
        {
            alert(response.validationErrorsList[0].errorMessage);
        } 
        
        else alert("Something went wrong!");
        
        this.setState({loader:false})
    };
    
    handleDecline = (id) => {
        this.setState({loader:true})
        PutData(
            this.props.HerokuHost + `/account/companion/request/${id}/decline`,
            {},
            200,
            this.props.token,
            this.handleDeclineRequest,
            this.onFailed
        );
    };

    handleDeclineRequest = (response) => {
        console.log(response);
        if(response.responseCode==="Success")
        {
            // console.log(response.response.requests[0].companion.userId);
            // this.props.comp.hasPendingCompanionRequest=false;

            let comp = JSON.stringify(this.state.comp);
            comp = JSON.parse(comp);
            comp.isCompanion=false;
            comp.hasPendingCompanionRequest=false;


            this.setState({comp});

            this.props.handleRequestUpdate({state:'decline',id:this.state.comp.userId});

        }
        else if (response.responseCode === "Failed" || response.responseCode === "Error")
        {
            alert(response.validationErrorsList[0].errorMessage);
        } 
        
        else alert("Something went wrong!");
        
        this.setState({loader:false})

    };

    handleSendRequest = (id) => {
        this.setState({loader:true})
        PostData(
          this.props.HerokuHost + `/account/companion/${id}/request`,
          {},
          200,
          this.props.token,
          this.handleCheckRequestSent,
          this.onFailed
        );
      };
    
      handleCheckRequestSent = (response) => {
        console.log(response);
        if (response.responseCode === "Success") {


            let comp = JSON.stringify(this.state.comp);
            comp = JSON.parse(comp);
            comp.hasPendingCompanionRequest=true;
            comp.isMyRequest=true;

          this.setState({comp})
        }
        
        else if (response.responseCode === "Failed" || response.responseCode === "Error")
        {
            alert(response.validationErrorsList[0].errorMessage);
        } 
        
        else alert("Something went wrong!");
        this.setState({loader:false})

      };


    render() {
        
        const {classes} = this.props;
        const comp=this.state.comp;
        return ( 

            

            <Zoom>
                <ListItem key={comp.userId} style={{opacity:this.state.loader===true?'0.5':'1'}} className="companion-list-item-wrapper">
                    <ListItemAvatar>
                        <Avatar className={classes.largeAvatar}>
                            <img src={comp.photoUrl? comp.photoUrl: DefaultDP} alt={DefaultDP} width={"64px"} />
                        </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                    primary={
                    <span className="companions-mycomp-name">
                        {" "}
                        {comp.name}
                        {!comp.isCompanion && (
                        <div className="companions-request-send-button-wrapper">
                            {comp.hasPendingCompanionRequest ? (
                            comp.isMyRequest ? (
                                <Button
                                variant="contained"
                                className="companions-request-send-button companions-request-cancel-button"
                                onClick={() => this.handleCancelRequest(comp.userId)} > 
                                    Cancel Request
                                </Button>
                            ) : (
                                <div>
                                <Button variant="contained" disabled={this.state.loader} className="other-companions-request-button companions-request-confirm-button" onClick={() => {this.handleAccept(comp.userId); }} >
                                    {/* <CheckIcon
                                    style={{ color: "#ffffff" }}
                                    /> */} Confirm
                                </Button>
                                <br/>
                                <Button variant="contained" disabled={this.state.loader} className="other-companions-request-button companions-request-decline-button" onClick={() => { this.handleDecline( comp.userId); }} >
                                    {/* <CrossIcon
                                    style={{ color: "#ffffff" }}
                                    /> */} Decline
                                </Button>
                                </div>
                            )
                            ) : (
                            <Button
                                variant="contained"
                                className="companions-request-send-button companions-request-confirm-button"
                                disabled={this.state.loader} 
                                onClick={() =>
                                this.handleSendRequest(
                                    comp.userId
                                )
                                }
                            >
                                {" "}
                                Send Request{" "}
                            </Button>
                            )}
                        </div>
                        )}
                    </span>
                    }
        
                />
                </ListItem>
            </Zoom>
        
        );
    }
}

const mapStateToProps = (state) => {
    return {
      HerokuHost: state.setHerokuHost,
      token: state.setUser.token,
    };
  };
  
  export default React.memo(
    connect(mapStateToProps, null)(withStyles(styles)(OtherCompanion))
  );
 
