import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Login from "../auth/login/login";
import Signup from "../auth/signup/signup";
import AdminRoutes from "../admin/adminRoutes";
import UserRoutes from "../user/userRoutes";
import TempAuth from "./tempAuth";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import ForgotController from "../auth/forgot/forgotController";
import { setUser, setDate, setActualDate } from "../store/actions";


class GetRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  CheckZero = (number) => {
    if (number <= 9) {
      return "0" + number;
    } else return number;
  };

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  setCookie(cname, cvalue, exmins) {
    var d = new Date();
    d.setMinutes(d.getMinutes() + exmins);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }



  updateSession = () => {

  };

  ContinueSession() {
    let token = this.getCookie('token')

    let user = this.getCookie('user')
    // console.log('here')
    if (token && token != null && token !== "" && token !== " " && user && user != null && user !== "" && user !== " ") {
      let parsedUser = JSON.parse(user);
      // console.log('setting store')
      let date = new Date();
      let Formatted_date = this.CheckZero(date.getMonth() + 1) + "-" + this.CheckZero(date.getDate()) + "-" + date.getFullYear();

      this.props.setDate(Formatted_date);
      this.props.setActualDate(date);
      this.props.setUser({ ...parsedUser, token: token });
      return true;
    }
    else return false;

  }


  componentWillMount() {


    let stylesArray = [
      `background-image: url(https://firebasestorage.googleapis.com/v0/b/the-familywins.appspot.com/o/TheFamilyWinsLogo.png?alt=media&token=91321b3b-4f0e-4dd2-bab0-b1abbcca96a0)`,
      `background-size: cover`,
      `color: #fff`,
      `padding: 10px 20px`,
      `line-height: 170px`,
      `width : 300px`,
      `height : 162px`,
      `border : 0px solid black`].join(`;`);
    console.log("%c WELCOME TO %c THE FAMILY WINS", "color: np; font-size: 24px", "color: #e25601; font-size: 24px");
    console.log(`%c                                                             `, stylesArray);

    this.setState({ loading: true })

    if (this.ContinueSession() === false) {
      this.setState({ loading: false })
      return;
    }

    // else {
    //   this.setState({loading:false})
    //   return;
    // };
    //check if user is signed in or not, session handling , cookie , session checking from backend.
    //based on data store update ( user data and user type along with token )
    //  ^^^
    //  |||
    //refresh page, user checking goes here
  }

  render() {
    return this.props.userType === "Admin"
      ?
      (
        <AdminRoutes history={this.props.history} />
      )
      :
      this.props.userType === "User" ?
        (
          <UserRoutes history={this.props.history} />
        )
        :
        (
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Signup} />
            <Route path="/temp-auth" component={TempAuth} />
            <Route path="/forgot" component={ForgotController} />

            <Redirect to="/login" />
          </Switch>
        );
  }
}

const mapStateToProps = (state) => {
  return {
    userType: state.setUser.memberType,
  };
};

export default compose(connect(mapStateToProps, { setUser, setDate, setActualDate }), withRouter)(GetRoutes);

// export default connect(mapStateToProps, {})(GetRoutes);
