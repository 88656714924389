import React from "react";
import ReactPlayer from "react-player/lazy";
import ShowMoreText from "react-show-more-text";
import { connect } from "react-redux";
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteData from "../fetchDataDelete";


import {
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Card,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    maxWidth: 310,
    minWidth: 310,
    minHeight: 290,
    margin: "12px",
    boxShadow: "none",
  },
  cardContentRoot: {
    padding: 0,
    paddingTop: 8,
  },
  heading: {
    fontSize: "1.15rem",
    fontWeight: "bold",
  },
});

const VideoCard = (props) => {
  const classes = useStyles();
  const [loading, setloading] = React.useState(false);


  const handleCardClick = () => {
    if(loading===false)
    {
      props.openDialoge();
    }
  };

  const Delete = () =>
  {
    if(loading===false)
    {
      DeleteData(props.HerokuHost+`/contents/video/${props.id}`,200,props.token,Deleted,onFailed)
    }
  }

  const Deleted = (response) =>
  {
    if(response.responseCode==="Success")
    {
      props.handleDelete(props.id);
    }
    else
    {
      alert('error on delting video, please check console for more info!')
      console.log('error on deleting worship video => ',response);
    }
  }

  const onFailed = (response) => {
    console.log("error on delete worship video => ", response);
    alert("Deleting Video Failed!");
  };

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={handleCardClick}>
        <CardMedia
          component={() => (
            <ReactPlayer
              url={props.link}
              light
              controls
              width="100%"
              height={168.75}
              on
            />
          )}
          alt="Video"
          title="Watch video"
        />
      </CardActionArea>
      <CardContent classes={{ root: classes.cardContentRoot }}>
        <Typography
          classes={{ root: classes.heading }}
          variant="h6"
          component="h1"
        >
          {props.title}

          <div className="Wor-Video-spotifyIcon-div">
            <div className="Wor-Video-delete-btn">
              <DeleteIcon className="Wor-Video-delete-icon" onClick={Delete}/>
            </div>
          </div>

        </Typography>
        <Typography variant="body2" color="textPrimary" component="p">
          <ShowMoreText lines={6} width={300} expanded={false}>
            {props.desc}
          </ShowMoreText>
        </Typography>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    HerokuHost: state.setHerokuHost,
    token: state.setUser.token,
  };
};

export default connect(mapStateToProps,{})(VideoCard);