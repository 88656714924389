import React, { Component } from 'react';
import { Grid, TextField, Button} from '@material-ui/core';
import Fade from 'react-reveal/Fade';
import FWLogo from "../../assets/TheFamilyWinsLogo.png";
import { connect } from "react-redux";

import './forgot.css'
import PostData from '../signup/fetchDataPost';


class ForgotEmail extends Component {
    constructor(props) {
        super(props);
        this.state = { otp:'',password:'',cpassword:'',email:'',otpSended:false, error1:false,error2:false,error3:false,error4:false,}
    }

    sendOTP = () =>
    {
        if(this.handleCheckEmail()==true)
        {
            this.setState({loader1:true})

            PostData(this.props.HerokuHost + "/account/forgot", this.state.email, 200, "",this.handleOtpReceive, this.onFailed) //using signup post data

        }
    }

    handleOtpReceive = (response) =>
    {
        if(response.responseCode==="Success")
        {
            console.log(response);
            this.setState({otpSended:true})
        }

        else if(response.responseCode!=="Success")
        {
            this.setState({error1:true,error1Message:response.validationErrorsList[0].errorMessage,loader1:false})
            console.log('error on forgot => ',response)
        }
        
    }

    handleSendPassword = () =>
    {
        if(this.handleCheckPassword()===true)
        {
            this.setState({loader2:true})
            let data=
            {
                emailAddress:this.state.email,
                password:this.state.password,
                code:this.state.otp
            }
            PostData(this.props.HerokuHost + "/account/reset", data , 200, "",this.CheckPasswordChange, this.onFailed) //using signup post data
        }
    }

    CheckPasswordChange = (response) =>
    {
        if(response.responseCode==="Success")
        {
            this.props.handleChange(this.state.email);
        }

        else 
        {
            console.log('error on forgot otp enter => ',response)
            this.setState({error4:true,error4Message:response.validationErrorsList[0].errorMessage,otp:'',loader2:false})
        }
    }

    handleCheckPassword = () =>
    {

        if(this.state.password.length<1)
        {
            this.setState({error2:true,error2Message:'Please enter password.'})
            return false;
        }
        else if(this.state.password.length<8)
        {
            this.setState({error2:true,error2Message:'Password must be atleast 8 character long.'})
            return false;
        }

         //Confirm Password validations...

        
        else if(this.state.cpassword.length<1)
        {
            this.setState({error3:true,error3Message:'Please enter confirm password.'})
            return false;
        }

        else if(this.state.cpassword !== this.state.password)
        {
            this.setState({error3:true,error3Message:'Confirm Password does not match.'})
            return false;
        }

        else return true;
    

    }



    onFailed = (response) =>
    {
        console.log('error on forgot => ',response)
    }

    handleCheckEmail = () =>
    {
        
        if(this.state.email.length<1)
        {
            this.setState({error1:true,error1Message:'Please enter your email address to receive otp.'})
            return false;
        }
        else if(!this.isValidEmail(this.state.email))
        {
            this.setState({error1:true,error1Message:'Please enter a valid email address'})
            return false;
        }

        else 
        {
            this.setState({error1:false,error1Message:''})
            return true;
        }
    }


    isValidEmail = (email) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(email)) {
        return true;
    }
    return false;
    };

    handleChange = (e) =>
    {
        this.setState({[e.target.id || e.target.name]:e.target.value})
        this.handleCheckError(e);
    }

    handleCheckError = (e) =>
    {
        if(e.target.id)
        {

            // Email Validations...
            if(e.target.id.toString()==="email")
            {
                if(e.target.value.length<1)
                {
                    this.setState({error1:true,error1Message:'Please enter your email address to receive otp.'})
                    return false;
                }
                else if(!this.isValidEmail(e.target.value))
                {
                    this.setState({error1:true,error1Message:'Please enter a valid email address'})
                    return false;
                }

                else 
                {
                    this.setState({error1:false,error1Message:''})
                    return true;
                }
                
            }

            //Password validations...

            else if(e.target.id.toString()==="password")
            {
                if(e.target.value.length<1)
                {
                    this.setState({error2:true,error2Message:'Please enter password.'})
                    return false;
                }
                else if(e.target.value.length<8)
                {
                    this.setState({error2:true,error2Message:'Password must be atleast 8 character long.'})
                    return false;
                }

                else 
                {
                    this.setState({error2:false,error2Message:''})
                    return true;
                }

                // else if(hasSpecialCharacter)
                // {
                //     this.setState({error2:true,error2Message:'Password must be atleast 8 character long.'})
                //     return false;
                // }
            }

             //Confirm Password validations...

            else if(e.target.id.toString()==="cpassword")
            {
                if(e.target.value.length<1)
                {
                    this.setState({error3:true,error3Message:'Please enter confirm password.'})
                    return false;
                }

                else if(e.target.value !== this.state.password)
                {
                    this.setState({error3:true,error3Message:'Confirm Password does not match.'})
                    return false;
                }
                else 
                {
                    this.setState({error3:false,error3Message:''})
                    return true;
                }
            }

            //Otp dosnt need validation....

            else if(e.target.id.toString()==="otp")
            {
                this.setState({error4:false,error4Message:''})
                return true;
            }
        }
    }
    render() { 
        return ( 
            <Grid className="Signup-Main-Wrapper" container sm={12} md={12} lg={12}>
                <div className="Signup-Main-Container">
                <div className="Signup-Logo-Container">
                    <img src={FWLogo} alt="Logo" className="Signup-Logo" />
                </div>
                <div className="Signup-Heading-Text">Reset Password</div>
                <div className="Signup-Form-Container" style={{width:'100%',textAlign:'center'}}>
                    <Grid item xs={12} md={12}>
                        <div className="Signup2-Name">
                            <TextField
                            className="forgot-text-field"
                            id="email"
                            label="Email"
                            helperText={this.state.error1Message}
                            error={this.state.error1}
                            fullWidth
                            value={this.state.email}
                            disabled={this.state.otpSended}
                            onChange={this.handleChange}
                            />
                        </div>

                        <div className="forgot-otp-button-wrapper">
                            <Button variant="contained" onClick={this.sendOTP} disabled={this.state.loader1||this.state.otpSended} className="forgot-otp-button">
                                Send Code To Email
                            </Button>
                        </div>
                       

                        {
                            this.state.otpSended===true?
                                    <Fade top cascade>
                                    <div className="forgot-password-fields-wrapper">

                                     <TextField
                                        className="forgot-text-field"
                                        id="otp"
                                        label="Enter code sended to your given email"
                                        helperText={this.state.error4Message}
                                        error={this.state.error4}
                                        fullWidth
                                        value={this.state.otp}
                                        onChange={this.handleChange}
                                    />
                                    <TextField
                                        className="forgot-text-field"
                                        id="password"
                                        label="Enter New Password"
                                        helperText={this.state.error2Message}
                                        error={this.state.error2}
                                        fullWidth
                                        value={this.state.password}
                                        onChange={this.handleChange}
                                    />
                                    <TextField
                                    className="forgot-text-field"
                                    id="cpassword"
                                    label="Enter Confirm Password"
                                    helperText={this.state.error3Message}
                                    error={this.state.error3}
                                    fullWidth
                                    value={this.state.cpassword}
                                    onChange={this.handleChange}
                                    />

                                    <div className="forgot-otp-button-wrapper">
                                        <Button variant="contained" style={{}} onClick={this.handleSendPassword} disabled={this.state.loader2} className="forgot-otp-button">
                                            Change Password
                                        </Button>
                                    </div>
                                
</div>
                                    </Fade>
                                
                                :null

                        }
                        {/* <div className="Signup2-Email">
                           
                        </div> */}
                    </Grid>
                </div>
            </div>
        </Grid>

        
    );
    }
}

const mapStateToProps = (state) => {
    return {
      // userId: state.setUser.userId,
      HerokuHost: state.setHerokuHost,
    };
  };
  
export default connect(mapStateToProps, { })(ForgotEmail);
 
