import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AdminDashboard from "./dashboard/dashboard.jsx";
import { Grid } from "@material-ui/core";
import GoDeeper from "./GoDeeper/GoDeeper.jsx";
import Watch from "./Watch/Watch.jsx";
import SideBar from "../containers/sidebar/sideBar";
import Header from "../containers/header/header";
import Listen from "./listen/MainListen";
import ReadAndReflect from "./ReadAndReflect/MainReadAndReflect";
import Comments from "./Comments/Comments.jsx";
import StudyGroups from "./StudyGroups/StudyGroups.jsx";
import UserData from "./UserData/UserData.jsx";
import EditProfile from "./EditProfile/EditProfile.jsx";
import Worship from "./Worship/Worship";
import DateComp from "../containers/dateComp/dateComp.jsx";

import "./adminRoutes.css";
import UserSpecific from "./UserData/UserSpecific.jsx";
import UserMain from "./UserData/UserMain.jsx";
import OrganaizationMain from "./Organaizations/OrganaizationMain.jsx";

class AdminRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DrawerState: false,
    };
  }

  componentDidMount() {
    console.log("Admin Routes");
  }

  handleToggleDrawer = (isOpen) => {
    this.setState({ DrawerState: isOpen });
  };

  render() {
    return (
      <Grid container direction="row">
        <Grid item sm={2} md={2} lg={2}>
          <SideBar
            admin={true}
            history={this.props.history}
            isOpen={this.state.DrawerState}
            closeDrawer={this.handleToggleDrawer}
          />
        </Grid>
        <Grid item sm={12} md={12} lg={12}>
          {console.log(this.props.history.location.pathname)}
          { ( this.props.history.location.pathname.includes("/user-data") || this.props.history.location.pathname.includes("/organization") ) ? null : (
            <React.Fragment>
              {" "}
              <Header openDrawer={this.handleToggleDrawer} /> <DateComp />{" "}
            </React.Fragment>
          )}

          <main className="admin-route-main">
            <Switch>
              <Route exact path="/dashboard" component={AdminDashboard} />
              <Route path="/go-deeper" component={GoDeeper} />
              <Route path="/listen" component={Listen} />
              <Route path="/read-and-reflect" component={ReadAndReflect} />
              <Route path="/watch" component={Watch} />
              <Route path="/worship" component={Worship} />
              <Route path="/comments" component={Comments} />
              <Route path="/study-groups" component={StudyGroups} />
              <Route exact path="/user-data" component={UserMain} />
              <Route path="/user-data/id=:id" component={UserSpecific} />

              <Route exact path="/organizations" component={OrganaizationMain} />


              <Route path="/edit-profile" component={EditProfile} />
              <Redirect to="/dashboard" />
            </Switch>
          </main>
        </Grid>
      </Grid>
    );
  }
}

export default AdminRoutes;
