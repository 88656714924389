import React, { useState, useRef } from "react";
import { Grid, TextField, Button, makeStyles } from "@material-ui/core";
import AvatarEditor from "react-avatar-editor";
import { TransverseLoading } from "react-loadingg";
import { connect } from "react-redux";
import PostData from "../../user/fetchDataPost";
import "../../user/GoDeeper/GoDeeper.scss";

const EditArticle = (props) => {
  const classes = useStyles();
  const [id, setId] = useState(props.id);
  const [title, setTitle] = useState(props.title);
  const [author, setAuthor] = useState(props.author);
  const [content, setContent] = useState(props.content);
  const [loadedImage, setLoadedImage] = useState(props.image);
  const [imageFile, setImageFile] = useState("");
  const [loading, setLoading] = useState(false);
  const avatarEditor = useRef(null);

  const handlePublish = () => {
    const status = formValidation();
    if (!status) {
      return;
    }
    setLoading(true);
    imageFile ? UploadImage() : uploadArticle(null);
  };

  const UploadImage = async () => {
    setLoading(true);
    let fd = new FormData();
    fd.append("file", imageFile);
    await PostData(
      props.HerokuHost + "/contents/article/upload/image",
      fd,
      200,
      props.token,
      (data) => {
        console.log(data.response.url);
        uploadArticle(data.response.url);
      },
      onFailed,
      true
    );
  };

  const uploadArticle = (articleImage) => {
    const newArticle = {
      id: id,
      title: title,
      author: author ? author : "",
      content: content,
      imageUrl: articleImage ? articleImage : loadedImage ? props.image : "",
    };
    PostData(
      props.HerokuHost + `/contents/article/${props.date}`,
      newArticle,
      200,
      props.token,
      (data) => {
        if (data.responseCode === "Success") {
          props.handleRefresh();
          props.handleEdit(false);
        }
      },
      onFailed
    );
  };

  const handleImageChnage = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.addEventListener("load", () => {
        setLoadedImage(reader.result);
        setImageFile(file);
      });
    }
  };

  const removeImage = () => {
    setLoadedImage(null);
    setImageFile(null);
    document.getElementById("GD-image-input-id").value = null;
  };

  const onFailed = (response) => {
    console.log(response);
    setLoading(false);
    alert("Failed response");
  };

  const formValidation = () => {
    if (!title) {
      alert("Title is empty");
      return false;
    } else if (!content) {
      alert("Content is empty");
      return false;
    }
    return true;
  };

  return (
    <div className="GD-AddArticle-Wrapper">
      <Grid container direction="row" xs={12} className="GD-Title-Container">
        <Grid item xs={12} sm={12} md={3} className="GD-Title-Text">
          Title:{" "}
        </Grid>
        <Grid item xs={12} sm={12} md={8} className="GD-Title-Input">
          <TextField
            label=""
            variant="outlined"
            fullWidth
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutline,
              },
            }}
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" xs={12} className="GD-Author-Container">
        <Grid item xs={12} sm={12} md={3} className="GD-Author-Text">
          Author:{" "}
        </Grid>
        <Grid item xs={12} sm={12} md={8} className="GD-Author-Input">
          <TextField
            label=""
            variant="outlined"
            fullWidth
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutline,
              },
            }}
            value={author}
            onChange={(event) => setAuthor(event.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" xs={12} className="GD-Content-Container">
        <Grid item xs={12} sm={12} md={3} className="GD-Content-Text">
          Content:{" "}
        </Grid>
        <Grid item xs={12} sm={12} md={8} className="GD-Content-Input">
          <TextField
            label=""
            variant="outlined"
            fullWidth
            multiline
            rows={15}
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutline,
              },
            }}
            value={content}
            onChange={(event) => setContent(event.target.value)}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        xs={12}
        className="GD-SelectImage-Container"
      >
        <Grid item xs={12} sm={12} md={3} className="GD-SelectImage-Text">
          Upload Image:{" "}
        </Grid>
        <Grid item xs={12} sm={12} md={8} className="GD-SelectImage-Input">
          <label class="custom-file-upload">
            <input
              id="GD-image-input-id"
              type="file"
              onChange={handleImageChnage}
            />
            Select Image
          </label>

          {loadedImage ? (
            <>
              <p className="GD-RemoveImage" onClick={removeImage}>
                Remove Image
              </p>
              <div style={{ marginTop: "10px" }}>
                <AvatarEditor
                  ref={avatarEditor}
                  image={loadedImage}
                  width={250}
                  height={250}
                  border={50}
                  borderRadius={0}
                  color={[255, 255, 255, 0.6]} // RGBA
                  scale={1}
                  rotate={0}
                />
              </div>
            </>
          ) : null}
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        xs={12}
        className="GD-BottomBtns-Container"
      >
        <Button
          id="GD-Back-Button"
          variant="contained"
          onClick={() => props.handleEdit(false)}
        >
          Back
        </Button>
        <Button
          id="GD-Publish-Button"
          variant="contained"
          onClick={() => handlePublish()}
          style={{ padding: loading ? "23px 0" : "11px 0" }}
          disabled={loading}
        >
          {loading ? (
            <TransverseLoading color="#ffffff" size="large" />
          ) : (
            "Publish Article"
          )}
        </Button>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  notchedOutline: {
    borderWidth: "1.5px",
    borderColor: "#989898",
  },
}));

const mapStateToProps = (state) => {
  return {
    HerokuHost: state.setHerokuHost,
    token: state.setUser.token,
    date: state.setDate.date,
  };
};

export default connect(mapStateToProps, null)(EditArticle);
