import React, { useState } from "react";
import { Button, Grid } from "@material-ui/core";
import Fade from "react-reveal/Fade";
// import Signup1 from "./signup1";
import Signup2 from "./signup2";
// import Signup3 from "./signup3";
import FWLogo from "../../assets/TheFamilyWinsLogo.png";
import "./signup.scss";
import PostData from "./fetchDataPost";
import { connect } from "react-redux";
import { TransverseLoading } from "react-loadingg";
import { setUser, setDate, setActualDate } from "../../store/actions";
import GetData from "../login/loginFetch";
import GetDataUser from "../../user/fetchDataGet";
import Signup4 from "./signup4";

const Signup = (props) => {
  const [signUpStep, setSignUpStep] = useState(1);
  const [email, setEmail] = useState("");

  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");

  const [name, setName] = useState("");
  const [birth, setBirth] = useState("");
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const CheckZero = (number) => {
    if (number <= 9) {
      return "0" + number;
    } else return number;
  };

  const setCookie = (cname, cvalue, exmins) => {
    var d = new Date();
    d.setMinutes(d.getMinutes() + exmins);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  const handleChange = (e) => {
    setErrMsg("");
    if (e.target.id === "email") {
      setEmail(e.target.value);
    } else if (e.target.id === "code") {
      setCode(e.target.value);
    } else if (e.target.id === "name") {
      setName(e.target.value);
    } else if (e.target.id === "birthday") {
      setBirth(e.target.value);
    } else if (e.target.id === "password") {
      setPassword(e.target.value);
    }
    else if (e.target.id === "Cpassword") {
      setCPassword(e.target.value);
    } else if (e.target.id === "address") {
      setAddress(e.target.value);
    }
  };

  const checkMe = () => {
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setErrMsg("You have entered an invalid email address!");
      return false;
    } else if (password.length < 8) {
      setErrMsg("Password should be atleast 8 character long");
      return false;
    } else if (name.length < 3) {
      setErrMsg("Please enter valid name");
      return false;
    } else if (address.length < 7) {
      setErrMsg("Please enter valid address");
      return false;
    } else if (birth === "") {
      setErrMsg("Please enter your birthdate");
      return false;
    }

    else if (password !== cPassword) {
      setErrMsg("confirm password does not match!");
      return false;
    }

    return true;
  };
  const handleSignUp = () => {
    setErrMsg("");
    if (signUpStep === 1) {
      if (checkMe() == false) {
        return;
      } else {
        let body = {
          userName: email,
          password: password,
          name: name,
          photoUrl: "",
          nickName: "",
          privacyLevel: "Private",
          address: address,
          notificationFrequency: "Never",
          birthDate: birth,
          mobile: "",
          registeredOn: new Date(),
        };

        // console.log(body);
        setLoading(true);
        PostData(
          props.HerokuHost + "/account/register",
          body,
          200,
          "",
          handleRegister,
          onFailed
        );
      }
    }

    // if(signUpStep===2)
    // {
    //   if(email==="")
    //   {
    //     alert("Please enter valid email address")
    //     return;
    //   }
    //   setLoading(true);
    //   PostData(props.HerokuHost+'/account/verify',code,200,"",handleAuthenticate,onFailed);
    // }
  };

  const handleRegister = (response) => {
    // console.log(response);

    if (response.responseCode === "Success") {
      let req = {
        Method: "LOCAL",
        MethodIdentity: email,
        MethodPasscode: password,
        DeviceKey: "MyPC",
        ApplicationKey: "MyBrowser",
        Platform: "DESKTOP",
      };

      setSignUpStep(2);
      GetData(props.HerokuHost + "/account/login", 200, req, checkLogin);
      // alert('Successfully Signup')
      // props.history.push('./')
    } else if (response.responseCode === "Failed") {
      console.log(response.validationErrorsList[0].errorMessage);
      alert(response.validationErrorsList[0].errorMessage.toString());
      setLoading(false);
    } else {
      setLoading(false);
      alert("Error, Please check logs");
      console.log("error response on signup => ", response);
    }
  };

  const checkLogin = (response, token) => {
    // setLoading(false);

    if (response.responseCode === "Success") {
      if (response.response) {

        GetDataUser(
          props.HerokuHost + "/account/challenge/progress",
          200,
          token,
          (res) => {

            let date = new Date();
            let Formatted_date = CheckZero(date.getMonth() + 1) +
              "-" +
              CheckZero(date.getDate()) +
              "-" +
              date.getFullYear();

            let user = response.response.user;
            // console.log(user);
            let Newuser = { ...user, token: token, challenge: res.response.challenge, lastActivityOn: res.response.lastActivityOn };

            props.setUser(Newuser);
            props.setDate(Formatted_date);
            props.setActualDate(date);

            setCookie('token', token, 30);
            setCookie('user', JSON.stringify(Newuser), 30);
            // console.log(res.response.challenge);
            // props.history.push("/dashboard");
          }, onFailed);
      }
    } else if (response.statusText === "Unauthorized") {
      console.log("error on login => ", response);
      // setError(true);
      // setErrorValue("Email or Password wrong.");
    }
  };

  const onFailed = (response) => {
    setLoading(false);
    alert("Error, Please check logs");
    console.log("error response on signup => ", response);
  };

  // const handleAuthenticate = (response) =>
  // {
  //   setLoading(false);
  //   console.log(response);
  //   if(response.responseCode==='Success')
  //   {
  //     setSignUpStep(signUpStep+1);
  //   }
  // }
  return (
    <Grid className="Signup-Main-Wrapper" container sm={12} md={12} lg={12}>
      <div className="Signup-Main-Container">
        <div className="Signup-Logo-Container">
          <img src={FWLogo} alt="Logo" className="Signup-Logo" />
        </div>
        <div className="Signup-Heading-Text">Create Account</div>
        <div className="Signup-Form-Container">
          {/* {signUpStep === 1 ? <Signup1 email={email} code={code} handleChange={handleChange}/> : null} */}
          {signUpStep === 1
            ?
            (
              <Signup2
                email={email}
                handleChange={handleChange}
                password={password}
                name={name}
                address={address}
              />
            ) : null}
          {signUpStep === 2 ? <Signup4 /> : null}
          <Fade bottom when={errMsg}>
            <div className="signup-error"> {errMsg} </div>
          </Fade>
        </div>
        <div className="Signup-Bottom-Btn">
          {/* <Grid item xs={12} md={6} className="Signup-Btn-Grid"> */}
          <Button
            id="Signup-Button"
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSignUp}
            disabled={loading}
          >
            {loading ? (
              <TransverseLoading color="#225b76" size="large" />
            ) : signUpStep === 1 ? (
              "Register"
            ) : signUpStep === 2 ? (
              "Next"
            ) : (
                    "Complete"
                  )}
          </Button>

          <div className="already-account">
            {" "}
            Already have an account?{" "}
            <span
              className="already-account-anchor"
              onClick={() => props.history.push("/")}
            >
              {" "}
              Sign In{" "}
            </span>{" "}
          </div>
          {/* </Grid> */}
        </div>
      </div>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    // userId: state.setUser.userId,
    HerokuHost: state.setHerokuHost,
  };
};

export default connect(mapStateToProps, { setUser, setDate, setActualDate })(Signup);
