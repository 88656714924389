/* eslint-disable no-unused-expressions */
import React, { useState } from "react";
import { connect } from "react-redux";
import { Grid, TextField, Button, makeStyles } from "@material-ui/core";
import { TransverseLoading } from "react-loadingg";
import "../../user/Watch/Watch.scss";
import PostVideo from "../../user/fetchDataPost";

const AddVideo = (props) => {
  const classes = useStyles();
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  

  const PublishVideo = () => {
    const status = formValidation();
    if (!status) {
      return;
    }
    setLoading(true);
    let data = {
      codePrefix: url,
      title: title,
    };
    PostVideo(
      props.HerokuHost + `/organization`,
      data,
      200,
      props.token,
      (data) => {
        data.responseCode === "Success" ? props.goBack() : alert("Some problem occured\ncould not post video");
      },
      onFailed
    );
  };

  const onFailed = (response) => {
    console.log(response);
    setLoading(false);
    alert("Failed response");
  };

  const formValidation = () => {
    if (!title) {
      alert("Oraganization name is empty");
      return false;
    } else if (!url) {
      alert("Prefix is empty");
      return false;
    }
    return true;
  };

  return (
    <div className="W-AddVideo-Wrapper">
      <Grid container direction="row" xs={12} className="W-Title-Container">
        <Grid item xs={12} sm={12} md={3} className="W-Title-Text">
          Name:{" "}
        </Grid>
        <Grid item xs={12} sm={12} md={8} className="W-Title-Input">
          <TextField
            label=""
            variant="outlined"
            placeholder="ORGANIZATION NAME...."
            fullWidth
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutline,
              },
            }}
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container direction="row" xs={12} className="W-Author-Container">
        <Grid item xs={12} sm={12} md={3} className="W-Author-Text">
          Prefix:{" "}
        </Grid>
        <Grid item xs={12} sm={12} md={8} className="W-Author-Input">
          <TextField
            label=""
            variant="outlined"
            placeholder="TFW-[CODES]"
            fullWidth
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutline,
              },
            }}
            value={url}
            onChange={(event) => setUrl(event.target.value)}
          />
        </Grid>
      </Grid>


      <Grid container direction="row" xs={12} className="W-BottomBtns-Container">
        <Button id="W-Back-Button" variant="contained" onClick={() => props.goBack()}>
          Back
        </Button>
        <Button
          id="W-Publish-Button"
          variant="contained"
          onClick={() => PublishVideo()}
          style={{ padding: loading ? "23px 0" : "11px 0" }}
          disabled={loading}>
          {loading ? <TransverseLoading color="#ffffff" size="large" /> : "ADD Organization"}
        </Button>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  notchedOutline: {
    borderWidth: "1.5px",
    borderColor: "#989898",
  },
}));

const mapStateToProps = (state) => {
  return {
    userId: state.setUser.userId,
    userName: state.setUser.userName,
    HerokuHost: state.setHerokuHost,
    token: state.setUser.token,
    date: state.setDate.date,
  };
};

export default connect(mapStateToProps, null)(AddVideo);
