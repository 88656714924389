import React from 'react'
import Skeleton from "react-loading-skeleton";

const CommentsSkeleton = () => {
    return (
        <div className="Main-Comment-skeleton">
        <div className="Comment-skeleton-circle-div">
          <Skeleton
            className="Comment-skeleton-circle"
            circle={true}
            height={80}
            width={80}
          />
        </div>
        <div className="Comment-skeleton-lines">
          <Skeleton className="Comment-skeleton-line" />
          <Skeleton className="Comment-skeleton-line" />
          <Skeleton className="Comment-skeleton-line" />
        </div>
      </div>
    )
}

export default CommentsSkeleton
