import React from "react";
import { Dialog, makeStyles } from "@material-ui/core";
import ReactPlayer from "react-player/lazy";
import "./Watch.scss";

const useStyles = makeStyles({
  scrollPaperss: {
    // maxHeight: null,
  },
});

const VideoDialoge = (props) => {
  const classes = useStyles();
  const [lightMode, setlightMode] = React.useState(false);

  const handleClose = () => {
    props.Close();
  };

  return (
    <Dialog
      open={props.Open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ scrollPaper: classes.scrollPaperss }}
    >
      <ReactPlayer
        url={props.link}
        controls
        width="70vw"
        height="39.35vw"
        className="react-player"
        playing={true}
        light={lightMode}
        onEnded={()=>setlightMode(true)}
      />
    </Dialog>
  );
};

export default VideoDialoge;
