import React from "react";
import { Paper, Button } from "@material-ui/core";
import { connect } from "react-redux";

import "../../user/GoDeeper/GoDeeper.scss";

const ReadArticle = (props) => {
  return (
    <div className="GD-Wrapper">
      <div className="GD-Admin-Controls-div">
        <div className="GD-Edit-Delete-Add-Options">
          {props.content ? (
            <div className="GD-Edit-Delete-Article-div">
              <p
                className="GD-Edit-Text"
                onClick={() => props.handleEdit(true)}
              >
                Edit Article
              </p>
              <p style={{ marginLeft: "8px", marginRight: "8px" }}>|</p>
              <p
                className="GD-Edit-Text"
                onClick={() => props.hanldeDelete(props.id)}
              >
                Delete Article
              </p>
            </div>
          ) : null}

          <Button
            id="GD-Edit-Button"
            variant="contained"
            onClick={() => props.handleAdd(true)}
          >
            Add Article
          </Button>
        </div>
      </div>
      {props.content ? (
        <div className="GD-RightContainer">
          {props.image ? (
            <Paper
              variant="elevation"
              elevation={2}
              style={{ backgroundImage: `url(${props.image})` }}
              className="GD-ImageContainer"
            ></Paper>
          ) : null}
          <div className="GD-Title">{props.title}</div>
          {props.author ? (
            <div className="GD-Author">{props.author}</div>
          ) : null}
          <div className="GD-Content">
            {props.content
              ? props.content.split("\n").map((item, i) => {
                  return <p key={i}>{item}</p>;
                })
              : null}
          </div>
        </div>
      ) : (
        <div className="GD-NoArticle">
          No Article for{" "}
          <span className="GD-NoArticle-Day"> {props.date}</span>.
        </div>
      )}
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    date: state.setDate.date,
  };
};

export default connect(mapStateToProps, null)(ReadArticle);
