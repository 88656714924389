/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import "./dateComp.css";
import { IconButton } from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setAhead, setDate, setActualDate } from "../../store/actions";

class DateComp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // dateAddition = () => {
  //   this.props.setAhead(this.props.ahead + 1);
  //   this.updateDate(this.props.ahead + 1);
  // };

  // dateMinus = () => {
  //   this.props.setAhead(this.props.ahead - 1);
  //   this.updateDate(this.props.ahead - 1);
  // };

  dateMinus = () => {
    // console.log(this.props.actualDate);
    var d = new Date(this.props.actualDate);
    // console.log("oldDate ", d.toDateString());
    d.setDate(d.getDate() - 1);
    // console.log("newDate ", d.toDateString());

    let formatted_date = this.dateFormatter(d);
    // console.log("formatted_date ", d.toDateString());

    this.props.setDate(formatted_date);
    this.props.setActualDate(d);
  };

  dateAddition = () => {
    var d = new Date(this.props.actualDate);
    // console.log("oldDate ", d.toDateString());

    d.setDate(d.getDate() + 1);
    // console.log("newDate ", d.toDateString());

    let formatted_date = this.dateFormatter(d);
    // console.log("formatted_date ", d.toDateString());

    this.props.setDate(formatted_date);
    this.props.setActualDate(d);
  };

  // updateDate = (ahead) => {
  //   let date = new Date();
  //   date.setDate(date.getDate() + ahead);
  //   let Formatted_date =
  //     date.getFullYear() +
  //     "-" +
  //     this.CheckZero(date.getMonth() + 1) +
  //     "-" +
  //     this.CheckZero(date.getDate());
  //   this.props.setDate(Formatted_date);
  // };

  CheckZero = (number) => {
    if (number <= 9) {
      return "0" + number;
    } else return number;
  };

  handleCalendarOpen = () => {
    let x = document.getElementsByClassName("date-comp-icon-button");
    x[0].style.visibility = "hidden";
    // it will compare todays date to check if it will change style of that arrow or not
    !this.compareDates(this.props.date) ? (x[1].style.visibility = "hidden") : null;
  };

  handleCalendarClose = () => {
    let x = document.getElementsByClassName("date-comp-icon-button");
    x[0].style.visibility = "unset";
    // it will compare todays date to check if it will change style of that arrow or not
    !this.compareDates(this.props.date) ? (x[1].style.visibility = "unset") : null;
  };

  handleCalendarChange = (date) => {
    let formatted_date = this.dateFormatter(date);
    this.props.setDate(formatted_date);
    this.props.setActualDate(date);
  };

  compareDates = (reduxDate) => {
    const currentDate = this.dateFormatter(new Date());
    return reduxDate === currentDate;
  };

  dateFormatter = (date) => {
    let Formatted_date =
      this.CheckZero(date.getMonth() + 1) + "-" + this.CheckZero(date.getDate())  + "-" + date.getFullYear();
    // this.props.setDate(Formatted_date);
    // let formatted_date = `${splittedDate[3]}-${getMonthNumber(
    //   splittedDate[1]
    // )}-${splittedDate[2]}`;
    return Formatted_date;
  };

  render() {
    return (
      <div className="date-comp-wrapper">
        <IconButton
          className="date-comp-icon-button"
          aria-label="previous-date"
          onClick={this.dateMinus}
          style={{ marginRight: 20 }}>
          {" "}
          <NavigateBeforeIcon className="date-comp-icon" />{" "}
        </IconButton>
        <DatePicker
          onCalendarOpen={this.handleCalendarOpen}
          onCalendarClose={this.handleCalendarClose}
          customInput={<CustomInput CurDate={this.props.date} />}
          dateFormat="mm/dd/yyyy"
          onChange={this.handleCalendarChange}
          // maxDate={new Date()}
        />
        {!this.compareDates(this.props.date) && (
          <IconButton
            className="date-comp-icon-button"
            aria-label="next-date"
            onClick={this.dateAddition}
            style={{ marginLeft: 20 }}>
            {" "}
            <NavigateNextIcon className="date-comp-icon" />{" "}
          </IconButton>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // userId: state.setUser.userId,
    date: state.setDate.date,
    actualDate: state.setDate.actualDate,
    ahead: state.setDate.ahead,
  };
};
const CustomInput = ({ value, onClick, CurDate }) => {
  return (
    <div onClick={onClick} className="custome-date">
      {month(parseInt(CurDate.split("-")[0]))} {CurDate.split("-")[1]}
    </div>
  );
};

const getMonthNumber = (month) => {
  let num;
  month === "Jan"
    ? (num = "01")
    : month === "Feb"
    ? (num = "02")
    : month === "Mar"
    ? (num = "03")
    : month === "Apr"
    ? (num = "04")
    : month === "May"
    ? (num = "05")
    : month === "Jun"
    ? (num = "06")
    : month === "Jul"
    ? (num = "07")
    : month === "Aug"
    ? (num = "08")
    : month === "Sep"
    ? (num = "09")
    : month === "Oct"
    ? (num = "10")
    : month === "Nov"
    ? (num = "11")
    : month === "Dec"
    ? (num = "12")
    : null;
  return num;
};
const month = (number) => {
  var month = [];
  month[1] = "January";
  month[2] = "February";
  month[3] = "March";
  month[4] = "April";
  month[5] = "May";
  month[6] = "June";
  month[7] = "July";
  month[8] = "August";
  month[9] = "September";
  month[10] = "October";
  month[11] = "November";
  month[12] = "December";
  return month[number];
};

export default React.memo(connect(mapStateToProps, { setDate, setAhead, setActualDate })(DateComp));
