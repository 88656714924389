import React, { Component } from 'react';
import NewGroupModal from './newGroupModal';
import MailIcon from "../../assets/mail-icon.png";
import GroupRequest from "./GroupRequest";
import GroupNamesComp from "./GroupNames";
import GetData from '../fetchDataGet';
import { connect } from "react-redux";
import { Button, Grid } from '@material-ui/core';
import SwipeableViews from "react-swipeable-views";
import { LoaderDots } from '@thumbtack/thumbprint-react';
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";

import Logo from '../../assets/TheFamilyWinsLogo.png'


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}>
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };





class GroupsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = { groupsLoader:true,groups:[],newGroupModal:false,GN_swipeIndex: 0,invitations:[],invitationLoader:false}
    }

    componentDidMount()
    {
        this.getInvitations();
        this.getGroups();
    }

    componentDidUpdate()
    {
        if(this.props.ExitGroupId!=null)
        {
            console.log(this.props.ExitGroupId);
            let newGroups = this.state.groups.filter( group => group.groupId.toString() !== this.props.ExitGroupId.toString())
            this.setState({groups:newGroups})
            this.props.setExitGroup();
            this.props.setGroupsLength(newGroups.length)
        }

        if(this.props.newMessage!=null)
        {
            let groups=this.state.groups;
            let groupIndex = groups.findIndex(group => group.groupId.toString()===this.props.newMessage.groupId.toString())
            groups[groupIndex].lastMessage=this.props.newMessage.text;
            groups[groupIndex].hasChange=this.props.newMessage.hasChange;


            this.setState(groups)
            this.props.setNewMessage();
        }
    }

    openNewGroupModal = () => {
        this.setState({ newGroupModal: true });
      };
    
    closeNewGroupModal = (newGroup) => {
        if(newGroup===true)
        {
            this.getGroups();
        }
        this.setState({ newGroupModal: false });
    };

    handleRemoveGroupRequest = (invitationId) =>
    {
        let invitations = this.state.invitations;
        invitations = invitations.filter((invitation) => invitation.invitationId.toString()!==invitationId.toString());
        this.setState({invitations})
    }
    
    handleBackFromRequest = () =>
    {
        this.GN_Swipe(0)
        this.getGroups();
    }

    getGroups = () => {
        this.setState({ groupsLoader: true });
        this.props.setGroupsLength(0);
        this.props.setGroupLoader(true);
        GetData(this.props.HerokuHost + `/account/group`, 200, this.props.token, this.setGroups, this.onFailed);
    };
    
    getInvitations = () => {
        // this.setState({backdrop:true,backdrop2:true})
        this.setState({invitations:[],invitationLoader:true})
        GetData(
            this.props.HerokuHost + `/account/group/invitation`,
            200,
            this.props.token,
            this.setInvitations,
            this.onFailed
        );
    };

    setInvitations = (response) => {
        // console.log("response => ", response);
        if (response.response) {
            let invitations = response.response.invitations;
            this.setState({ invitations });
        } else if (response.validationErrorsList) {
            console.log("Error on study group => ", response.validationErrorsList[0].errorMessage);
        }
        this.setState({ invitationLoader: false });
    };

    setGroups = (response) => {
        // console.log("response => ", response);
        if (response.response) {
            let groups = response.response.groups;
            this.setState({ groups, groupsLoader: false });
            this.props.setGroupsLength(groups.length);

        } else if (response.validationErrorsList) {
            console.log("Error on study group => ", response.validationErrorsList[0].errorMessage);
        }
        // let global=this;
        this.setState({groupsLoader:false})
        this.props.setGroupLoader(false);

    } 

    onFailed = (response) => {
        console.log(response);
        alert("Failed response");
    };
    
    GN_Swipe = (value) => {
        this.setState({ GN_swipeIndex: value });
    };

    notificationPage = () => 
    {
        this.getInvitations();
        this.GN_Swipe(1)
    }

   


    render() { 
        return ( 
            <Grid item xs={12} sm={12} md={5} className="SG-GroupNames-Container">
                <div className="SG-GroupNames-headerRow">
                <Button id="SG-createGroupBtn" variant="contained" onClick={this.openNewGroupModal}>
                    Create a Group
                </Button>
                <div className="SG-MailIcon-div">
                    {this.state.invitations.length > 0 ?<div className="SG-noti-dot"></div> :null}
                    <img
                    src={MailIcon}
                    style={{ width: "40px" }}
                    alt="mail icon"
                    className="SG-MailIcon"
                    onClick={this.notificationPage}
                    />
                </div>
                </div>
                <SwipeableViews index={this.state.GN_swipeIndex}>

                <TabPanel
                    value={this.state.GN_swipeIndex}
                    index={0}
                    dir={"ltr"}
                >
                    {this.state.groupsLoader === true ? (
                        <div className="SG-Groups-loader">
                            {/* <TransverseLoading color="#225b76" size="large" /> */}
                            <div className="logo-wrapper-loader1">
                                <div className="logo-loader1">
                                    <img src={Logo} width="150px"/>
                                </div>
                                <div className="text-loader1">
                                    Loading Groups <LoaderDots size="small" theme="muted" />
                                </div>
                            </div>
                        </div>
                    ) : this.state.groups.length > 0 ? (
                        <div className="SG-GroupNames-list">
                        {this.state.groups.map((group, id) => (
                            <GroupNamesComp
                            key={id}
                            group={group}
                            setActiveGroup ={(group)=>this.props.setActiveGroup(group)}
                            activeGroup={this.props.activeGroup}
                            />
                        ))}
                        </div>
                    ) : (
                        <div className="SG-NoGroup-text">
                            {" "}
                            No Groups Fellas,{" "}
                            <span className="SG-NoGroup-anchor" onClick={this.openNewGroupModal}>
                                Please create one.
                            </span>
                        </div>
                    )}
                </TabPanel>
                
                <TabPanel
                    value={this.state.GN_swipeIndex}
                    index={1}
                    dir={"ltr"}
                >
                    <div style={{display:"unset"}}>
                        <div className="GN-HeaderRow">
                            <div className="GN-Noti-Text">Notification</div>
                            <p onClick={this.handleBackFromRequest} className="SG-GN-goBack">
                                {"<"} Back
                            </p>
                        </div>
                    {this.state.invitationLoader === true ? (
                            <div className="SG-Groups-loader">
                                {/* <TransverseLoading color="#225b76" size="large" /> */}
                                <div className="logo-wrapper-loader1">
                                    <div className="logo-loader1">
                                        <img src={Logo} width="150px"/>
                                    </div>
                                    <div className="text-loader1">
                                        Loading invitations <LoaderDots size="small" theme="muted" />
                                    </div>
                                </div>
                            </div>
                        ) : 
                        this.state.invitations.length > 0 ? (
                            
                              
                            this.state.invitations.map((invitation, id) => (
                            <GroupRequest
                                invitation={invitation}
                                handleRemoveGroupRequest = {(id)=>this.handleRemoveGroupRequest(id)}
                            />
                            ))
                           
                        )
                    : (
                        <div className="SG-NoGroup-text">
                            {" "}
                            No New Group Invitations{" "}
                            {/* <span className="SG-NoGroup-anchor" onClick={this.openNewGroupModal}>
                                Please create one.
                            </span> */}
                        </div>
                    )}
                    </div>
                </TabPanel>

                </SwipeableViews>

                <NewGroupModal
                    open={this.state.newGroupModal}
                    onClose={(newGroup)=>this.closeNewGroupModal(newGroup)}
                />
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    return {
      HerokuHost: state.setHerokuHost,
      token: state.setUser.token,
    };
  };
  
export default connect(mapStateToProps, {})(GroupsContainer);
  
 
