import React, { Component } from "react";
import {
  Button,
  Grid,
  withStyles,
  Avatar,
  GridList,
  GridListTile
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import CheckMark from "../../assets/check-mark.png";
import PopperImage from "../../assets/popper.png";
import DeleteData from "../fetchDataDelete";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import { connect } from "react-redux";
import { compose } from "redux";
import PostData from "../fetchDataPost";
import GetData from "../fetchDataGet";
import PutData from "../fetchDataPut";



// import "../../user/EditProfile/EditProfile.scss";

const groups = [

    {
        name:'This is Dummy Data'
    },
    {
        name:'Group Name 2 (Leader)'
    },
    {
        name:'Group Name 3'
    },
    {
        name:'Group Name 4'
    },
]
// const trackList = [
//     {
//       Date: "May 1, 2020",
//       Message: "You've completed Day 19 of 21",
//       Type: "Completed",
//     },
//     {
//       Date: "May 2, 2020",
//       Message: "You've completed Day 20 of 21",
//       Type: "Completed",
//     },
//     {
//       Date: "May 3, 2020",
//       Message: "You've completed Day 21 of 21",
//       Type: "Completed",
//     },
//     {
//       Date: "May 3, 2020",
//       Message1: "YOU FINISHED YOUR 21 CONSECUTIVE DAYS,",
//       Message2: "YOU'RE NOW ONTO THE 90 DAYS CHALLENGE.",
//       Type: "Congratulations",
//     },
//     {
//       Date: "May 4, 2020",
//       Message: "You've completed Day 1 of 90",
//       Type: "Completed",
//     },
//     {
//       Date: "May 5, 2020",
//       Message: "You've completed Day 2 of 90",
//       Type: "Completed",
//     },
//     {
//       Date: "May 6, 2020",
//       Message: "You've completed Day 3 of 90",
//       Type: "Completed",
//     },
    
//   ];

class UserSpecific extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();

    this.state = {
      loadedImage: "",
      title: "",
      codePrefix: "",
      disabled: true,
      changesShow: false,
      backdrop:false,
    };
  }

  componentWillMount()
  {

    // GetData(this.props.HerokuHost+`/account/${this.props.user.userId}/progress`,200,this.props.token,this.setProgress,this.onFailed);
    
    this.setState({disableSave:true,  
      title: this.props.user.title,
      codePrefix: this.props.user.codePrefix,
      

      oldTitle: this.props.user.title,
      oldCodePrefix: this.props.user.codePrefix,
     
    })
  }


  handleChange = (e) =>
  {
    this.setState({
      [e.target.id]:e.target.value, disabled: false,
      changesShow: false,
    })
  }

  
  onFailed = (response) => {
    console.log(response);
    this.setState({ backdrop: false,changesShow: "Error" });
  };

  updateProfile = () => {
    this.setState({ backdrop: true });
    const UpdatedUser = {
      title: this.state.title,
      codePrefix: this.state.codePrefix,
    };
    PutData(
      this.props.HerokuHost + `/organization/${this.props.user.id}`,
      UpdatedUser,
      200,
      this.props.token,
      (data) => {
        this.setState({
        disabled: true,
        backdrop: false,
        changesShow: "Success",
        oldTitle: this.state.title,
        oldCodePrefix: this.state.codePrefix,
       });
      },
      this.onFailed
    );
  };

  
  
  

  
  handleDeleteUser = () =>
  {
    // DeleteData(this.props.HerokuHost + `/account/${this.props.user.userId}`,
    // 200,
    // this.props.token,
    // (data) => {
      
    //   this.props.handleToggleView();
    // },
    // this.onFailed)
  }

  month(number)
  {
    var month = new Array();
    month[1] = "January";
    month[2] = "February";
    month[3] = "March";
    month[4] = "April";
    month[5] = "May";
    month[6] = "June";
    month[7] = "July";
    month[8] = "August";
    month[9] = "September";
    month[10] = "October";
    month[11] = "November";
    month[12] = "December";
    return month[number];
  }

  render() {
    const { classes } = this.props;
    return (
      <div className="EP-Wrapper">
        <Grid container>

          <Grid item lg={7} xl={7} sm={7} md={7}>

            <GridList cols={7}>

              <GridListTile cols={5} style={{height:'100%'}}>
                <div className="header-avatar-div">
                  <Avatar alt="Image" src={this.state.photoUrl || null} className="header-avatar" max-width="60px" />
                </div>
                <div className="header-user-name">
                  Organization Title : {this.state.oldTitle} <br />{" "}
                </div>
              </GridListTile>
           
              {/* <GridListTile cols={2} style={{height:'100%'}}>   
                <div className="user-specific-change-profile-picture">
                  <div style={{marginBottom:'10px'}}>Change Profile Picture</div>
                  <input
                  type="file"
                  id="user-specific-data-change-profile-button"
                  onChange={this.handleImageChnage}
                  style={{display:'none'}}
                  ref={this.myRef}
                  />
              
                  <Button  variant="contained" onClick={()=>this.myRef.current.click()}>
                    Upload
                  </Button>
                </div>
              </GridListTile> */}
              <GridListTile cols={7} style={{height:'100%'}} className="EP-FormContainer">
                <InputElement
                  id="title"
                  label="title"
                  value={this.state.title}
                  type="text"
                  onChange={this.handleChange}
                />
                <InputElement
                id="codePrefix"
                label="Code Prefix"
                value={this.state.codePrefix}
                type="text"
                onChange={this.handleChange}
                />
                
              </GridListTile>
            </GridList>
          </Grid>
                      
          {/* <Grid  item lg={5} md={5} xl={5}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="groups-participating-div">
                <div className="track-my-progress-header">Codes Generated: </div>
                
              </div>
              

              
            
            </Grid>

          </Grid>   */}

        </Grid> 

        <div style={{display:'inline-block'}}>
          <div style={{display:'inline-block'}}>
            <Button
              id="EP-SaveButton"
              variant="contained"
              onClick={this.updateProfile}
              disabled={this.state.disabled}
              style={{ background: this.state.disabled ? "rgb(228, 88, 1,0.5)" : "rgb(228, 88, 1,1)" }}

              >
              Save Changes
            </Button>
          </div>

          <div style={{display:'inline-block'}} className="user-specific-delete-button-div">
            <Button
              id="user-specific-delete-button"
              onClick={this.handleDeleteUser}
              disabled
            >
              Delete Organization
            </Button>
          </div>
         
        </div>

        {this.state.changesShow === "Success" ? (
            <div className={classes.changeSaved}>
              Changes Saved Successfully.
            </div>
          ) : null}
          {this.state.changesShow === "Error" ? (
            <div className={classes.changeSaved}>
              Error Changing Profile Data! Please try again later.
            </div>
          ) : null}
           {this.state.changesShow === "ImageSuccess" ? (
            <div className={classes.changeSaved}>
              Image Uploaded Successfully.
            </div>
          ) : null}

          {this.state.changesShow === "ImageError" ? (
            <div className={classes.changeSaved}>
              Error Changing Profile Picture! Please try again later.
            </div>
          ) : null}

          <Backdrop className={classes.backdrop} open={this.state.backdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>


      </div>
    );
  }
}

const style = (theme) => ({
  radioRoot: {
    color: "#989898",
  },
  radioChecked: {
    color: "#989898 !important",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  changeSaved: {
    display:'inline-block',
    color: "#E25601",
    marginLeft:'10px',
    // font-size
  },
});

export const InputElement = (props) => {
  return (
    <div className="Ep-InputContainer">
      <input
        id={props.id}
        value={props.value}
        className="EP-Input"
        type={props.type}
        onChange={props.onChange}
      />
      <div className="EP-InputLabel">{props.label}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    HerokuHost: state.setHerokuHost,
    date: state.setDate.date,
    token: state.setUser.token,
  };
};

export default compose(
  connect(mapStateToProps, {}),
  withStyles(style)
)(UserSpecific);

