import { combineReducers } from "redux";
import { ActionTypes } from "./actions";

let userState = {
  // memberType: "",
  // userId: null,
  // name: "",
  // BirthDate: "",
  // photoUrl: "",
  // progress: null,
  // userName: "",
  // token: "",
  // address: "",
  // notificationFrequency: "",
  // privacyLevel: "",
  // lastLogin: "",
  // registeredOn: "",
  // nickName: "",
  // password: "",
};

let date = {
  date: "2020-08-14",
  ahead: 0,
  actualDate: "",
};

let ActualHerokuHost="https://tfw-staging-de01c1f6e8ed.herokuapp.com"
let ProductionHost="https://family-win-api.herokuapp.com"
let AmazonHost = "https://i4arwc2za0.execute-api.us-east-2.amazonaws.com"

let HerokuHost = AmazonHost;

export const setHerokuHost = (state = HerokuHost) => {
  return state;
};

export const setDate = (state = date, action) => {
  switch (action.type) {
    case ActionTypes.SET_DATE:
      state = Object.assign({}, state, { ...state, date: action.payload.date });
      return state;
    case ActionTypes.SET_AHEAD:
      state = Object.assign({}, state, {
        ...state,
        ahead: action.payload.ahead,
      });
      return state;

    case ActionTypes.SET_ACTUAL_DATE:
      state = Object.assign({}, state, {
        ...state,
        actualDate: action.payload.date,
      });
      return state;
    default:
      return state;
  }
};

export const setUser = (state = userState, action) => {
  switch (action.type) {
    case ActionTypes.UPATE_USER:
      state = Object.assign({}, state, {
        ...action.payload,
      });
      return state;

    case ActionTypes.SET_COMPLETED:
      state = Object.assign({}, state, { ...action.payload });
      return state;

    case ActionTypes.SET_CHALLENGE:
    state = Object.assign({}, state, { ...action.payload });
    return state;


    case ActionTypes.LOGOUT:
      state = Object.assign({}, { memberType: null });
      return state;

    default:
      return state;
  }
};

export default combineReducers({ setUser, setHerokuHost, setDate });
