import React, { Component } from 'react';
import './NoData.css';

class NoData extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
        <div className="no-data-wrapper" style={{marginLeft:this.props.marginLeft?this.props.marginLeft:'',textAlign:this.props.textAlign?this.props.textAlign:'',minHeight:this.props.minHeight?this.props.minHeight:'', background:this.props.background?this.props.background:'', height:this.props.height?this.props.height:''}}>
            {
                this.props.innerDiv?
                    <div className="no-data-inner-div">
                        {this.props.description }
                    </div>
            :   this.props.description 
            } 
        </div>  );
    }
}
 
export default NoData;