import React, { Component } from "react";
import { connect } from "react-redux";
import "../../user/Watch/Watch.scss";
import VideoCard from "./videocard";
import { TransverseLoading } from "react-loadingg";
import VideoDialoge from "../../user/Watch/dialoge.jsx";
import FetchVideos from "../../user/fetchDataGet";

class Watch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogeOpen: false,
      videos: [],
      dialogeLink: null,
      loading: false,
    };
  }

  componentDidMount() {
    this.GetVideos();
  }

  componentDidUpdate(oldprops) {
    if (oldprops.date !== this.props.date) {
      this.GetVideos(this.props.date);
    }
  }

  GetVideos = async () => {
    this.setState({ loading: true });
    await FetchVideos(
      this.props.HerokuHost + `/contents/video/${this.props.date}`,
      200,
      this.props.token,
      (data) => {
        console.log(data);
        this.setState({ videos: data.response.contents, loading: false });
      }
    );
  };

  handleDialoge = (x, link) => {
    x === "o"
      ? this.setState({ dialogeOpen: true, dialogeLink: link })
      : this.setState({ dialogeOpen: false, dialogeLink: null });
  };

  handleDelete = (id) =>
  {
    let oldVideos=this.state.videos;
    let filteredVideos = oldVideos.filter( video => video.id.toString() !== id.toString())
    this.setState({videos:filteredVideos});
  }

  renderVideos = () => {
    return this.state.videos.map((video, index) => {
      return (
        <VideoCard
          key={video.id}
          id={video.id}
          openDialoge={() => this.handleDialoge("o", video.url)}
          title={video.title}
          desc={video.description}
          link={video.url}
          handleDelete={(id)=>this.handleDelete(id)}
        />
      );
    });
  };

  render() {
    return (
      <div style={{ minHeight: "calc(100vh - 370px)" }}>
        {this.state.loading ? (
          <TransverseLoading color="#225b76" size="large" />
        ) : (
          <div className="W-Videos-Wrapper">
            {this.state.videos.length > 0 ? (
              this.renderVideos()
            ) : (
              <div className="W-NoVideos">No Videos for <span className="GD-NoVideos-Day"> {this.props.date}</span>. </div>
            )}
            <VideoDialoge
              Open={this.state.dialogeOpen}
              Close={() => this.handleDialoge("c")}
              link={this.state.dialogeLink}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.setUser.userId,
    userName: state.setUser.userName,
    HerokuHost: state.setHerokuHost,
    token: state.setUser.token,
    date: state.setDate.date,
  };
};

export default connect(mapStateToProps, null)(Watch);
