import React, { Component } from "react";
import { Paper,withStyles } from "@material-ui/core";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { connect } from "react-redux";
import { TransverseLoading } from "react-loadingg";
import GetArticle from "../fetchDataGet";
import "./Favourites.scss";
import Slide from "react-reveal/Slide";
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import GoDeeper from "../GoDeeper/GoDeeper";
import Listen from "../listen/listen";
import Watch from "../Watch/Watch";
import Worship from "../Worship/Worship";


const TabsDetail = [
    {label:"Go Deeper",id:0},
    {label:"Listen",id:1},
    {label:"Watch",id:2},
    {label:"Worship",id:3}
]

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  const StyledTabs = withStyles({
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      height:'4px',
      '& > span': {
        width: '100%',
        backgroundColor: '#d7d5d5',
      },
    },
  })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);


  const StyledTab1 = withStyles((theme) => ({
    root: {
      textTransform: 'none',
      color: 'rgb(34, 91, 118)',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      '&:focus': {
        opacity: 1,
        background:"green"
      },
      '&.Mui-selected':{
        color: '#fff',
        background:"rgb(34, 91, 118)"

    }
    },
  }))  ((props) => <Tab {...props} />);

  const StyledTab2 = withStyles((theme) => ({
    root: {
      textTransform: 'none',
      color: 'rgb(226, 86, 1)',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      '&:focus': {
        opacity: 1,
      },
      '&.Mui-selected':{
        color: '#fff',
        background:"rgb(226, 86, 1)"
    }
    },
  }))  ((props) => <Tab {...props} />);

class Favourites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      currentTab:0
    };
  }

  componentDidMount() {
    // this.getArticle(this.props.date);
  }


  handleChangeTab = (event, newValue) => {
    this.setState({currentTab:newValue})
  }

  handleChangeTabIndex = (index) => {
    this.setState({currentTab:index})
  }

  render() {
    const { currentTab, loading, content } = this.state
    return loading ? (
      <div className="journal-loader-wrraper">
        <TransverseLoading color="#225b76" size="large" />
      </div>
    ) : (
      <Slide left>
        <>
          <div className="FV-Wrapper">
              <div className="FV-RightContainer">
                <Paper square>
                    <StyledTabs
                        value={currentTab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.handleChangeTab}
                        aria-label="favourites tabs"
                        variant="scrollable"
                    >
                        {
                            TabsDetail.map((t,i)=>
                            i%2==0?
                            <StyledTab1 label={t.label}  />
                            :
                            <StyledTab2 label={t.label}  />
                            )
                        }
                       
                    </StyledTabs>
                </Paper>
                <SwipeableViews
                    axis={'x'}
                    index={currentTab}
                    onChangeIndex={this.handleChangeIndex}
                >
                    <TabPanel value={currentTab} index={0} dir={'ltr'}>
                        <GoDeeper isFavourite={true} />
                    </TabPanel>
                    <TabPanel value={currentTab} index={1} dir={'ltr'}>
                        <Listen isFavourite={true}/>
                    </TabPanel>
                    <TabPanel value={currentTab} index={2} dir={'ltr'}>
                        <Watch isFavourite={true}/>
                    </TabPanel>
                    <TabPanel value={currentTab} index={3} dir={'ltr'}>
                        <Worship isFavourite={true}/>
                    </TabPanel>
                </SwipeableViews>
              </div>
            
          </div>
        </>
      </Slide>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.setUser.userId,
    userName: state.setUser.userName,
    HerokuHost: state.setHerokuHost,
    token: state.setUser.token,
    date: state.setDate.date,
  };
};

export default connect(mapStateToProps, null)(Favourites);
