import React from 'react';
import { Button } from '@material-ui/core';
import DeleteData from '../fetchDataDelete';
import { connect } from "react-redux";



 
const GroupNames = (props) => {

  const [loading, setLoading] = React.useState(false);

  const active = props.activeGroup?props.activeGroup.groupId.toString()===props.group.groupId.toString()?true:false:false
  const RemoveGroup =  (e) =>
  {
    e.stopPropagation();
    setLoading(true)
    DeleteData(props.HerokuHost+`/account/group/${props.group.groupId}`,200,props.token,groupDeleted,onFailed)
  }

  const groupDeleted = (response) =>
  {
    if (response.responseCode === "Success")
    {
      props.ExitGroup(props.group.groupId)
    }
    else
    {
      console.log(response)
      alert('Something went wrong')
    }

    setLoading(false)
  };

  const onFailed = (response) => {
    console.log(response);
    alert("Failed response");
  };

  return (
    <div className="SG-GN-Group-Container" style={{opacity:loading===true?'0.5':'1',background:active?'rgb(218, 217, 217)':''}} onClick={()=>{props.group.hasChange=false; props.setActiveGroup(props.group)}}>
      {props.group.hasChange===true?
        <div className="SG-GN-noti-div">
          <div className="SG-GN-noti-dot"></div> 
        </div>
      :null}
      <div className="SG-GN-details-div">
        <div className="SG-GN-name">{props.group.title} <div style={{display:'inline-block',position:'absolute',right:'0px'}}> <Button onClick={RemoveGroup} style={{textTransform:'none',color:'#e25601'}}> Remove Group </Button> </div></div>
        <div className="SG-GN-members">{props.group.totalMembers} Members</div>
        <div className="SG-GN-msg">{props.group.lastMessage}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    HerokuHost: state.setHerokuHost,
    token: state.setUser.token,
  };
};

export default connect(mapStateToProps, {})(GroupNames);

