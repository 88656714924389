import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import EndProgressModal from "./EndProgressModal";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import CheckMark from "../../assets/check-mark.png";
import PopperImage from "../../assets/popper.png";
import DefaultDP from "../../assets/defaultDP.png";
import Shield21 from "../../assets/shieldShape21hollow.png";
import Shield90 from "../../assets/shieldShape90hollow.png";

import { connect } from "react-redux";
import { compose } from "redux";

import "./trackProgress.css";
import GetData from "../fetchDataGet";
import Slide from "react-reveal/Slide";
import PostData from "../fetchDataPost";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import { setChallenge,setProgress,setUser } from "../../store/actions";

import NoData from "../../containers/NoData/NoData";
import PutData from "../fetchDataPut";

const styles = (theme) => ({
  tracklistRoot: {
    width: "100%",
    maxWidth: 600,
    backgroundColor: theme.palette.background.paper,
  },
  largeAvatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
});

// const trackList = [
//   {
//     Date: "May 1, 2020",
//     Message: "You've completed Day 19 of 21",
//     Type: "Completed",
//   },
//   {
//     Date: "May 2, 2020",
//     Message: "You've completed Day 20 of 21",
//     Type: "Completed",
//   },
//   {
//     Date: "May 3, 2020",
//     Message: "You've completed Day 21 of 21",
//     Type: "Completed",
//   },
//   {
//     Date: "May 3, 2020",
//     Message1: "YOU FINISHED YOUR 21 CONSECUTIVE DAYS,",
//     Message2: "YOU'RE NOW ONTO THE 90 DAYS CHALLENGE.",
//     Type: "Congratulations",
//   },
//   {
//     Date: "May 4, 2020",
//     Message: "You've completed Day 1 of 90",
//     Type: "Completed",
//   },
//   {
//     Date: "May 5, 2020",
//     Message: "You've completed Day 2 of 90",
//     Type: "Completed",
//   },
//   {
//     Date: "May 6, 2020",
//     Message: "You've completed Day 3 of 90",
//     Type: "Completed",
//   },
//   {
//     Date: "May 7, 2020",
//     Message: "You've completed Day 4 of 90",
//     Type: "Completed",
//   },
//   {
//     Date: "May 8, 2020",
//     Message: "You've completed Day 5 of 90",
//     Type: "Completed",
//   },
// ];

// const compProgress = [
//   {
//     id: "0",
//     name: "David Dziardziel",
//     thumbnail: "",
//     progress: "Completed 8 of 21 days.",
//   },
//   {
//     id: "1",
//     name: "Nolan Martin",
//     thumbnail: "",
//     progress: "Completed 1 of 21 days.",
//   },
//   {
//     id: "2",
//     name: "Diana Dziardziel",
//     thumbnail: "",
//     progress: "Completed 10 of 21 days.",
//   },
//   {
//     id: "3",
//     name: "Colby Elizabeth",
//     thumbnail: "",
//     progress: "Completed 17 of 21 days.",
//   },
//   {
//     id: "4",
//     name: "Chip Gamber",
//     thumbnail: "",
//     progress: "Completed 2 of 21 days.",
//   },
//   {
//     id: "5",
//     name: "Nick Renzulli",
//     thumbnail: "",
//     progress: "Completed 19 of 21 days.",
//   },
// ];

class TrackProgress extends Component {
  constructor(props) {
    super(props);
    this.state = { trackList: [], companionsList: [], endProgressModal: false,backdrop:false };
  }

  componentDidMount() {

    if(this.props.challenge==null)
    {
      this.getProgress();
      this.getCompanionsData();
    }

    else if(this.props.challenge.toString()==='null')
    {
      this.getProgress();
      this.getCompanionsData();
    }
  }

  getCompanionsData = () => {
    this.setState({ backdrop: true });
    GetData(this.props.HerokuHost + `/account/companion`, 200, this.props.token, this.setCompanions, this.onFailed);
  };

  setCompanions = (data) => {
    this.setState({ companionsList: data.response.users, backdrop: false });
  };

  getProgress = () => {
    GetData(this.props.HerokuHost + "/account/challenge/progress", 200, this.props.token, this.setProgress, this.onFailed);
  };

  setProgress = (response) => {
    // console.log(response.response);
    if (response.response) {
      let progressTrack = response.response.progressTrack;
      progressTrack.reverse();

      this.setState({ trackList: progressTrack });
    }
  };

  closeEndProgressModal = () => this.setState({ endProgressModal: false });

  openEndChallengeModal = () => this.setState({ endProgressModal: true });

  onFailed = (response) => {
    console.log("error on track progress => ", response);
    alert("Track Req Failed!");
  };

  month(number) {
    var month = [];
    month[1] = "January";
    month[2] = "February";
    month[3] = "March";
    month[4] = "April";
    month[5] = "May";
    month[6] = "June";
    month[7] = "July";
    month[8] = "August";
    month[9] = "September";
    month[10] = "October";
    month[11] = "November";
    month[12] = "December";
    return month[number];
  }

  
  setCookie = (cname, cvalue, exmins) => {
    var d = new Date();
    d.setMinutes(d.getMinutes() + exmins);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  startChallenge = (id) =>
  {
    this.setState({backdrop:true})
    let date = new Date().toLocaleDateString().replace(/[/]/g,"-")
    // console.log(date);
    PostData(this.props.HerokuHost+`/account/challenge/${id}/start/${date}`,{},200,this.props.token,this.setStartChallenge,this.onFailed)
  }

  setStartChallenge = (response) =>
  {
    if(response.responseCode==="Success")
    {
      // console.log(response)
      let NewUser = this.props.user;

      let progress = response.response.progress;

      // this.props.setChallenge(null);
      // this.props.setProgress({completed:0,total:progress.total})

      NewUser['challenge']=null;
      NewUser['progress']=progress;

      let LastActivity = new Date(progress.progressDate);
      LastActivity.setDate(LastActivity.getDate() - 1);
      // console.log(LastActivity.toLocaleString())
      // this.props.setUser({lastActivityOn:LastActivity.toLocaleString()})

      NewUser['lastActivityOn']=LastActivity.toLocaleString()
      this.props.setUser(NewUser);
      this.setCookie('user',JSON.stringify(NewUser),30); 

      
      // not setting progress

      this.setState({backdrop:false})

      this.getProgress();
      this.getCompanionsData();


    }

    else
    {
      alert('Something went wrong !')
      console.log('error at starting challenge => ',response)
    }
    
  }

  endChallenge = () =>
  {
    this.setState({backdrop:true})
    let date = new Date().toLocaleDateString().replace(/[/]/g,"-")
    PutData(this.props.HerokuHost+`/account/challenge/end/${date}`,{},200,this.props.token,this.setEndChallenge,this.onFailed)
  }

  setEndChallenge = (response) =>
  {
    // console.log(response);
    if(response.responseCode==="Success")
    {
      let NewUser = this.props.user;
      let progress = this.props.progress;

      progress['completed']=null;
      progress['total']=null;
      NewUser['challenge']=response.response.challenge;
      NewUser['progress']=progress;
    
      this.props.setUser(NewUser);         
      this.setCookie('user',JSON.stringify(NewUser),30);


      this.setState({backdrop:false})

    }

    else
    {
      alert('Something Went Wrong, Please try again');
      console.log('error on ending response => ',response)
      this.setState({backdrop:false})

    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Slide left>

        <Backdrop className={classes.backdrop} open={this.state.backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <div>
          { ((this.props.challenge==null) ? false : (this.props.challenge.toString() !== 'null')) ? (
            <Grid container direction="row" justify="center">
            {/* {console.log(this.props.challenge)} */}
            {
              this.props.challenge.map((activity,id)=>
                <Grid key={id} item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <div className="track-my-progress-StartChallenge-div">
                    <img className="track-my-progress-shield-img" alt="days challenge" src={activity.days===21?Shield21:Shield90} />
                    <div className="track-my-progress-StartChallenge-text">Start the </div>
                    <div className="track-my-progress-StartChallenge-text">{activity.title +" "} Challenge </div>
                    <Button id="track-my-progress-StartChallenge-Button" style={{background:activity.days===90?"#e25601":''}} variant="contained" onClick={()=>this.startChallenge(activity.challengeId)}>
                      Get Started
                    </Button>
                  </div>
                </Grid>
              )
            } 
            </Grid>
          ) : (
            <Grid container direction="row">
              <Grid item xs={12} sm={10} md={6} lg={7} xl={7}>
                <div className="track-my-progress-header">
                  My Progress
                  <Button
                    id="track-my-progress-endChallengeBtn"
                    variant="contained"
                    onClick={this.endChallenge}>
                    End Challenge
                  </Button>
                </div>

                {this.state.backdrop===false ?
                  this.state.trackList.length>0?
                    <div className="companions-new-request-list">
                      <List
                        component="companion-request-list"
                        className={classes.tracklistRoot}
                        aria-label="track-my-progress-list">
                        {this.state.trackList.map((track, key) => (
                          <ListItem key={key} style={{ position: "relative" }}>
                            <ListItemAvatar>
                              <Avatar className={classes.largeAvatar}>
                                <img
                                  width="30px"
                                  src={track.targetAchieved === false ? CheckMark : PopperImage}
                                  alt="Tracker"
                                />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                (track.targetAchieved === false || track.completed<0) ? (
                                  <span className="track-list-date">
                                    {this.month(parseInt(track.progressDate.split("T")[0].split("-")[1])) + " "}{" "}
                                    {track.progressDate.split("T")[0].split("-")[2] +
                                      ", " +
                                      track.progressDate.split("T")[0].split("-")[0]}{" "}
                                  </span>
                                ) : (

                                  <span className="track-list-congrats">
                                    {" "}
                                    CONGRATULATIONS! <br /> YOU FINISHED YOUR {track.total} CONSECUTIVE DAYS. <br />
                                    YOU ARE NOW ONTO 90 DAYS CHALLENGE.
                                  </span>
                                )
                              }
                              secondary={
                                (track.targetAchieved === false || track.completed<0) ? (
                                  <span className="track-list-message"> {track.caption} </span>
                                ) : null
                              }
                            />
                            {track.targetAchieved.toString() === "true" ? (
                              <div className="track-my-progress-line-cong-up"></div>
                            ) : null}

                            {key !== this.state.trackList.length - 1 ? (
                              <div
                                className={
                                  track.targetAchieved.toString() === "true"
                                    ? "track-my-progress-cong-line"
                                    : "track-my-progress-line"
                                }></div>
                            ) : null}
                          </ListItem>
                        ))}
                      </List>
                    </div>
                  : <NoData minHeight="40px" description="No progress" textAlign="left"  marginLeft="20px"/>
                :null}
              </Grid>

              <Grid item xs={12} sm={10} md={6} lg={4} xl={4}>
                <div className="track-my-progress-header">Companions' Progress</div>
                
                {this.state.backdrop===false ?
                  this.state.companionsList.length>0?
                    <div className="companions-new-request-list">
                      <List
                        component="companion-request-list"
                        className={classes.tracklistRoot}
                        aria-label="companions-progress-list">
                        {this.state.companionsList.map((comp, key) => (
                          <ListItem key={key}>
                            <ListItemAvatar>
                              <Avatar className={classes.largeAvatar}>
                                <img src={comp.photoUrl ? comp.photoUrl : DefaultDP} width="64px" alt="thumbnail" />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={<span className="track-list-comp-name"> {comp.name} </span>}
                              secondary={
                                <React.Fragment>
                                  <span className="track-list-comp-progress">
                                    {comp.progress
                                      ? "Completed " + comp.progress.completed + " days of " + comp.progress.total + " days"
                                      : null}
                                  </span>
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                        ))}
                      </List>
                    </div>
                  :<NoData minHeight="40px" description="No companions progress" textAlign="left" marginLeft="20px"/>
                :null}
              </Grid>
            </Grid>
          )}
        </div>
        {/* <EndProgressModal open={this.state.endProgressModal} onClose={this.closeEndProgressModal} /> */}
      </Slide>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    challenge: state.setUser.challenge,
    HerokuHost: state.setHerokuHost,
    token: state.setUser.token,
    progress:state.setUser.progress,
    user:state.setUser,
  };
};

export default compose(connect(mapStateToProps,{setChallenge,setProgress,setUser}), withStyles(styles))(TrackProgress);
