import React, { Component, useState } from "react";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import {
  Button,
  Fab,
  withStyles,
  TextField,
  Backdrop,
  IconButton,
} from "@material-ui/core";
import PostData from "../fetchDataPost";
import GetData from "../fetchDataGet";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AddCircleOutline, CheckCircle } from "@material-ui/icons";
import { connect } from "react-redux";
import { compose } from "redux";

class NewGroupModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupTitle: "",
      error: false,
      errorMessage: "",
      backdrop: false,
      generalError: false,
      generalErrorMessage: "",
      companionsList: null,
      selectedCompanions: [],
    };
  }

  componentDidUpdate(prevProps) {
    prevProps.open !== this.props.open && this.getCompanionsData();
  }

  checkMe = (e = null) => {
    let textLength;
    if (e == null) {
      textLength = this.state.groupTitle.length;
      console.log("e was null");
    } else textLength = e.length;
    if (textLength < 6) {
      this.setState({
        error: true,
        errorMessage: "Group name must be at least 6 character long",
        generalError: false,
        generalErrorMessage: "",
      });
      return false;
    } else {
      this.setState({
        error: false,
        errorMessage: "",
        generalError: false,
        generalErrorMessage: "",
      });
      return true;
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    this.checkMe(e.target.value);
  };

  getCompanionsData = () => {
    this.setState({ backdrop: true });
    GetData(
      this.props.HerokuHost + `/account/companion`,
      200,
      this.props.token,
      this.setCompanions,
      this.onFailed
    );
  };

  setCompanions = (data) => {
    this.setState({ companionsList: data.response.users, backdrop: false });
  };

  createGroup = () => {
    if (this.checkMe() == true) {
      let data = {
        title: this.state.groupTitle,
        members: this.state.selectedCompanions,
        imageUrl:
          "https://geodash.gov.bd/uploaded/people_group/default_group.png",
      };
      this.setState({ backdrop: true });
      console.log(data);
      PostData(
        this.props.HerokuHost + `/account/group`,
        data,
        200,
        this.props.token,
        this.groupCreated,
        this.onFailed
      );
    }
  };

  groupCreated = (response) => {
    console.log(response);
    if (response.responseCode === "Success") {
      this.setState({
        groupTitle: "",
        error: false,
        errorMessage: "",
        backdrop: false,
        generalError: false,
        generalErrorMessage: "",
      });
      this.props.onClose(true)
    } else if (
      response.responseCode === "Failed" ||
      response.responseCode === "Error"
    ) {
      this.setState({
        error: false,
        errorMessage: "",
        backdrop: false,
        generalError: true,
        generalErrorMessage: response.validationErrorsList[0].errorMessage,
        selectedCompanions:[],

      });
    }
  };

  renderCompanions = () => {
    return this.state.companionsList.map((member) => {
      return <Member member={member} handleMember={this.handleMember} />;
    });
  };

  handleMember = (flag, userId) => {
    flag ? this.addMemberinList(userId) : this.removerMemberinList(userId);
  };

  addMemberinList = (id) => {
    let tempArr = [...this.state.selectedCompanions];
    tempArr.push(id);
    this.setState({ selectedCompanions: tempArr });
  };

  removerMemberinList = (id) => {
    let newArr = this.state.selectedCompanions.filter(
      (arrId) => arrId.toString() !== id.toString()
    );
    this.setState({ selectedCompanions: newArr });
  };

  onFailed = (response) => {
    this.setState({ backdrop: false });
    console.log(response);
    alert("Failed response");
  };

  render() {
    const { classes } = this.props;
    return (
      <Rodal visible={this.props.open} onClose={()=>this.props.onClose(false)}>
        <div className="NewGroupModal-rodal-header">Create A New Group</div>
        {this.state.backdrop === true ? (
          <Backdrop className={classes.backdrop} open={this.state.backdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <div className="NewGroupModal-rodal-content-wrapper">
            <div className="NewGroupModal-rodal-fields-wrapper">
              <TextField
                error={this.state.error}
                id="groupTitle"
                label="Group Title"
                value={this.state.groupTitle}
                onChange={this.handleChange}
                helperText={this.state.errorMessage}
              />
            </div>

            {this.state.generalError === true ? (
              <div className="NewGroupModal-rodal-error-wrapper">
                {this.state.generalErrorMessage}
              </div>
            ) : null}

            <div className="NewGroupModal-input-div">
              <input
                className="NewGroupModal-input"
                placeholder="Search members"
              />
            </div>

            <div className="NewGroupModal-membersList">
              {this.state.companionsList && this.state.companionsList.length > 0
                ? this.renderCompanions()
                : null}
            </div>

            <div className="NewGroupModal-rodal-button-wrapper">
              <Button
                id="NewGroupModal-rodal-newGroup-button"
                variant="contained"
                onClick={this.createGroup}
                disabled={
                  this.state.error || this.state.selectedCompanions.length < 1
                }
              >
                {" "}
                Create Group{" "}
              </Button>
            </div>
          </div>
        )}
      </Rodal>
    );
  }
}

const style = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
});

const mapStateToProps = (state) => {
  return {
    HerokuHost: state.setHerokuHost,
    token: state.setUser.token,
    userId: state.setUser.userId,
  };
};

export const Member = (props) => {
  const [checked, setChecked] = useState(false);

  const handleClick = (flag) => {
    setChecked(flag);
    props.handleMember(flag, props.member.userId);
  };

  return (
    <div className="NewGroupModal-member-div">
      <div className="NewGroupModal-member-details">
        <div className="NewGroupModal-member-name">{props.member.name}</div>
      </div>
      <div className="NewGroupModal-member-remove">
        {checked ? (
          <IconButton onClick={() => handleClick(false)}>
            <CheckCircle style={{ color: "#022635" }} fontSize="large" />
          </IconButton>
        ) : (
          <IconButton onClick={() => handleClick(true)}>
            <AddCircleOutline style={{ color: "#989898" }} fontSize="large" />
          </IconButton>
        )}
      </div>
    </div>
  );
};


export default compose(
  connect(mapStateToProps, {}),
  withStyles(style)
)(NewGroupModal);
