import React from "react";
import Skeleton from "react-loading-skeleton";

const ListenSkeleton = () => {
  return (
    <div className="Main-Listen-skeleton">
      <div className="Listen-skeleton-circle-div">
        <Skeleton className="Listen-skeleton-circle" circle={true} height={40} width={40} />
      </div>
      <div className="Listen-skeleton-lines">
        <Skeleton className="Listen-skeleton-line" />
      </div>
    </div>
  );
};

export default ListenSkeleton;
