import React, { useRef } from "react";
import "./signup.scss";
import { Grid, TextField } from "@material-ui/core";

const Signup2 = (props) => {
  const inputEl = useRef(null);
  return (
    <>
      <Grid container direction="row">
        <Grid item xs={12} md={6}>
          <div className="Signup2-Name">
            <TextField
              id="name"
              label="Name"
              fullWidth
              value={props.name}
              onChange={props.handleChange}
            />
          </div>
          <div className="Signup2-Email">
            <TextField
              id="email"
              label="Email"
              fullWidth
              value={props.email}
              onChange={props.handleChange}
            />
          </div>
          <div className="Signup2-Password">
            <TextField
              id="password"
              label="Password"
              type="password"
              fullWidth
              value={props.password}
              onChange={props.handleChange}
            />
          </div>

          <div className="Signup2-Password">
            <TextField
              id="Cpassword"
              label="Confirm Password"
              type="password"
              fullWidth
              value={props.Cpassword}
              onChange={props.handleChange}
            />
          </div>


        </Grid>
        <Grid item xs={12} md={6}>
          <div className="Signup2-Birthday">
            {/* <TextField id="standard-basic" label="Birthday" fullWidth /> */}
            <input
              ref={inputEl}
              id="birthday"
              className="Signup2-DateInput"
              onChange={props.handleChange}
              placeholder="Birthday"
              type="text"
              onFocus={() => (inputEl.current.type = "date")}
            />
          </div>
          <div className="Signup2-CityState">
            <TextField
              id="address"
              onChange={props.handleChange}
              label="City, State"
              fullWidth
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Signup2;
