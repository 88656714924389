import React, { Component } from "react";
import ReadAndReflectComp from "./ReadAndReflect";
import AddAudio from "./AddAudio";

class MainReadAndReflect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addState: false,
    };
  }

  handleAddState = (state) => {
    this.setState({ addState: state });
  };
  render() {
    return (
      <>
        {this.state.addState ? (
          <AddAudio handleAddState={this.handleAddState} />
        ) : (
          <ReadAndReflectComp handleAddState={this.handleAddState} />
        )}
      </>
    );
  }
}

export default MainReadAndReflect;
