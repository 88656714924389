import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import DefaultDP from "../../assets/defaultDP.png";

import SwipeableViews from "react-swipeable-views";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import "./companions.css";
import { Button } from "@material-ui/core";
import GetData from "../fetchDataGet";
import DeleteData from "../fetchDataDelete";
import { connect } from "react-redux";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import Slide from "react-reveal/Slide";
import Zoom from "react-reveal/Zoom";

import logo from "../../assets/TheFamilyWinsLogo.png";
import NoData from "../../containers/NoData/NoData";
import OtherCompanion from "./otherCompanion";
import CompanionRequest from "./companionRequest";

import Popper from '@material-ui/core/Popper';
import { useSpring, animated } from 'react-spring/web.cjs';


// const newRequest = [{id:'0',name:'Kwabena Angel' ,thumbnail:Image}, {id:'1',name:'Will Warbow' ,thumbnail:Image},{id:'2',name:'Gunter von Harringa' ,thumbnail:Image},{id:'3',name:'Robbert Littlefeild' ,thumbnail:Image},{id:'4',name:'Matt Hyde' ,thumbnail:Image}, ]
// const myComp = [{id:'0',name:'TC Sargent' ,thumbnail:''}, {id:'1',name:'Mila Rose' ,thumbnail:''},{id:'2',name:'Elliot Paco Enriquez' ,thumbnail:''},{id:'3',name:'Andrew J. Colabella' ,thumbnail:''},{id:'4',name:'Nick Renzulli' ,thumbnail:''}]
const styles = (theme) => ({
  playlistRoot: {
    width: "100%",
    maxWidth: 600,
    backgroundColor: theme.palette.background.paper,
    overflow:'hidden',
  },
  largeAvatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};



const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};




class Companions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otherComp: [],
      newRequest: [],
      myComp: [],
      value: 0,
      backdrop: null,
      backdrop1: null,
      backdrop2: null,
      finderLoader: null,
      otherCompanionSearch: "",
      newOtherCompUpdate:{},
      onSearch: false,
      newRequestLoader:false,
      anchorEl:null

    };
  }

  componentWillMount() {
    this.getCompanionsData();
    this.getNewRequests();
  }

  getNewRequests = () => {
    this.setState({ backdrop: true, backdrop2: true });
    GetData(
      this.props.HerokuHost + `/account/companion/request`,
      200,
      this.props.token,
      this.setNewRequests,
      this.onFailed
    );
  };

  getCompanionsData = () => {
    this.setState({ backdrop: true, backdrop1: true });

    


    GetData(
      this.props.HerokuHost + `/account/companion`,
      200,
      this.props.token,
      this.setCompanions,
      this.onFailed
    );
  };

  getOtherCompanions = () => {
    this.setState({ finderLoader: true });
    GetData(
      this.props.HerokuHost + `/account/search`,
      200,
      this.props.token,
      this.setOtherCompanions,
      this.onFailed
    );
  };

  setOtherCompanions = (response) => {
    console.log("other companions response => ", response);
    if (response.response) {
      let users = response.response.users;
      this.setState({ otherComp: users });
    } else if (response.validationErrorsList) {
      console.log("Error on companions => ", response.validationErrorsList[0]);
    }
    this.setState({ finderLoader: false });
  };

  setNewRequests = (response) => {
    // console.log("response => ", response);
    if (response.response) {
      let requests = response.response.requests;
      this.setState({ newRequest: requests });
    } else if (response.validationErrorsList) {
      console.log("Error on companions => ", response.validationErrorsList[0]);
    }

    if (this.state.backdrop1 === false) {
      this.setState({ backdrop: false, backdrop2: false });
    } else this.setState({ backdrop2: false });
  };

  setCompanions = (response) => {
    // console.log("companions response => ", response);
    if (response.response) {
      let users = response.response.users;
      this.setState({ myComp: users },
        ( 
          ()=>{
            let items=document.getElementsByClassName('MuiListItemAvatar-root');
            // console.log(items.length)
            // for(ite).forEach(item => {
            //   alert('added')
            // item.addEventListener('mouseenter', event => {
            //   alert('aaa')
            // })
            // })
          }
        )
      );
    } else if (response.validationErrorsList) {
      console.log("Error on companions => ", response.validationErrorsList[0]);
    }

    if (this.state.backdrop2 === false) {
      this.setState({ backdrop: false, backdrop1: false });
    } else this.setState({ backdrop1: false });
  };

  onFailed = (response) => {
    this.setState({ backdrop: false });
    alert("Companion req failed");
    console.log("failed response on companions => ", response);
  };

  Find = () => {
    this.setState({ value: 1 });
    this.getOtherCompanions();
  };
  Back = () => {
    this.setState({ value: 0 });
  };

 
  

  searchOtherCompanions = () => {
    const searchText = this.state.otherCompanionSearch;
    if (
      searchText !== " " &&
      searchText !== "" &&
      searchText !== null &&
      searchText
    ) {
      this.setState({ finderLoader: true, onSearch: true });
      GetData(
        this.props.HerokuHost + `/account/search?match=${searchText}`,
        200,
        this.props.token,
        this.setOtherCompanions,
        this.onFailed
      );
    } else if (this.state.onSearch === true) {
      if (searchText === " " || searchText === "") {
        this.setState({ finderLoader: true, onSearch: false });
        GetData(
          this.props.HerokuHost + `/account/search`,
          200,
          this.props.token,
          this.setOtherCompanions,
          this.onFailed
        );
      }
    } else return;
  };

  handleChange = (e) => {
    this.setState({ [e.target.id || e.target.name]: e.target.value });
  };

  handleCancelRequest = (id) => {
    console.log(id);
    DeleteData(
      this.props.HerokuHost + `/account/companion/request/${id}`,
      200,
      this.props.token,
      (data) => {
        let requests = data.response.requests;
        this.setState({ newRequest: requests });
      },
      this.onFailed
    );
  };

  handleRequestUpdate = (updateObj) =>
  {
    let allreq=this.state.newRequest;
    let newReq=allreq.filter( (req)=> req.companion.userId.toString()!==updateObj.id.toString() )

    // this.setState({newRequestUpdate:updateObj})
    this.setState({newRequest:newReq});
  }

  handleOtherCompUpdate = (updateObj) =>
  {
    let newObject = JSON.stringify(updateObj);
    newObject = JSON.parse(newObject);
    let allreq=this.state.newRequest;
    let newReq=allreq.filter( (req)=> req.companion.userId.toString()!==updateObj.id.toString() )
    this.setState({newOtherCompUpdate:newObject,newRequest:newReq})
  }

  closeAnchorEl = () => {
   
    this.setState({anchorEl:null})
   
    
  };

  setAnchorEl = (event,img) =>
  {
    this.setState({anchorEl:event.currentTarget,HoverImg:img})
  }


  render() {

    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const { classes } = this.props;
    return this.state.backdrop ? (
      <div>
        <Backdrop className={classes.backdrop} open={this.state.backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    ) : (
      <Slide left>
        <div>
          <Grid container direction="row">
            {/* NEW RQUEST */}
            <Grid item xs={12} sm={10} md={6} lg={5} xl={5}>
              <div className="companions-new-request-header">New Requests</div>
              { this.state.newRequest.filter(req => req.isMyRequest===false).length > 0 ?                 
              (
                <div className="companions-new-request-list">
                    <List
                      component="companion-request-list"
                      className={classes.playlistRoot}
                      aria-label="mailbox folders"
                    >
                      {this.state.newRequest.map(
                        (req, key) =>
                          !req.isMyRequest && (
                            <CompanionRequest req={req} handleOtherCompUpdate={(updateObj)=>{this.handleOtherCompUpdate(updateObj)}} />
                          )
                      )}
                    </List>
                  </div>
                ) 
                :
                (
                  <NoData
                    innerDiv={true}
                    minHeight={"150px"}
                    height={"calc(100vh - 400px)"}
                    background={"#bdaeae2e"}
                    description={"No request to show."}
                  />
                )
              }
            </Grid>
            <Grid item xs={false} sm={false} md={false} lg={1} xl={1}></Grid>
            <Grid item xs={12} sm={10} md={6} lg={5} xl={5}>
            <Popper 
              id={id}
              open={open}
              anchorEl={this.state.anchorEl}
              transition
              placement={"left"} 
            >
             {({ TransitionProps }) => (
                <Fade {...TransitionProps}>
                  <img src={this.state.HoverImg?this.state.HoverImg:null} style={{ maxWidth:"400px",maxHeight:'200px'}} alt="Hover"/>
                </Fade>
              )}
            </Popper >
              <SwipeableViews axis={"x"} index={this.state.value}>
                <TabPanel value={this.state.value} index={0} dir={"ltr"}>
                  <div className="companions-mycomp-header">
                    My Companions
                    <Button
                      variant="contained"
                      className="companions-find-button"
                      onClick={this.Find}
                    >
                      {" "}
                      Find{" "}
                    </Button>
                  </div>
                  <div className="companions-mycomp-list">
                    <List
                      component="companion-request-list"
                      className={classes.playlistRoot}
                      aria-label="mailbox folders"
                    >
                      {this.state.myComp.length > 0 ? (
                        this.state.myComp.map((comp, key) => (
                          <Zoom>
                            <ListItem key={key}>
                              <ListItemAvatar >
                                  <Avatar className={classes.largeAvatar}  onMouseEnter={(e)=>this.setAnchorEl(e,comp.photoUrl ? comp.photoUrl : DefaultDP)} onMouseLeave={this.closeAnchorEl} >
                                    <img
                                   
                                      src={
                                        comp.photoUrl ? comp.photoUrl : DefaultDP
                                      }
                                      alt={DefaultDP}
                                      width={"64px"}
                                    />
                                  </Avatar>
                                
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <span className="companions-mycomp-name">
                                    {" "}
                                    {comp.name}{" "}
                                  </span>
                                }
                              />
                            </ListItem>
                          </Zoom>
                        ))
                      ) : (
                        <NoData
                          innerDiv={true}
                          minHeight={"150px"}
                          height={"calc(100vh - 400px)"}
                          background={"#bdaeae2e"}
                          description={"No companions, Add one."}
                        />
                      )}
                    </List>
                  </div>
                </TabPanel>

                <TabPanel value={this.state.value} index={1} dir={"ltr"}>
                  <div className="companions-mycomp-header">
                    <div style={{ marginBottom: "20px" }}>
                      Search
                      <Button
                        variant="contained"
                        className="companions-back-button"
                        onClick={this.Back}
                      >
                        {" "}
                        Back{" "}
                      </Button>
                    </div>

                    <TextField
                      id="otherCompanionSearch"
                      type="search"
                      variant="outlined"
                      onChange={this.handleChange}
                      className="companions-find-search-field"
                    />
                    <Button
                      variant="contained"
                      className="companions-find-search-button"
                      onClick={this.searchOtherCompanions}
                    >
                      {" "}
                      <SearchIcon />{" "}
                    </Button>
                  </div>

                  {this.state.finderLoader === false ? (
                    <div className="companions-mycomp-list">
                      {this.state.otherComp.length > 0 ? (
                        <List
                          component="companions-list"
                          className={classes.playlistRoot}
                          aria-label="companions-list"
                        >
                          {this.state.otherComp.map((comp, key) => (
                            <OtherCompanion comp={comp} newOtherCompUpdate={this.state.newOtherCompUpdate} handleRequestUpdate={(updateObj)=>this.handleRequestUpdate(updateObj)}/>
                          ))}
                        </List>
                      ) : (
                        <NoData
                          innerDiv={true}
                          minHeight={"150px"}
                          height={"calc(100vh - 400px)"}
                          background={"#bdaeae2e"}
                          description={"No companions, Add one."}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="companions-finder-loader-wrapper">
                      <img
                        src={logo}
                        className="companions-search-loader-image hvr-back-pulse"
                        alt="img"
                      />
                      <div className="companions-search-loader-text">
                        {" "}
                        Family Wins, Loading Users...
                      </div>
                    </div>
                  )}
                </TabPanel>
              </SwipeableViews>
            </Grid>
          </Grid>
        </div>
      </Slide>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // userId: state.setUser.userId,
    // userName: state.setUser.userName,
    HerokuHost: state.setHerokuHost,
    token: state.setUser.token,
    // date: state.setDate.date,
  };
};

export default React.memo(
  connect(mapStateToProps, null)(withStyles(styles)(Companions))
);
