import React, { Component } from 'react';
import AddOrganaization from './AddOrganaization';
import OrganaizationData from './OrganaizationData';
import OrganizationSpecific from './OrganizationSpecific';
import ViewOrganaizationCodes from './Codes/ViewOrganaizationCodes';
import ViewOrganaizationCodeBatch from './Codes/ViewOrganaizationCodeBatch';

const CodeTypes = [
    {name:"single-use-code", codeType: '1', id:"1", label:"Single Use Code"},
    {name:"multiple-use-code", codeType: '2',label:"Multiple Use Code",id:"2"}
]

class OrganaizationMain extends Component {
    constructor(props) {
        super(props);
        this.state = { view:'Organaizations',selectedUser:null,batchCodeId:null }
    }

    

    handleToggleView = (view) =>
    {
        //'OrganaizationSpecific'
        //Organaizations
        //AddOrganaization
        this.setState({view:view,batchCodeId:null});

        // else if(this.state.view==="OrganaizationSpecific")
        // this.setState({view:'Organaizations'});
    }

    handleSelectedUser = (selectedUser,view) =>
    {
        this.setState({selectedUser,view,batchCodeId:null})
    }

    handleSelectedBatchCode = (batchCodeId) => {
        this.setState({batchCodeId,view:"OrganaizationViewCodeBatch"})
    }

    render() { 
        return ( 
        
        <div>

            {
                this.state.view==="Organaizations"?<OrganaizationData CodeTypes={CodeTypes} handleToggleView={(view)=>this.handleToggleView(view)} handleSelectedUser={(user,view)=>this.handleSelectedUser(user,view)} />
                :
                this.state.view==="OrganaizationViewCodes"? <ViewOrganaizationCodes goBack={()=>this.handleToggleView("Organaizations")} CodeTypes={CodeTypes} handleSelectedBatchCode={this.handleSelectedBatchCode} handleToggleView={()=>this.handleToggleView("Organaizations")} user={this.state.selectedUser} />
                :  
                this.state.view==="OrganaizationViewCodeBatch"? <ViewOrganaizationCodeBatch goBack={()=>this.handleToggleView("OrganaizationViewCodes")} batchCodeId={this.state.batchCodeId} handleToggleView={()=>this.handleToggleView("Organaizations")} user={this.state.selectedUser} />
                :
                this.state.view==="AddOrganaization"? <AddOrganaization goBack={()=>this.handleToggleView("Organaizations")} />
                :
                this.state.view==="OrganaizationEdit"? <OrganizationSpecific handleToggleView={(view)=>this.handleToggleView(view)} goBack={()=>this.handleToggleView("Organaizations")} user={this.state.selectedUser} handleSelectedUser={(user,view)=>this.handleSelectedUser(user,view)}/>
                :
                null
            }

        </div> );
    }
}
 
export default OrganaizationMain;