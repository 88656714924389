const GetData = (url, statusCode, req, callback) => {
  let token;
  let object;
  fetch(url, {
    method: "POST",
    headers: {
      // 'Access-Control-Request-Headers':'Content-Type',
      "Content-Type": "application/json",
      //   "Authorization": "Bearer " + btoa(JSON.stringify(req))
      Authorization: "Basic " + btoa(JSON.stringify(req)),
    },
  })
    .then(function (response) {
      if (response.status !== statusCode) {
        console.log(
          "Looks like there was a problem. Status Code: " + response.status
        );
        return response;
      } else {
        // console.log(response.headers.get("Token"));
        token = response.headers.get("Token");
        return response.json();
      }
    })
    .then(function (data) {
      // console.log(data.headers);
      if (data.id && data.id != null) {
        // console.log("Success");
        object = data;
      } else {
        object = data;
        // console.log("Failed");
      }

      callback(object, token);
    });
};

export default GetData;
