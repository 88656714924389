export const ActionTypes = {
  UPATE_USER: "UPATE_USER",
  SET_DATE: "SET_DATE",
  SET_AHEAD: "SET_AHEAD",
  LOGOUT: "LOGOUT",
  SET_ACTUAL_DATE: "SET_ACTUAL_DATE",
  SET_CHALLENGE: "SET_CHALLENGE",
  SET_COMPLETED: "SET_COMPLETED",

};

export const setUser = (userInfo) => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.UPATE_USER,
      payload: {
        ...userInfo,
      },
    });
  };
};

export const logoutUser = () => {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.LOGOUT,
      payload: {},
    });
  };
};

export const setAhead = (ahead) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.SET_AHEAD, payload: { ahead } });
  };
};

export const setActualDate = (date) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.SET_ACTUAL_DATE, payload: { date } });
  };
};

export const setDate = (date) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.SET_DATE, payload: { date } });
  };
};

export const setChallenge = (challenge) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.SET_CHALLENGE, payload: { challenge } });
  };
};

export const setProgress = (progress) => {
  return async (dispatch) => {
    dispatch({ type: ActionTypes.SET_COMPLETED, payload: {progress:progress} });
  };
};
