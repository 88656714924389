import React, { Component } from "react";
import "./Watch.scss";
import { connect } from "react-redux";
import VideoCard from "./videocard.jsx";
import VideoDialoge from "./dialoge.jsx";
import FetchVideos from "../fetchDataGet.jsx";
import { TransverseLoading } from "react-loadingg";
import Slide from 'react-reveal/Slide';
import DeleteData from "../fetchDataDelete";
import PostData from "../fetchDataPost";

const ContentTypeID = 3;

class Watch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dialogeOpen: false,
      videos: [],
      dialogeLink: null,
    };
  }

  componentDidMount() {
    this.GetVideos(this.props.date);
  }

  componentDidUpdate(oldprops) {
    if (oldprops.date !== this.props.date) {
      this.GetVideos(this.props.date);
    }
  }

  handleToggleFavourite = (e,id,isWatchFav) => {
    e.stopPropagation();
    this.setState({
      loading: true,
    });
    isWatchFav?
    DeleteData(
      this.props.HerokuHost + `/contents/deletefavourite/${ContentTypeID}/${id}`,
      {},
      200,
      this.props.token,
      (data) => {
        this.onToggleSuccess(data,id)
      },
      this.onFailed
    )
    :
    PostData(
      this.props.HerokuHost + `/contents/addtofavourite/${ContentTypeID}/${id}`,
      {},
      200,
      this.props.token,
      (data) => {
        this.onToggleSuccess(data,id)
      },
      this.onFailed
    );
  }

  onToggleSuccess = (data,id) => {
    console.log("Working", data);
    if (data.responseCode === "Success") {

      let newWatchList = JSON.parse(JSON.stringify(this.state.videos));
      let findedRow = newWatchList.find((v)=>v?.id?.toString() === id.toString());
      findedRow.isFavourite = !findedRow.isFavourite;

     this.setState({
      loading:false,
      videos:newWatchList
     })
    }
  }

  GetVideos = (date) => {
    this.setState({
      loading: true,
      dialogeOpen: false,
      videos: [],
      dialogeLink: null,
    });
    FetchVideos(
      this.props.HerokuHost + `/contents/video/${date}`,
      200,
      this.props.token,
      (data) => {
        this.setState({ videos: data.response.contents, loading: false });
      }
    );
  };

  handleDialoge = (x, link) => {
    x === "o"
      ? this.setState({ dialogeOpen: true, dialogeLink: link })
      : this.setState({ dialogeOpen: false, dialogeLink: null });
  };

  renderVideos = () => {
    const {isFavourite} = this.props;

    return this.state.videos.map((video, index) => {
      return (
        <VideoCard
          key={index}
          openDialoge={() => this.handleDialoge("o", video.url)}
          handleToggleFavourite={this.handleToggleFavourite}
          isFavourite={this.props.isFavourite}
          video={video}
        />
      );
    });
  };

  render() {
    return this.state.loading ? (
      <div className="journal-loader-wrraper">
        <TransverseLoading color="#225b76" size="large" />
      </div>
    ) : (
      <Slide left>
      <div className="W-user-videos-Wrapper">
        {this.state.videos.length > 0 ? (
          this.renderVideos()
        ) : (
          <div className="W-NoVideos">No Videos for <span className="GD-NoVideos-Day"> {this.props.date}</span>. </div>

        )}
        <VideoDialoge
          Open={this.state.dialogeOpen}
          Close={() => this.handleDialoge("c")}
          link={this.state.dialogeLink}
        />
      </div>
      </Slide>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.setUser.userId,
    userName: state.setUser.userName,
    HerokuHost: state.setHerokuHost,
    token: state.setUser.token,
    date: state.setDate.date,
  };
};

export default connect(mapStateToProps, null)(Watch);
