import React, { Component } from "react";
import { IconButton } from "@material-ui/core";
import {
  Add,
  Remove,
  RemoveCircleOutline,
} from "@material-ui/icons";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { connect } from "react-redux";

import GetData from "../fetchDataGet";
import PutData from "../fetchDataPut";



class GroupChatSettings extends Component {

  constructor(props) {
    super(props);
    this.state = {  members:[] }
  }

  componentDidMount() 
  {
    this.getMembers();
  }

  getMembers = () =>
  {
    this.setState({loader:true})
    GetData(this.props.HerokuHost+`/account/group/${this.props.activeGroupId}/members`,200,this.props.token,this.setMembers,this.onFailed)
  }

  setMembers = (response) =>
  {
    console.log(response);
    if(response.responseCode==="Success")
    {
      this.setState({loader:false,members:response.response.members})
    }
    else if((response.responseCode==="Failed") || (response.responseCode==="Error"))
    {
      alert('Something went wrong.')
      console.log('failure at getting group members =>',response)
    }

    else
    {
      alert('Something went wrong.')
      console.log('failure at getting group members  =>',response)
    }
  }

  editName = () =>
  {
    alert('UNDER DEVELOPMENT');
  }

  onFailed = (response) =>
  {
    alert('Something went wrong.')
    console.log('onFailed => failure at getting chat thread =>',response)
  }

  ExitGroup = () =>
  {
    PutData(this.props.HerokuHost+`/account/group/${this.props.thread.group.groupId}/exit`,{},200,this.props.token,this.Exitted,this.onFailed)
  }

  Exitted = (response) =>
  {
    console.log(response);
    if(response.responseCode==="Success")
    {
      this.props.ExitGroup();
    }
    else if((response.responseCode==="Failed") || (response.responseCode==="Error"))
    {
      alert('Something went wrong.')
      console.log('failure at getting group members =>',response)
    }

    else
    {
      alert('Something went wrong.')
      console.log('failure at getting group members  =>',response)
    }
  }

  RemoveMember = (id) =>
  {
    let members=this.state.members;
    let newMembers = members.filter((member)=> member.userId.toString()!==id.toString())
    this.setState({members:newMembers})
  }

  render() {
    return (
      this.props.thread?
      <div className="SG-GroupSetting-Wrapper">
        <div className="SG-GroupSetting-header">
          <div className="SG-GroupSetting-name">
            { this.props.thread.group.title}{" "}
            {this.props.thread.group.selfOwned?<span className="SG-GroupSetting-Editname" onClick={this.editName} >Edit Name</span>:null}
          </div>
          <IconButton onClick={() => this.props.swipe(0)}>
            <ChevronLeftIcon
              style={{ color: "#989898", cursor: "pointer" }}
              fontSize="large"
            />
          </IconButton>
        </div>
        <div className="SG-GroupSetting-membersList">
        {
          this.state.members.map((member,id)=><Member HerokuHost={this.props.HerokuHost} activeGroupId={this.props.activeGroupId} token={this.props.token} RemoveMember={(id)=>{this.RemoveMember(id)}} key={id} member={member} selfOwned={this.props.thread.group.selfOwned} />)
        }
        </div>
        <div className="SG-GroupSetting-GroupOptions">
          {this.props.thread.group.selfOwned?
            <div className="SG-GroupSetting-Option-div" onClick={() => this.props.swipe(2)}>
              <div
                className="SG-GroupSetting-Option-icon"
              >
                <Add style={{ color: "white" }} fontSize="large" />
              </div>
              <div className="SG-GroupSetting-Option-text">Add Member</div>
            </div>
          :null}
          <div className="SG-GroupSetting-Option-div" onClick={this.ExitGroup}>
            <div className="SG-GroupSetting-Option-icon">
              <Remove style={{ color: "white" }} fontSize="large" />
            </div>
            <div className="SG-GroupSetting-Option-text">Exit Group</div>
          </div>
        </div>
      </div>
      :null
    );
  }
}

export const Member = (props) => {

  const RemoveMember = () =>
  {
    PutData(props.HerokuHost+`/account/group/${props.activeGroupId}/members/${props.member.userId}/remove`,{},200,props.token,checkRemoveMember,onFailed)
  }

  const checkRemoveMember = (response) =>
  {
    console.log(response);
    if(response.responseCode==="Success")
    {
      props.RemoveMember(props.member.userId);
    }
    else if((response.responseCode==="Failed") || (response.responseCode==="Error"))
    {
      alert('Something went wrong.')
      console.log('failure at getting group members =>',response)
    }

    else
    {
      alert('Something went wrong.')
      console.log('failure at getting group members  =>',response)
    }
  }

  const onFailed = (response) =>
  {
    alert('Something went wrong.')
    console.log('onFailed => failure at getting chat thread =>',response)
  }
  return (
    <div className="SG-GroupSetting-member-div">
      <div className="SG-GroupSetting-member-details">
      {console.log(props.member)}
        <div className="SG-GroupSetting-member-name" >{props.member.title}</div>
        <div className="SG-GroupSetting-member-info">
          {props.member.progress? "Completed " + props.member.progress.completed + " days of " + props.member.progress.total + " days":null}
        </div>
      </div>
      {
        (!props.member.isAdmin && props.selfOwned)?
        <div className="SG-GroupSetting-member-remove" onClick={RemoveMember} >
          <RemoveCircleOutline style={{ color: "#989898" }} fontSize="large" />
        </div>
        :null
      }
      
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    HerokuHost: state.setHerokuHost,
    token: state.setUser.token,
  };
};

export default connect(mapStateToProps, {})(GroupChatSettings);


