/* eslint-disable eqeqeq */
const PutData = (url, data, statusCode, token, callback, onFailed) => {
  let object;
  fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(data),
  })
    .then(function (response) {
      console.log(response);
      if (response.status != statusCode) {
        console.log(
          "Looks like there was a problem. Status Code: " + response.status
        );
        return response;
      } else return response.json();
    })
    .then(function (data) {
      object = data;
      // console.log(data.status);
      if (data.status && data.status !== statusCode) {
        onFailed(object);
      } else callback(object);
    });
};

export default PutData;
