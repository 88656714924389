import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { setUser } from "../store/actions";

class tempAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleUser = (type) => {
    this.props.setUser(type);
  };
  render() {
    return (
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <div style={{ color: "grey", fontSize: "26px", marginBottom: "20px" }}>
          Select An Auth
        </div>

        <Button
          varinat="contained"
          style={{
            display: "block",
            padding: "20px",
            margin: "20px",
            background: "#225b76",
            color: "white",
          }}
          onClick={() => this.handleUser("User")}
        >
          {" "}
          User Auth{" "}
        </Button>
        <Button
          varinat="contained"
          style={{
            display: "block",
            padding: "20px",
            margin: "20px",
            background: "#225b76",
            color: "white",
          }}
          onClick={() => this.handleUser("Admin")}
        >
          {" "}
          Admin Auth{" "}
        </Button>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userType: state.setUser.userType,
  };
};
export default connect(mapStateToProps, { setUser })(tempAuth);
