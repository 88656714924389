/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import ReadArticle from "./readArticle.jsx";
import EditArticle from "./EditArticle.jsx";
import AddArticle from "./AddArticle.jsx";
import { connect } from "react-redux";
import GetData from "../../user/fetchDataGet";
import DeleteData from "../../user/fetchDataDelete";
import { TransverseLoading } from "react-loadingg";
import Slide from "react-reveal/Slide";

import "../../user/GoDeeper/GoDeeper.scss";

class GoDeeper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      author: "",
      title: "",
      content: "",
      image: "",
      editMode: false,
      addMode: false,
      readMode: true,
      loading: false,
    };
  }

  componentDidMount() {
    this.getArticle(this.props.date);
  }

  componentDidUpdate(oldprops) {
    if (oldprops.date !== this.props.date) {
      this.getArticle(this.props.date);
    }
  }

  getArticle = (date) => {
    this.setState({
      loading: true,
      id: "",
      title: "",
      author: "",
      content: "",
      image: "",
    });
    GetData(
      this.props.HerokuHost + `/contents/article/${date}`,
      200,
      this.props.token,
      this.setArticle,
      this.onFailed
    );
  };

  setArticle = (data) => {
    const article = data.response.contents[data.response.contents.length - 1];
    console.log("data aritcle: ", data);
    data.response.contents.length > 0
      ? this.setState({
          loading: false,
          id: article.id,
          title: article.title,
          author: article.author,
          content: article.content,
          image: article.imageUrl,
        })
      : this.setState({
          loading: false,
        });
  };

  onFailed = (response) => {
    console.log(response);
    alert("Failed response");
  };

  handleEditMode = (flag) => {
    flag
      ? this.setState({ editMode: true, readMode: false, addMode: false })
      : this.setState({ editMode: false, readMode: true, addMode: false });
  };

  handleDeleteArticle = (id) => {
    let confirm = window.confirm(
      "Are you sure you want to delete this article?"
    );
    confirm ? this.DeleteArticle(id) : null;
  };

  DeleteArticle = (articleId) => {
    DeleteData(
      this.props.HerokuHost + `/contents/article/${articleId}`,
      200,
      this.props.token,
      (data) => {
        if (data.responseCode === "Success") {
          alert("Article deleted Successfully!");
          this.getArticle(this.props.date);
        } else {
          alert("problem occured while deleting the article");
        }
      },
      this.onFailed
    );
  };

  handleAddMode = (flag) => {
    flag
      ? this.setState({ editMode: false, readMode: false, addMode: true })
      : this.setState({ editMode: false, readMode: true, addMode: false });
  };

  render() {
    return this.state.loading ? (
      <div className="journal-loader-wrraper">
        <TransverseLoading color="#225b76" size="large" />
      </div>
    ) : (
      <Slide left>
      <>
        {this.state.readMode ? (
          <ReadArticle
            id={this.state.id}
            author={this.state.author}
            title={this.state.title}
            content={this.state.content}
            image={this.state.image}
            handleAdd={(flag) => this.handleAddMode(flag)}
            handleEdit={(flag) => this.handleEditMode(flag)}
            hanldeDelete={(id) => {
              this.handleDeleteArticle(id);
            }}
          />
        ) : this.state.addMode ? (
          <AddArticle
            handleAdd={(flag) => this.handleAddMode(flag)}
            handleRefresh={() => this.getArticle(this.props.date)}
          />
        ) : this.state.editMode ? (
          <EditArticle
            id={this.state.id}
            author={this.state.author}
            title={this.state.title}
            content={this.state.content}
            image={this.state.image}
            handleEdit={(flag) => this.handleEditMode(flag)}
            handleRefresh={() => this.getArticle(this.props.date)}
          />
        ) : null}
      </>
      </Slide>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    HerokuHost: state.setHerokuHost,
    token: state.setUser.token,
    date: state.setDate.date,
  };
};

export default connect(mapStateToProps, null)(GoDeeper);
