import React, { Component } from "react";
import ListenComp from "./listen";
import AddAudio from "./AddAudio";

class MainListen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addState: false,
    };
  }

  handleAddState = (state) => {
    this.setState({ addState: state });
  };
  render() {
    return (
      <>
        {this.state.addState ? (
          <AddAudio handleAddState={this.handleAddState} />
        ) : (
          <ListenComp handleAddState={this.handleAddState} />
        )}
      </>
    );
  }
}

export default MainListen;
