import React, { Component } from 'react';
import SwipeableViews from "react-swipeable-views";
import GroupChatComp from "./GroupChat";
import GroupChatSettingsComp from "./GroupChatSettings";
import AddMemberComp from "./AddMembers";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import GetData from "../fetchDataGet";
import logo from "../../assets/TheFamilyWinsLogo.png";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}>
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };




class GroupChatContainer extends Component {
  constructor(props) {
      super(props);
      this.state = { 
          GC_swipeIndex: 0,
          activeGroup:null,
        }
  }

  componentDidUpdate(prevProps)
  {
    if(this.props.activeGroup!=null)
    {
      if(prevProps.activeGroup==null)
      {
        this.getActiveThread();
      }

      else if (this.props.activeGroup.groupId!==prevProps.activeGroup.groupId)
      {
          this.getActiveThread();
      }
    }

    else if(this.props.activeGroup==null)
    {
      if(prevProps.activeGroup!=null)
      {
        this.setState({activeGroup:null})
      }
    }

    if(this.props.newMessage!=null)
    {
      if(this.props.activeGroup!=null)
      {
        if(this.props.activeGroup.groupId.toString()===this.props.newMessage.groupId.toString())
        {
          let activeGroup=this.state.activeGroup;
          if(activeGroup.thread)
          {
            activeGroup.thread.push(this.props.newMessage);
          }

          else activeGroup['thread']=[this.props.newMessage];
          this.setState({activeGroup});
        }
      }
      
      this.props.setNewMessage();
    }
  }

  getActiveThread = () => {
      console.log('loading thread')
      this.setState({chatLoader:true,activeGroup:null})
      GetData(
      this.props.HerokuHost + `/account/group/${this.props.activeGroup.groupId}/thread`,
      200,
      this.props.token,
      this.setActiveThread,
      this.onFailed
      );
  };


  setThread = (thread) =>
  {
    let activeGroup=this.state.activeGroup;
    activeGroup.thread=thread;
    this.setState({activeGroup});
  }

  setActiveThread = (response) =>
  {
      console.log(response)
      if(response.responseCode==="Success")
      {
      this.setState({activeGroup:response.response})
      this.props.scrollToBottom(); 
      }

      else if((response.responseCode==="Failed") || (response.responseCode==="Error"))
      {
      console.log('Error on getting thread => ',response)
      alert('Error => ', response.validationErrorsList[0].errorMessage)
      }

      else 
      {
          console.log('Error on getting thread => ',response)
          alert('Something went wrong') 
      }
      this.setState({chatLoader:false})

  }

  GC_Swipe = (value) => {
      this.setState({ GC_swipeIndex: value });
      this.props.scrollToBottom(); 
  
    };

    ExitGroup = () =>
    {
      this.setState({activeGroup:null,GC_swipeIndex:0})
      this.props.ExitGroup();
    }
  render() { 
    return ( 
      <Grid item xs={12} sm={12} md={7} className="SG-GroupChat-Container">
        {this.state.activeGroup==null ?
          this.state.chatLoader === true ? 
            ( //loader groupchat component
              <div className="SG-GroupChat-Group-Loader">
                <img src={logo} className="SG-GroupChat-Group-Loader-Image hvr-back-pulse" alt="img" />
                <div className="SG-GroupChat-Group-Loader-Text">
                  {" "}
                  Family Wins, Loading Your Companions messages...{" "}
                </div>
              </div>
            )
          : this.props.groupsLength < 1 ? ( //disable groupchat component due to no group
            this.props.groupLoader === true ? (
              <div className="SG-GroupChat-Group-Loader">
                <img src={logo} className="SG-GroupChat-Group-Loader-Image hvr-back-pulse" alt="img" />
                <div className="SG-GroupChat-Group-Loader-Text"> Family Wins, Loading Groups...</div>
              </div>
            ) : (
              <div className="SG-GroupChat-Group-Loader">
                <img src={logo} className="SG-GroupChat-Group-Loader-Image" alt="img" />
                <div className="SG-GroupChat-Group-Loader-Text">
                  {" "}
                  Family Wins, Create a Group and start a healthy chat...
                </div>
              </div>
            )
          ) : (
          <div className="SG-GroupChat-Group-Loader">
            <img src={logo} className="SG-GroupChat-Group-Loader-Image" alt="img" />
            <div className="SG-GroupChat-Group-Loader-Text">
              {" "}
              Family Wins, Select a group to resume your chat...{" "}
            </div>
          </div>
        ) : (
          <SwipeableViews index={this.state.GC_swipeIndex}>
            <TabPanel
              value={this.state.GC_swipeIndex}
              index={0}
              dir={"ltr"}
            >
              <GroupChatComp swipe={this.GC_Swipe} setThread={(thread)=>{this.setThread(thread)}} activeGroup={this.state.activeGroup} thread={this.state.activeThread} />
            </TabPanel>

            <TabPanel value={this.state.GC_swipeIndex} index={1} dir={"ltr"}>
              <GroupChatSettingsComp ExitGroup={this.ExitGroup} thread={this.state.activeGroup} activeGroupId={this.props.activeGroup ? this.props.activeGroup.groupId :null } swipe={this.GC_Swipe} />
            </TabPanel>

            <TabPanel value={this.state.GC_swipeIndex} index={2} dir={"ltr"}>
              <AddMemberComp activeGroup={this.props.activeGroup} swipe={this.GC_Swipe} />
            </TabPanel>
          </SwipeableViews>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      HerokuHost: state.setHerokuHost,
      token: state.setUser.token,
    };
  };
  
export default connect(mapStateToProps, {})(GroupChatContainer);