import React from "react";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import { Button } from "@material-ui/core";
import "./trackProgress.css";
import PutData from "../fetchDataPut";
import { connect } from "react-redux";
import { setProgress } from "../../store/actions";



const EndProgressModal = (props) => {

  const [loading, setLoading] = React.useState(false);
  
  const Restart = () =>
  {
    setLoading(true)
    PutData(props.HerokuHost+'/account/challenge/restart',{},200,props.token,setRestart,onFailed)
  }

  const setRestart = (response) =>
  {
    // console.log(response);
    if(response.responseCode==="Success")
    {
      props.setCompleted(0)
      props.onClose();
    }

    else
    {
      alert('Something Went Wrong, Please try again');
      console.log('error on restart response => ',response)
    }
    setLoading(false)
    
  }

  
  const onFailed = (response) => {
    console.log("error on restart progress => ", response);
    alert("Restart Req Failed!, Please Try Again Later");
  };


  return (
    <Rodal visible={props.open} onClose={()=>props.onClose()}>
      <div className="EndProgressModal-container">
        <div className="EndProgressModal-text">
          No problem, we understand <br /> things come up. What would you <br />
          like to do?
        </div>
        <Button id="EndProgressModal-continueBtn" variant="contained" onClick={()=>props.onClose()}>
          Continue the Challenge
        </Button>
        <Button id="EndProgressModal-restartBtn" variant="contained" onClick={Restart}>
          Restart the Challenge
        </Button>
      </div>
    </Rodal>
  );
};

const mapStateToProps = (state) => {
  return {
    HerokuHost: state.setHerokuHost,
    token: state.setUser.token,
  };
};

export default connect(mapStateToProps, {setProgress})(EndProgressModal);