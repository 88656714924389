import React, { Component } from "react";
import { Button, Grid, RadioGroup, FormControlLabel, Radio, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { compose } from "redux";
import PostData from "../../user/fetchDataPost";
import PutData from "../../user/fetchDataPut";
import { setUser } from "../../store/actions";
import { TransverseLoading } from "react-loadingg";
import AvatarEditor from "react-avatar-editor";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Slide from "react-reveal/Slide";

import Modal from "@material-ui/core/Modal";

import "../../user/EditProfile/EditProfile.scss";

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageFile: "",
      loadedImage: "",
      name: this.props.user.name,
      email: this.props.user.userName,
      password: this.props.user.password,
      birthDate: this.props.user.birthDate.split("T")[0],
      address: this.props.user.address,
      notificationFrequency: this.props.user.notificationFrequency,
      privacyLevel: this.props.user.privacyLevel,
      disabled: true,
      changesShow: false,
      backdrop: false,
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.id || e.target.name]: e.target.value, disabled: false, changesShow: false });
  };

  handleImageChnage = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.addEventListener("load", () => {
        this.setState({ loadedImage: reader.result, imageFile: file });
      });
    }
  };

  updateProfile = () => {
    this.setState({ backdrop: true });
    const UpdatedUser = {
      userId: this.props.user.userId,
      userName: this.state.email,
      password: this.state.password,
      name: this.state.name,
      photoUrl: this.props.user.photoUrl,
      privacyLevel: this.state.privacyLevel,
      address: this.state.address,
      notificationFrequency: this.state.notificationFrequency,
      birthDate: this.state.birthDate,
    };
    PutData(
      this.props.HerokuHost + "/account/save",
      UpdatedUser,
      200,
      this.props.user.token,
      (data) => {
        this.props.setUser({
          ...data.response.user,
          token: this.props.user.token,
        });
        this.setState({
          disabled: true,
          backdrop: false,
          changesShow: "Success",
        });
      },
      this.onFailed
    );
  };

  updateImage = (newPhotoUrl) => {
    const UpdatedUser = {
      userId: this.props.user.userId,
      photoUrl: newPhotoUrl ? newPhotoUrl : this.props.user.photoUrl,
      userName: this.props.user.email,
      password: this.props.user.password,
      name: this.props.user.name,
      privacyLevel: this.props.user.privacyLevel,
      address: this.props.user.address,
      notificationFrequency: this.props.user.notificationFrequency,
      birthDate: this.props.user.birthDate,
    };
    PutData(
      this.props.HerokuHost + "/account/save",
      UpdatedUser,
      200,
      this.props.user.token,
      (data) => {
        this.props.setUser({
          ...data.response.user,
          token: this.props.user.token,
        });
        this.setState({
          loadedImage: "",
          photoUrl: newPhotoUrl ? newPhotoUrl : this.state.photoUrl,
          backdrop: false,
          changesShow: "ImageSuccess",
        });
      },
      this.onImageFailed
    );
  };

  onImageFailed = (response) => {
    console.log(response);
    this.setState({ backdrop: false, changesShow: "ImageError" });
  };

  UploadDP = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    this.setState({ backdrop: true });
    let fd = new FormData();
    fd.append("file", file);
    await PostData(
      this.props.HerokuHost + "/account/upload/photo",
      fd,
      200,
      this.props.user.token,
      (data) => {
        this.updateImage(data.response.url);
      },
      this.onFailed,
      true
    );
  };

  onFailed = (response) => {
    console.log(response);
    this.setState({ loading: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <Slide left>
        <div className="EP-Wrapper">
          <Grid container direction="row" className="EP-Content">
            <Grid item xs={12} sm={12} md={6} lg={6} className="EP-FormContainer">
              <InputElement label="Name" value={this.state.name} type="text" id="name" onChange={this.handleChange} />
              <InputElement
                label="Email"
                value={this.state.email}
                type="email"
                id="email"
                disabled={true}
                onChange={this.handleChange}
              />
              <InputElement
                label="Password"
                value={this.state.password}
                type="password"
                id="password"
                onChange={this.handleChange}
                disabled={true}
              />
              <InputElement
                label="Date of Birth"
                value={this.state.birthDate}
                type="date"
                id="birthDate"
                onChange={this.handleChange}
              />
              <InputElement
                label="City, State"
                value={this.state.cityState}
                type="text"
                id="address"
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="EP-OptionsContainer">
              <div className="EP-DPContainer">
                <div className="EP-DPContainer1">
                  <div className="EP-changeDPText">Change Profile Picture</div>
                  <label class="custom-file-upload">
                    <input type="file" name="image" accept="image/*" onChange={this.UploadDP} />
                    UPLOAD
                  </label>
                </div>
              </div>
              <div className="EP-OptContainer1">
                <div className="EP-HeadingQuestion">How often do you want a reminder to use The Family Wins app?</div>
                <div className="EP-RadioGroup">
                  <RadioGroup
                    aria-label="notificationFrequency"
                    name="notificationFrequency"
                    value={this.state.notificationFrequency}
                    onChange={this.handleChange}>
                    <FormControlLabel
                      value="Daily"
                      control={
                        <Radio
                          classes={{
                            root: classes.radioRoot,
                            checked: classes.radioChecked,
                          }}
                          checked={this.state.notificationFrequency === "Daily" && true}
                        />
                      }
                      label="once a day"
                    />
                    <FormControlLabel
                      value="Weekly"
                      control={
                        <Radio
                          classes={{
                            root: classes.radioRoot,
                            checked: classes.radioChecked,
                          }}
                          checked={this.state.notificationFrequency === "Weekly" && true}
                        />
                      }
                      label="once a week"
                    />
                    <FormControlLabel
                      value="Monthly"
                      control={
                        <Radio
                          classes={{
                            root: classes.radioRoot,
                            checked: classes.radioChecked,
                          }}
                          checked={this.state.notificationFrequency === "Monthly" && true}
                        />
                      }
                      label="once a month"
                    />
                    <FormControlLabel
                      value="Never"
                      control={
                        <Radio
                          classes={{
                            root: classes.radioRoot,
                            checked: classes.radioChecked,
                          }}
                          checked={this.state.notificationFrequency === "Never" && true}
                        />
                      }
                      label="never"
                    />
                  </RadioGroup>
                </div>
              </div>
            </Grid>
          </Grid>
          <div style={{ display: "inline-block" }}>
            <Button
              id="EP-SaveButton"
              variant="contained"
              onClick={() => this.updateProfile(null)}
              style={{ background: this.state.disabled ? "rgb(228, 88, 1,0.5)" : "rgb(228, 88, 1,1)" }}
              disabled={this.state.disabled}>
              {/* {this.state.loading ? (
              <TransverseLoading color="#ffffff" size="large" />
            ) : ( */}
              Save Changes
              {/* )} */}
            </Button>
          </div>

          {this.state.changesShow === "Success" ? (
            <div className={classes.changeSaved}>Changes Saved Successfully.</div>
          ) : null}
          {this.state.changesShow === "Error" ? (
            <div className={classes.changeSaved}>Error Changing Profile Data! Please try again later.</div>
          ) : null}
          {this.state.changesShow === "ImageSuccess" ? (
            <div className={classes.changeSaved}>Image Uploaded Successfully.</div>
          ) : null}

          {this.state.changesShow === "ImageError" ? (
            <div className={classes.changeSaved}>Error Changing Profile Picture! Please try again later.</div>
          ) : null}

          <Modal
            open={this.state.modalOpen}
            aria-labelledby="avatar-edit-modal"
            // aria-describedby="simple-modal-description"
          >
            {this.state.loadedImage ? (
              <div className="EP-DPContainer2">
                <AvatarEditor
                  ref={(node) => (this.avatarEditor = node)}
                  image={this.state.loadedImage}
                  width={250}
                  height={250}
                  border={50}
                  borderRadius={120}
                  color={[255, 255, 255, 0.6]} // RGBA
                  scale={1}
                  rotate={0}
                />
              </div>
            ) : null}
          </Modal>
          <Backdrop className={classes.backdrop} open={this.state.backdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      </Slide>
    );
  }
}

const style = (theme) => ({
  radioRoot: {
    color: "#989898",
  },
  radioChecked: {
    color: "#989898 !important",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  changeSaved: {
    color: "#E25601",
    display: "inline-block",
    marginLeft: "10px",
  },
});

export const InputElement = (props) => {
  return (
    <div className="Ep-InputContainer">
      <input value={props.value} className="EP-Input" disabled={props.disabled} type={props.type} id={props.id} onChange={props.onChange} />
      <div className="EP-InputLabel">{props.label}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    HerokuHost: state.setHerokuHost,
    user: state.setUser,
    date: state.setDate.date,
  };
};

export default compose(connect(mapStateToProps, { setUser }), withStyles(style))(EditProfile);
