import React from "react";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import { Button } from "@material-ui/core";
// import "./trackProgress.css";
import { connect } from "react-redux";
import { setUser } from "../../store/actions";
import PostData from "../../user/fetchDataPost";
import PutData from "../../user/fetchDataPut";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import { makeStyles } from '@material-ui/core/styles';
import GetData from "../../user/fetchDataGet";

const useStyles = makeStyles ({
    backdrop: {
      zIndex: 5,
      color: "#fff",
    },
  });






const ForgetModal = (props) => {

  const [loading, setLoading] = React.useState(false);
  const [step, setStep] = React.useState(1);

  
  const Restart = () =>
  {
    setLoading(true)
    let date = new Date().toLocaleDateString().replace(/[/]/g,"-")
    PutData(props.HerokuHost+`/account/challenge/end/${date}`,{},200,props.token,setRestart,onFailed)
  }

  const setRestart = (response) =>
  {
    // console.log(response);
    if(response.responseCode==="Success")
    {

      let NewUser =  props.user;
      NewUser['challenge'] = response.response.challenge;

      let progress = props.progress;
      progress['completed']=null;
      progress['total']=null;

      NewUser['progress'] = progress;
      props.setUser(NewUser)          
      setCookie('user',JSON.stringify(NewUser),30);
      setLoading(false)
      setStep(1);
      props.onClose('restart'); 
    }

    else
    {
      alert('Something Went Wrong, Please try again');
      console.log('error on restart response => ',response)
    }
    setLoading(false)
    
  }

  
  const onFailed = (response) => {
    console.log("error on restart progress => ", response);
    alert("Restart Req Failed!, Please Try Again Later");
  };

  const Continue = () =>
  {
    setLoading(true)
    PostData(props.HerokuHost+`/account/challenge/progress/${props.date}/continue`,{},200,props.token,PostTodayDateFromConitnue,onFailed)
  }

  // const setContinue = (response) =>
  // {
  //   console.log(response);
  //   if(response.responseCode==="Success")
  //   {
  //     setStep(1);
  //     props.onClose('continue');
  //   }

  //   else
  //   {
  //     alert('Something Went Wrong, Please try again');
  //     console.log('error on restart response => ',response)
  //   }
  //   setLoading(false)
  // }

  const newStep = ( ) =>
  {
    setStep(2);
  }

  const Skip = ( ) =>
  {
    setLoading(true)
    let yesterdayDate = new Date(props.date);
    // console.log('today: ',yesterdayDate)
    yesterdayDate.setDate(yesterdayDate.getDate() - 1);
    let yesterDateString = yesterdayDate.toLocaleDateString().replace(/[/]/g,'-');
    // console.log('posting yesterday...',yesterDateString );

    PostData(props.HerokuHost+`/account/challenge/progress/${yesterDateString}/continue`,{},200,props.token,PostYesterday,onFailed)
  }


  const PostYesterday = (response) =>
  {
    // console.log('continue api response ',response)
    if (response.response)
    {
      let progress = response.response.current;
        let NewUser = props.user;
        NewUser['lastActivityOn']=response.response.lastActivityOn;
        NewUser['progress']=progress;
        props.setUser(NewUser)
        setCookie('user',JSON.stringify(NewUser),30) 

        let yesterdayDate = new Date(props.date);
        // console.log('today: ',yesterdayDate)
        yesterdayDate.setDate(yesterdayDate.getDate() - 1);
        let yesterDateString = yesterdayDate.toLocaleDateString().replace(/[/]/g,'-');
        // console.log('posting yesterday...',yesterDateString );
        PostData(props.HerokuHost + `​​/account/challenge/progress/${yesterDateString}`,{},200,props.token,PostTodayDate,onFailed);

    }
    else if (response.validationErrorsList.length > 0)
    {
      setLoading(false)
      alert(response.validationErrorsList[0].errorMessage);
      console.log("validation error response on header => ", response);
    }
    else
    {
      setLoading(false)
      alert("Unknown error occured!");
      console.log("unknown error response on header => ", response);
    }
  }


  const PostTodayDateFromConitnue = (response) =>
  {
    // console.log('continue api response ',response)
    if (response.response)
    {
      let progress = response.response.current;
        let NewUser = props.user;
        NewUser['lastActivityOn']=response.response.lastActivityOn;
        NewUser['progress']=progress;
        props.setUser(NewUser)
        setCookie('user',JSON.stringify(NewUser),30) 
        PostData(props.HerokuHost + `​​/account/challenge/progress/${props.date}`,{},200,props.token,setSkip,onFailed);

    }
    else if (response.validationErrorsList.length > 0)
    {
      setLoading(false)
      alert(response.validationErrorsList[0].errorMessage);
      console.log("validation error response on header => ", response);
    }
    else
    {
      setLoading(false)
      alert("Unknown error occured!");
      console.log("unknown error response on header => ", response);
    }


  }


  const PostTodayDate = (response) =>
  {
    // console.log('post yesterday api response =>', response)
    if (response.response) {
      let progress = response.response.progress;
      if(progress)
      {
        let progressDate=progress.progressDate;  
        let Newuser = props.user;
        Newuser['lastActivityOn']=progressDate;
        Newuser['progress']=progress;
        props.setUser(Newuser)
        setCookie('user',JSON.stringify(Newuser),30)
        PostData(props.HerokuHost + `​​/account/challenge/progress/${props.date}`,{},200,props.token,setSkip,onFailed);

      } 

      else {
        setLoading(false)
      }
     
     
    }
    else if (response.validationErrorsList.length > 0)
    {
      setLoading(false)
      alert(response.validationErrorsList[0].errorMessage);
      console.log("validation error response on header => ", response);
    }
    else
    {
      setLoading(false)
      alert("Unknown error occured!");
      console.log("unknown error response on header => ", response);
    }


  }

  const setSkip = (response) =>
  {
    // console.log(response);
    if (response.response) {
      let progress = response.response.progress;
      if(progress)
      {
        let progressDate=progress.progressDate;  
        let Newuser = props.user;
        Newuser['lastActivityOn']=progressDate;
        Newuser['progress']=progress;
        props.setUser(Newuser)
        setCookie('user',JSON.stringify(Newuser),30)
        setLoading(false)
        setStep(1);
        props.onClose();

      } 

      else {
        setLoading(false)
      }
     
     
    }
    else if (response.validationErrorsList.length > 0)
    {
      setLoading(false)
      alert(response.validationErrorsList[0].errorMessage);
      console.log("validation error response on header => ", response);
    }
    else
    {
      setLoading(false)
      alert("Unknown error occured!");
      console.log("unknown error response on header => ", response);
    }
  }

  const setCookie = (cname, cvalue, exmins) => {
    var d = new Date();
    d.setMinutes(d.getMinutes() + exmins);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  

  const classes = useStyles();


  return (
    <Rodal visible={props.open} onClose={()=> {setStep(1); props.onClose() }}>

        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>

    {
        step===1?
        <div className="EndProgressModal-container">
            <div className="EndProgressModal-text">
                Hey you forget to record your  <br /> results yesterday. <br /> <br/>
                Did you complete your 15 mins  <br /> yesterday?
            </div>
            <Button id="EndProgressModal-continueBtn" variant="contained" onClick={Skip}>
              YES, I DID
            </Button>
            <Button id="EndProgressModal-restartBtn" variant="contained" onClick={newStep}>
              NO, I DIDN'T
            </Button>
        </div>
        :step==2?
            <div className="EndProgressModal-container">
                <div className="EndProgressModal-text">
                  No problem, we understand <br /> things come up. What would you <br />
                  like to do?
                </div>
                <Button id="EndProgressModal-continueBtn" variant="contained" onClick={Continue}>
                  Continue the Challenge
                </Button>
                <Button id="EndProgressModal-restartBtn" variant="contained" onClick={Restart}>
                  Restart the Challenge
                </Button>
            </div>
        :null

    }
      
    </Rodal>
  );
};

const mapStateToProps = (state) => {
  return {
    HerokuHost: state.setHerokuHost,
    progress:state.setUser.progress,
    token: state.setUser.token,
    date: state.setDate.date,
    user: state.setUser
  };
};

export default connect(mapStateToProps, {setUser})(ForgetModal);