import React from "react";
import ReactPlayer from "react-player/lazy";
import ShowMoreText from "react-show-more-text";
import Spotify from "../../assets/spotify.png";
import { CardActionArea, CardContent, CardMedia, Typography, Card, makeStyles } from "@material-ui/core";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import IconButton from "@material-ui/core/IconButton";

import "./Worship.scss";

const useStyles = makeStyles({
  root: {
    maxWidth: 310,
    minWidth: 310,
    minHeight: 290,
    margin: "12px",
    borderRadius:'0px',
    background:'#fffefe'
  },
  cardContentRoot: {
    padding: 0,
    paddingTop: 10,
  },
  heading: {
    fontSize: "1.15rem",
    fontWeight: "bold",
    paddingTop:'15px',
    paddingLeft:'10px',
  },
  SpotifyIcon: {
    width: "50px",
  },
});

const VideoCard2 = (props) => {
  const classes = useStyles();
  const {video,isFavourite,handleToggleFavourite} = props;

  const handleCardClick = () => {
    props.openDialoge();
  };

  return (
    <Card className={classes.root} elevation={1}>
      <CardActionArea onClick={handleCardClick}>
        <CardMedia
          component={() => <ReactPlayer url={video?.url} light controls width="100%" height={168.75} />}
          alt="Video"
          title="Watch video"
        />
        {
          !isFavourite  &&
          <div id="favourite-icon-div">
            <IconButton aria-label="delete">
              {
                video?.isFavourite ?
                <FavoriteIcon style={{color:"#225b76"}} onClick={(e)=>handleToggleFavourite(e,video.id, video?.isFavourite)} /> :
                <FavoriteBorderIcon style={{color:"#225b76"}} onClick={(e)=>handleToggleFavourite(e,video.id,video?.isFavourite)}/>
              }
            </IconButton>
          </div>
        }

      </CardActionArea>
      <CardContent classes={{ root: classes.cardContentRoot }}>
        <div className="Wor-Video-spotifyIcon-div">
          <div className="Wor-Video-spotifyIcon-btn" onClick={() => window.open(video?.spotifyLink)}>
            <img src={Spotify} className="Wor-Video-spotifyIcon" alt="spotify" />
            <span className="Wor-Video-spotifyIcon-text">Listen On Spotify</span>
          </div>
        </div>
        <Typography classes={{ root: classes.heading }} variant="h6" component="h1">
          {video?.title}
        </Typography>
        <Typography style={{ paddingLeft:'10px'}} variant="body2" color="textPrimary" component="div">
          <ShowMoreText lines={6} width={300} expanded={false}>
            {video?.description}
          </ShowMoreText>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default VideoCard2;
