import React, { Component } from "react";
import { Button, Grid, RadioGroup, FormControlLabel, Radio, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { compose } from "redux";

import AvatarEditor from "react-avatar-editor";
import PostData from "../fetchDataPost";
import { setUser } from "../../store/actions";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./EditProfile.scss";

import PutData from "../fetchDataPut";
import Slide from "react-reveal/Slide";

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageFile: "",
      loadedImage: "",
      name: this.props.user.name,
      email: this.props.user.userName,
      password: this.props.user.password,
      birthDate: this.props.user.birthDate,
      address: this.props.user.address,
      notificationFrequency: this.props.user.notificationFrequency,
      privacyLevel: this.props.user.privacyLevel,
      disabled: true,
      changesShow: false,
      backdrop: false,
    };
  }

  componentDidMount() {
    this.setValues();
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id || e.target.name]: e.target.value,
      disabled: false,
      changesShow: false,
    });
  };

  // SaveChange = () => {
  //   let body = {
  //     name: this.state.name,
  //     userName: this.state.email,
  //     birthDate: this.state.birthDate,
  //     address: this.state.address,
  //     notificationFrequency: this.state.notificationFrequency,
  //     privacyLevel: this.state.privacyLevel,
  //   };

  //   PutData(
  //     this.props.HerokuHost + "/account/save",
  //     JSON.stringify(body),
  //     200,
  //     this.props.user.token,
  //     this.updateStore,
  //     this.setValues
  //   );
  // };

  setValues = (response = null) => {
    if (response != null) {
      alert("Error Updating Values, reverting values.");
    }
    let ibd, birthDate;
    ibd = this.props.user.birthDate.split("T");
    birthDate = ibd[0];
    // console.log("Birthday: ", birthDate);

    this.setState({
      name: this.props.user.name,
      email: this.props.user.userName,
      birthDate,
      address: this.props.user.address,
      notificationFrequency: this.props.user.notificationFrequency,
      privacyLevel: "Public",
      loading: false,
    });
  };

  updateStore = () => {
    this.props.setUser(
      this.props.user.userType,
      this.state.name,
      this.props.user.token,
      this.props.user.userId,
      this.state.email,
      this.state.birthDate,
      this.props.user.photoUrl,
      this.props.user.progress,
      this.state.notificationFrequency,
      this.state.address,
      this.state.privacyLevel
    );
  };

  // handleImageChnage = (event) => {
  //   const file = event.target.files[0];
  //   console.log("file ayi hai :", file);
  //   const reader = new FileReader();
  //   if (file) {
  //     reader.readAsDataURL(file);
  //     reader.addEventListener("load", () => {
  //       this.setState({ loadedImage: reader.result, imageFile: file });
  //     });
  //   }
  // };

  UploadDP = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    let fd = new FormData();
    fd.append("file", file);
    this.setState({ backdrop: true });

    await PostData(
      this.props.HerokuHost + "/account/upload/photo",
      fd,
      200,
      this.props.token,
      (data) => {
        // console.log(data.response.url);
        this.updateProfile(data.response.url);
      },
      this.onFailed,
      true
    );
  };

  updateProfile = (newPhotoUrl) => {
    this.setState({ backdrop: true });
    const UpdatedUser = {
      userId: this.props.user.userId,
      userName: this.state.email,
      password: this.state.password,
      name: this.state.name,
      photoUrl: newPhotoUrl ? newPhotoUrl : this.props.user.photoUrl,
      // progress: this.props.user.progress,
      // memberType: this.props.user.memberType,
      // nickName: this.props.user.nickName,
      privacyLevel: this.state.privacyLevel,
      address: this.state.address,
      notificationFrequency: this.state.notificationFrequency,
      birthDate: this.state.birthDate,
      // lastLogin: this.props.user.lastLogin,
      // registeredOn: this.props.user.registeredOn,
    };
    PutData(
      this.props.HerokuHost + "/account/save",
      UpdatedUser,
      200,
      this.props.user.token,
      (data) => {
        this.props.setUser({
          ...data.response.user,
          token: this.props.user.token,
        });
        this.setState({
          loadedImage: "",
          disabled: true,
          backdrop: false,
          changesShow: "Success",
        });
        // alert("Changes Saved!");
      },
      this.onFailed
    );
  };

  onFailed = (response) => {
    console.log(response);
    alert("Failed response");
    this.setState({ backdrop: false, changesShow: "Error" });
  };

  render() {
    const { classes } = this.props;
    return (
      <Slide left>
        <div className="EP-Wrapper">
          <Grid container direction="row" className="EP-Content">
            <Grid item xs={12} sm={12} md={6} lg={6} className="EP-FormContainer">
              <InputElement label="Name" value={this.state.name} type="text" id="name" onChange={this.handleChange} />
              <InputElement
                label="Email"
                value={this.state.email}
                type="email"
                id="email"
                onChange={this.handleChange}
                disabled={true}
              />
              <InputElement
                label="Password"
                value={this.state.password ? this.state.password : null}
                type="password"
                id="password"
                onChange={this.handleChange}
                disabled={true}
              />
              <InputElement
                label="Date of Birth"
                value={this.state.birthDate}
                type="date"
                id="birthDate"
                onChange={this.handleChange}
              />
              <InputElement
                label="City, State"
                value={this.state.address}
                type="text"
                id="address"
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} className="EP-OptionsContainer">
              <div className="EP-DPContainer">
                <div className="EP-DPContainer1">
                  <div className="EP-changeDPText">Change Profile Picture</div>
                  <label class="custom-file-upload">
                    <input type="file" name="image" accept="image/*" onChange={this.UploadDP} />
                    UPLOAD
                  </label>
                </div>
                {this.state.loadedImage ? (
                  <div className="EP-DPContainer2">
                    <AvatarEditor
                      ref={(node) => (this.avatarEditor = node)}
                      image={this.state.loadedImage}
                      width={250}
                      height={250}
                      border={50}
                      borderRadius={120}
                      color={[255, 255, 255, 0.6]} // RGBA
                      scale={1}
                      rotate={0}
                    />
                  </div>
                ) : null}
              </div>
              <div className="EP-OptContainer1">
                <div className="EP-HeadingQuestion">How often do you want a reminder to use The Family Wins app?</div>
                <div className="EP-RadioGroup">
                  <RadioGroup
                    aria-label="notificationFrequency"
                    name="notificationFrequency"
                    value={this.state.notificationFrequency}
                    onChange={this.handleChange}>
                    <FormControlLabel
                      value="Daily"
                      control={
                        <Radio
                          classes={{
                            root: classes.radioRoot,
                            checked: classes.radioChecked,
                          }}
                          checked={this.state.notificationFrequency === "Daily" && true}
                          onChange={() => this.setState({ notificationFrequency: "Daily" })}
                        />
                      }
                      label="once a day"
                    />
                    <FormControlLabel
                      value="Weekly"
                      control={
                        <Radio
                          classes={{
                            root: classes.radioRoot,
                            checked: classes.radioChecked,
                          }}
                          checked={this.state.notificationFrequency === "Weekly" && true}
                          onChange={() => this.setState({ notificationFrequency: "Weekly" })}
                        />
                      }
                      label="once a week"
                    />
                    <FormControlLabel
                      value="Monthly"
                      control={
                        <Radio
                          classes={{
                            root: classes.radioRoot,
                            checked: classes.radioChecked,
                          }}
                          checked={this.state.notificationFrequency === "Monthly" && true}
                          onChange={() => this.setState({ notificationFrequency: "Monthly" })}
                        />
                      }
                      label="once a month"
                    />
                    <FormControlLabel
                      value="Never"
                      control={
                        <Radio
                          classes={{
                            root: classes.radioRoot,
                            checked: classes.radioChecked,
                          }}
                          checked={this.state.notificationFrequency === "Never" && true}
                          onChange={() => this.setState({ notificationFrequency: "Never" })}
                        />
                      }
                      label="never"
                    />
                  </RadioGroup>
                </div>
              </div>
              <div className="Ep-OptContainer2">
                <div className="EP-HeadingQuestion">Set my profile progress to</div>
                <div className="EP-RadioGroup">
                  <RadioGroup
                    aria-label="Privacy Level"
                    name="privacy-level"
                    value={this.state.privacyLevel}
                    onChange={this.handleChange}>
                    <FormControlLabel
                      value="Public"
                      control={
                        <Radio
                          classes={{
                            root: classes.radioRoot,
                            checked: classes.radioChecked,
                          }}
                          checked={this.state.privacyLevel === "Public" && true}
                          onChange={() => this.setState({ privacyLevel: "Public" })}
                        />
                      }
                      label="Public (My companions can see)"
                    />
                    <FormControlLabel
                      value="Private"
                      control={
                        <Radio
                          classes={{
                            root: classes.radioRoot,
                            checked: classes.radioChecked,
                          }}
                          checked={this.state.privacyLevel === "Private" && true}
                          onChange={() => this.setState({ privacyLevel: "Private" })}
                        />
                      }
                      label="Private (Only i can see)"
                    />
                  </RadioGroup>
                </div>
              </div>
            </Grid>
          </Grid>
          <div>
            <Button
              id="EP-SaveButton"
              variant="contained"
              style={{ background: this.state.disabled ? "rgb(228, 88, 1,0.5)" : "rgb(228, 88, 1,1)" }}
              onClick={() => this.updateProfile(null)}
              disabled={this.state.disabled}>
              Save Changes
            </Button>

            {this.state.changesShow === "Success" ? (
              <div className={classes.changeSaved}>Changes Saved Successfully.</div>
            ) : null}

            {this.state.changesShow === "ImageSuccess" ? (
              <div className={classes.changeSaved}>Image Uploaded Successfully.</div>
            ) : null}

            {this.state.changesShow === "ImageError" ? (
              <div className={classes.changeSaved}>Error Changing Profile Picture! Please try again later.</div>
            ) : null}

            {this.state.changesShow === "Error" ? (
              <div className={classes.changeSaved}>Error Changing Profile Data! Please try again later.</div>
            ) : null}
            <Backdrop className={classes.backdrop} open={this.state.backdrop}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        </div>
      </Slide>
    );
  }
}

const style = (theme) => ({
  radioRoot: {
    color: "#989898",
  },
  radioChecked: {
    color: "#989898 !important",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  changeSaved: {
    color: "#E25601",
    display: "inline-block",
    marginLeft: "10px",

    // font-size
  },
});

export const InputElement = (props) => {
  return (
    <div className="Ep-InputContainer">
      <input value={props.value} className="EP-Input" disabled={props.disabled} type={props.type} id={props.id} onChange={props.onChange} />
      <div className="EP-InputLabel">{props.label}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    HerokuHost: state.setHerokuHost,
    user: state.setUser,
    date: state.setDate.date,
    token: state.setUser.token,
  };
};

export default compose(connect(mapStateToProps, { setUser }), withStyles(style))(EditProfile);
