import React from "react";
import ReactPlayer from "react-player/lazy";
import ShowMoreText from "react-show-more-text";
import Spotify from "../../assets/spotify.png";
import { CardActionArea, CardContent, CardMedia, Typography, Card, makeStyles } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from "react-redux";
import DeleteData from "../fetchDataDelete";


// import "./Worship.scss";

const useStyles = makeStyles({
  root: {
    maxWidth: 310,
    minWidth: 310,
    minHeight: 290,
    margin: "12px",
    borderRadius:'0px',
    background:'#fffefe',
  },
  cardContentRoot: {
    padding: 0,
    paddingTop: 8,
  },
  heading: {
    fontSize: "1.15rem",
    fontWeight: "bold",
    paddingTop:'15px',
    paddingLeft:'10px',
  },
  SpotifyIcon: {
    width: "50px",
  },
});

const VideoCard2 = (props) => {
  const classes = useStyles();
  const [loading, setloading] = React.useState(false);

  const handleCardClick = () => {
    if(loading===false)
    {
      props.openDialoge();
    }
  };

  const openSpotify = () => 
  {
    if(loading===false)
    {
      window.open(props.spotifyLink)
    } 
  }

  const Delete = () =>
  {
    if(loading===false)
    {
      setloading(true)
      DeleteData(props.HerokuHost+`/contents/worship/${props.id}`,200,props.token,Deleted,onFailed)
    }
  }

  const Deleted = (response) =>
  {
    if(response.responseCode==="Success")
    {
      props.handleDelete(props.id);
    }
    else
    {
      alert('error on delting video, please check console for more info!')
      console.log('error on deleting worship video => ',response);
    }
  }

  const onFailed = (response) => {
    console.log("error on delete worship video => ", response);
    alert("Deleting Video Failed!");
  };

  return (
    <Card className={classes.root} elevation={1} style={{opacity:loading===true?'0.5':'1',transition:'all 0.4s ease'}}>
      <CardActionArea onClick={handleCardClick}>
        <CardMedia
          component={() => <ReactPlayer url={props.link} light controls width="100%" height={168.75} on />}
          alt="Video"
          title="Watch video"
        />
      </CardActionArea>
      <CardContent classes={{ root: classes.cardContentRoot }}>
        <div className="Wor-Video-spotifyIcon-div">
          <div className="Wor-Video-spotifyIcon-btn" onClick={ openSpotify } >
            <img src={Spotify} className="Wor-Video-spotifyIcon" alt="spotify" />
            <span className="Wor-Video-spotifyIcon-text">Listen On Spotify</span>
          </div>
          <div className="Wor-Video-delete-btn">
            <DeleteIcon className="Wor-Video-delete-icon" onClick={Delete}/>
          </div>
        </div>
        <Typography classes={{ root: classes.heading }} variant="h6" component="h1">
          {props.title}
        </Typography>
        <Typography style={{ paddingLeft:'10px'}}  variant="body2" color="textPrimary" component="p">
          <ShowMoreText lines={6} width={300} expanded={false}>
            {props.desc}
          </ShowMoreText>
        </Typography>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    HerokuHost: state.setHerokuHost,
    token: state.setUser.token,
  };
};

export default connect(mapStateToProps,{})(VideoCard2);

