/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { Component } from "react";

import IconButton from "@material-ui/core/IconButton";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import { withStyles } from "@material-ui/core/styles";
import PauseIcon from "@material-ui/icons/Pause";
import Slider from "@material-ui/core/Slider";
import VolumeDown from "@material-ui/icons/VolumeDown";
import VolumeUp from "@material-ui/icons/VolumeUp";
import Grid from "@material-ui/core/Grid";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Slide from "react-reveal/Slide";
import ListenSkeleton from "../listen/ListenSkeleton";

import MusicIcon from "../../assets/music-icon.png";

import "../listen/listen.css";
import GetData from "../fetchDataGet";

import { compose } from "redux";
import { connect } from "react-redux";

var mounted = false;

const styles = (theme) => ({
  controls: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  playlistRoot: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
});

class ReadAndReflect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: null,
      play: false,
      volume: 100,
      disable: true,
      songName: "",
      song_mins: 0,
      song_seconds: 0,
      song_curr_mins: 0,
      song_curr_seconds: 0,
      songslist: [],
      activeSong: null,
      nextDisable: false,
      prevDisable: false,
      loading: true,
    };
  }

  componentWillUnmount() {
    let audioElement = document.getElementById("audio");
    audioElement.removeEventListener("canplaythrough", this.loadSong);
    audioElement.removeEventListener("timeupdate", this.updateTime);
    mounted = false;
  }

  componentDidUpdate(oldprops) {
    if (oldprops.date !== this.props.date) {
      document.getElementById("audio").src = "";
      this.getAudio(this.props.date);
    }
  }

  componentDidMount() {
    mounted = true;
    var audioElement = document.getElementById("audio");
    audioElement.addEventListener("canplaythrough", this.loadSong);

    audioElement.addEventListener("timeupdate", this.updateTime);
    this.getAudio(this.props.date);
  }

  loadSong = () => {
    let audioElement = document.getElementById("audio");
    const global = this;

    let duration = audioElement.duration;
    // console.log("duration: " + duration);
    let song_mins = Math.floor(duration / 60);
    let song_seconds = Math.round(duration - 60 * song_mins);
    global.setState({ duration, song_mins, song_seconds, disable: false });

    if (global.state.play === true) {
      audioElement.play();
    }
  };

  updateTime = (e) => {
    const global = this;

    let currentTime = e.target.currentTime;
    let song_curr_mins = Math.floor(currentTime / 60);
    let song_curr_seconds = Math.round(currentTime - 60 * song_curr_mins);

    let duration = e.target.duration - currentTime;
    let song_mins = Math.floor(duration / 60);
    let song_seconds = Math.round(duration - 60 * song_mins);

    if (duration === 0) {
      global.setState({ play: false });
    }

    global.setState({
      currentTime,
      song_curr_mins,
      song_curr_seconds,
      song_mins,
      song_seconds,
    });
  };

  getAudio = (date) => {
    this.setState({
      date,
      songslist: [],
      activeSong: null,
      disable: true,
      nextDisable: true,
      prevDisable: true,
      songName: "",
      song_mins: 0,
      song_seconds: 0,
      song_curr_mins: 0,
      song_curr_seconds: 0,
      loading: true,
    });
    GetData(
      this.props.HerokuHost + `/contents/speech/${date}`,
      200,
      this.props.user.token,
      this.setAudio,
      this.onFailed
    );
  };

  onFailed = (response) => {
    console.log(response);
    alert("Failed response");
  };

  setAudio = (response) => {
    this.setState({ loading: false });
    if (response.response) {
      // console.log(response.response);

      if (mounted === true) {
        var audioElement = document.getElementById("audio");

        let nextDisable = false;
        if (response.response.contents.length < 1) {
          nextDisable = true;
        }

        if (response.response.contents.length > 0) {
          audioElement.setAttribute("src", response.response.contents[0].url);
          audioElement.load();
        }

        this.setState({
          songslist: response.response.contents,
          activeSong: response.response.contents[0],
          disable: true,
          nextDisable,
          prevDisable: true,
        });
      }
    }
  };

  changeSong = (song) => {
    let currSongIndex = this.state.songslist.findIndex((e) => e.id === song.id);
    let nextDisable = false;
    let prevDisable = false;
    if (currSongIndex === this.state.songslist.length - 1) {
      nextDisable = true;
    }

    if (currSongIndex === 0) {
      prevDisable = true;
    }
    var audioElement = document.getElementById("audio");
    audioElement.setAttribute("src", song.url);
    audioElement.load();
    this.setState({
      activeSong: song,
      disable: true,
      nextDisable,
      prevDisable,
    });
  };

  Play = () => {
    if (this.state.play === false) {
      document.getElementById("audio").play();
      this.setState({ play: true });
    } else {
      document.getElementById("audio").pause();
      this.setState({ play: false });
    }
  };

  NextSong = () => {
    let currSongIndex = this.state.songslist.findIndex((e) => e.id === this.state.activeSong.id);
    let nextDisable = false;
    console.log(currSongIndex);
    if (currSongIndex + 1 === this.state.songslist.length - 1) {
      nextDisable = true;
    }
    var audioElement = document.getElementById("audio");
    audioElement.setAttribute("src", this.state.songslist[currSongIndex + 1].url);
    audioElement.load();
    this.setState({
      activeSong: this.state.songslist[currSongIndex + 1],
      disable: true,
      nextDisable,
      prevDisable: false,
    });
  };

  PrevSong = () => {
    let currSongIndex = this.state.songslist.findIndex((e) => e.id === this.state.activeSong.id);
    let prevDisable = false;
    if (currSongIndex - 1 == 0) {
      prevDisable = true;
    }

    var audioElement = document.getElementById("audio");
    audioElement.setAttribute("src", this.state.songslist[currSongIndex - 1].url);
    audioElement.load();
    this.setState({
      activeSong: this.state.songslist[currSongIndex - 1],
      disable: true,
      prevDisable,
      nextDisable: false,
    });
  };

  currentTime = (e) => {
    console.log(e);
    // document.getElementById('tracktime').innerHTML = Math.floor(this.currentTime) + ' / ' + Math.floor(this.duration);
  };

  handleChangeVolume = (e, value) => {
    this.setState({ volume: value });
    document.getElementById("audio").volume = value / 100;
  };

  handleChangeTime = (e, value) => {
    document.getElementById("audio").currentTime = (value * this.state.duration) / 100;
    // this.setState({currentTime:value})
  };
  render() {
    const { classes } = this.props;
    return (
      <Slide left>
        <div>
          <Grid container direction="row" spacing={2}>
            {/* AUDIO PLAYER */}

            <Grid item xs={12} sm={12} md={12} lg={5} xl={5} style={{ marginBottom: "15px", position: "relative" }}>
              {/* TIME LINE OF PLAYER */}
              <div>
                <Slider
                  disabled={this.state.disable}
                  value={(this.state.currentTime * 100) / this.state.duration}
                  onChange={this.handleChangeTime}
                  aria-labelledby="continuous-slider"
                />
                <span className="song-current-time-player">
                  {" "}
                  {this.state.song_curr_mins} :{" "}
                  <span className="song-fake-zero"> {this.state.song_curr_seconds < 10 ? 0 : null} </span>{" "}
                  {this.state.song_curr_seconds}{" "}
                </span>
                <span className="song-time-left-player">
                  {" "}
                  {isNaN(this.state.song_seconds) ? (
                    "-- : --"
                  ) : (
                    <span>
                      {" "}
                      - {this.state.song_mins} :{" "}
                      <span className="song-fake-zero"> {this.state.song_seconds < 10 ? 0 : null} </span>{" "}
                      {this.state.song_seconds}{" "}
                    </span>
                  )}{" "}
                </span>
              </div>
              {/* NAME OF SONG */}
              <div className="song-title-player">
                {this.state.activeSong != null ? this.state.activeSong.title : "-- --"}
              </div>

              <audio id="audio" type="audio/mp3" />
              <div className={classes.controls}>
                <IconButton aria-label="previous" onClick={this.PrevSong} disabled={this.state.prevDisable}>
                  <SkipPreviousIcon className="song-previous-icon-player" />
                </IconButton>
                <IconButton aria-label="play/pause" disabled={this.state.disable} onClick={this.Play}>
                  {this.state.play === false ? (
                    <PlayArrowIcon className={classes.playIcon} className="song-play-icon-player" />
                  ) : (
                    <PauseIcon className={classes.playIcon} className="song-pause-icon-player" />
                  )}
                </IconButton>
                <IconButton aria-label="next" onClick={this.NextSong} disabled={this.state.nextDisable}>
                  <SkipNextIcon className="song-skip-icon-player" />
                </IconButton>
              </div>

              <Grid container spacing={2}>
                <Grid item>
                  <VolumeDown />
                </Grid>
                <Grid item xs>
                  <Slider
                    value={this.state.volume}
                    onChange={this.handleChangeVolume}
                    aria-labelledby="continuous-slider"
                  />
                </Grid>
                <Grid item>
                  <VolumeUp />
                </Grid>
              </Grid>
            </Grid>

            {/**  PLAYLIST */}

            <Grid item xs={false} sm={false} md={false} lg={1} xl={1}></Grid>

            <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
              <List component="song-playlist" className={classes.playlistRoot} aria-label="mailbox folders">
                {this.state.loading ? (
                  <>
                    <ListenSkeleton />
                    <ListenSkeleton />
                    <ListenSkeleton />
                    <ListenSkeleton />
                  </>
                ) : (
                  this.state.songslist.map((song, key) => (
                    <div key={key}>
                      <ListItem button onClick={() => this.changeSong(song)} className="listen-item-wrapper">
                        <ListItemAvatar>
                          <Avatar>
                            <img src={song.thumbnail ? song.thumbnail : MusicIcon} alt={song.name} width="16px" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={song.title} />
                      </ListItem>
                      <Divider />
                    </div>
                  ))
                )}
              </List>
            </Grid>
          </Grid>
          {/* </Grid> */}
        </div>
      </Slide>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    HerokuHost: state.setHerokuHost,
    user: state.setUser,
    date: state.setDate.date,
  };
};

export default compose(connect(mapStateToProps, {}), withStyles(styles))(ReadAndReflect);
