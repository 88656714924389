import React, { Component } from "react";
import { Grid, withStyles } from "@material-ui/core";
import { animateScroll, Link } from "react-scroll";
import { connect } from "react-redux";
import "./StudyGroups.scss";
import Slide from "react-reveal/Slide";
import { HubConnectionBuilder } from '@microsoft/signalr';
import GroupsContainer from "./groupsContainer";
import GroupChatContainer from "./groupChatContainer";

class StudyGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupsLength:0,
      activeGroup:null,
      activeThread:[],
      connection:null,
      newMessageGroup:null,
      newMessageChat:null,
    };
  }
  componentDidMount() {
    const newConnection = new HubConnectionBuilder()
    .withUrl(this.props.HerokuHost+"/hub/group", { accessTokenFactory: () => this.props.token })
    .build();

    this.setState({connection:newConnection}, ()=>{ 
      console.log(this.state.connection)
      this.startConnection();
    })

  }

  startConnection = () =>
  {
    this.state.connection.start()
    .then(result => {
        console.log('Connected! ', result);
        this.state.connection.on('ReceiveMessage', (message) => {
          console.log('updated message ',message)
          if(this.state.activeGroup!=null)
          {
            if(message.groupId.toString() === this.state.activeGroup.groupId.toString())
            {
              message.hasChange=false;
            }

            else message.hasChange=true;
          }

          else message.hasChange=true;
          this.setState({newMessageGroup:message,newMessageChat:message})
        });

        this.state.connection.onclose(async () => {
          this.startConnection();
      });
    })
    .catch(e => console.log('Connection failed: ', e));
  }

  setNewMessageGroup = () =>
  {
    this.setState({newMessageGroup:null})
  }

  setNewMessageChat = () =>
  {
    this.setState({newMessageChat:null})
  }

  scrollToBottom = () => {
    animateScroll.scrollToBottom({
      to: "target",
    });
  };
  
  setActiveGroup = (group) =>
  {
    this.setState({activeGroup:group})
  }

  setGroupLoader = (state) =>
  {
    console.log(state)
    this.setState({groupLoader:state})
  }

  setGroupsLength = (length) =>
  {
    // console.log(length)
    this.setState({groupsLength:length})
  }

  ExitGroup = () =>
  {
    this.setState({activeGroup:null,ExitGroupId:this.state.activeGroup.groupId})
  }

  setExitGroup = () =>
  {
    this.setState({ExitGroupId:null})
  }

  ExitGroupFromName = (id) =>
  {
    if(this.state.activeGroup!=null)
    {

      if(this.state.activeGroup.groupId.toString() === id.toString())
      {
        console.log('setting null on state')
        this.setState({activeGroup:null});
      }
    }
    
  }


  render() {
    return (
      <Slide left>
        <Grid container className="SG-Wrapper">
          
          <GroupsContainer setNewMessage={this.setNewMessageGroup} activeGroup={this.state.activeGroup} ExitGroup={(id)=>this.ExitGroupFromName(id)} newMessage={this.state.newMessageGroup} setExitGroup={this.setExitGroup} ExitGroupId={this.state.ExitGroupId} setActiveGroup={(group)=>this.setActiveGroup(group)} setGroupLoader={(state)=>this.setGroupLoader(state)} setGroupsLength={(length)=>this.setGroupsLength(length)}/>
          <GroupChatContainer setNewMessage={this.setNewMessageChat} newMessage={this.state.newMessageChat} ExitGroup={this.ExitGroup} scrollToBottom={this.scrollToBottom} activeGroup={this.state.activeGroup} groupLoader={this.state.groupLoader} groupsLength={this.state.groupsLength}/>
          <Link to="target"></Link>

         
        </Grid>
      </Slide>
    );
  }
}
const style = (theme) => ({
  paper: {
    maxHeight: "calc(100vh - 600px)",
  },
});

const mapStateToProps = (state) => {
  return {
    userId: state.setUser.userId,
    userName: state.setUser.userName,
    HerokuHost: state.setHerokuHost,
    token: state.setUser.token,
    date: state.setDate.date,
  };
};

export default connect(mapStateToProps, null)(withStyles(style)(StudyGroups));
