import React, { Component } from "react";
import "./header.css";
import { Button, LinearProgress, AppBar, Avatar, Grid, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import GetData from "../../user/fetchDataGet";
import { TransverseLoading } from "react-loadingg";
import { Menu } from "@material-ui/icons";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import Badge21 from "../../assets/shieldShape21.png";
import Badge90 from "../../assets/shieldShape90.png";
// import { setChallenge } from "../../store/actions";

import { connect } from "react-redux";
import { compose } from "redux";
import PostData from "../../user/fetchDataPost";
import { setProgress,setUser,setChallenge } from "../../store/actions";
import ForgetModal from "./forgetModal";
import PutData from "../../user/fetchDataPut";


const TrackerLinearProgress = withStyles((theme) => ({
  root: {
    height: 16,
    borderRadius: 10,
    width: 420,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 10,
    backgroundColor: "#225B76",
  },
}))(LinearProgress);

const drawerWidth = 276;

const styles = (theme) => ({
  headerAppbar: {
    backgroundColor: "white !important",
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    [theme.breakpoints.down("xs")]: {
      width: `calc(100% - 100px)`,
      marginLeft: 0,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
});

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: "--",
      caption: null,
      completed: null,
      total: null,
      challenge: 'null',
      loading: false,
      badges:[],
      progressDate:null,
      backdrop:false,
    };
  }

  componentDidMount() {
    if (this.props.userType === "User" ) {
      PostData(this.props.HerokuHost+'/account/challenge/badges',{},200,this.props.token,this.setBadges,this.onFailed)
    }
  }

  componentDidUpdate(prevProps)
  {

    if(this.props.challenge!==prevProps.challenge)
    {
      this.setState({challenge:this.props.challenge})  
    }
  }

  setBadges = (response) =>
  {
    if (response.responseCode==="Success")
    {
      // console.log(response)
      this.setState({badges:response.response.badges})
    }

    else 
    {
      alert("Unable to get Badges!")
      console.log('error ion badges response =>', response)
    }

  }

  setProgress = (response) => {
    // console.log(response);

    if (response.response) {
      let progress = response.response.progress;
      if(progress)
      {

        let progressDate=progress.progressDate;  
        this.setState(
        {
          loading: false,
        });

        // this.props.setProgress(progress); 
        let Newuser = this.props.user;
        Newuser['lastActivityOn']=progressDate;
        Newuser['progress']=progress;
        this.props.setUser(Newuser)
        this.setCookie('user',JSON.stringify(Newuser),30)
      } 

      else {
        this.setState(
        {
          loading: false,
        });
      }
     
     
    } else if (response.validationErrorsList.length > 0)
      {
        this.setState({ loading: false });
        alert(response.validationErrorsList[0].errorMessage);
        console.log("validation error response on header => ", response);
      }
      else
      {
        this.setState({ loading: false });
        alert("Unknown error occured!");
        console.log("unknown error response on header => ", response);
      }
  };

  setCookie = (cname, cvalue, exmins) => {
    var d = new Date();
    d.setMinutes(d.getMinutes() + exmins);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }


  closeForgetModal = (state) =>
  {
    // console.log(state)
    if(state==="continue")
    {
      // console.log(this.props.date)
      // this.setState({progressDate:this.props.date})

      
    }
    else if(state==="restart")
    {
      // console.log(this.props.date)
      // this.setState({progressDate:this.props.date})
      this.props.history.push('/progress')
    }
    this.setState({ forgetModal: false });
  } 

  // openEndChallengeModal = () => this.setState({ endProgressModal: true });



  CompletedTrack = () =>
  {
    this.setState({ loading: true });

    // let todayinUtc =  new Date(this.props.date);
    PostData(
      this.props.HerokuHost + `​/account/challenge/progress/${this.props.date}`,
      {},
      200,
      this.props.token,
      this.setProgress,
      this.onFailed
    );
  }

  checkTrackProgressToday = () =>
  {
    let date = this.props.lastActivityOn;
    let TodaysDate = new Date().toLocaleDateString().replace(/[/]/gi,'-');
    let propsDate = new Date(this.props.actualDate).toLocaleDateString().replace(/[/]/gi,'-');

    // console.log('props.actudalDate ',this.props.actualDate)
    // console.log('TodaysDateCheck ',TodaysDateCheck)

    // return false;


    

    
//to not give user access for tracking progress when not on current date
    if(propsDate !== TodaysDate) 
    {
      return false;
    }

    if(!date)
    {
      return true;
    }




    // let date = progress.progressDate;
   
    // console.log('progressDate =>', date);

    if(date==null)
    {
      return true;
    }

    else 
    {
      // let removedUtcTime = date.replace(/[+]00:00/gi, "")
      // let progressDate =   new Date(new Date(removedUtcTime).toLocaleString() + " UTC").toLocaleDateString();

      let d1 = new Date(this.props.date);
      let d2 = new Date(date);

      // console.log('d1: ',d1 )
      // console.log('d2: ',d2 )

      let year1=d1.getFullYear();
      let year2=d2.getFullYear();


      let month1=d1.getMonth();
      let month2=d2.getMonth();

      let date1=d1.getDate();
      let date2=d2.getDate();

      // console.log(d1)
      // console.log(d2)

      if(year1 > year2)
      {
        // console.log('year true')
        return true;
      }

      else if(year2 > year1)
      {
        // console.log('year false')
        return false;
      }

      else if (year2 === year1)
      {
        if(month1 > month2)
        {
          // console.log('month true')

          return true;
        }
        else if(month2 > month1)
        {
          // console.log('month false')
          return false;
        }
        else if (month1 === month2)
        {
          if(date1>date2)
          {
            // console.log('date1' , date1)
            // console.log('date' , date2)



            return true;
          }

          // console.log('date false')

          return false; //date1===date2 && date2>date1
        }
      }

      return false;

    }
  }

  checkForget = () => {

    let date = this.props.lastActivityOn;
    
    // console.log(date);
    
    
    if(date==null)
    {
      this.CompletedTrack();
    }

    else 
    {

      // let removedUtcTime = date.replace(/[+]00:00/gi, "")
      // let progressDate =   new Date(new Date(removedUtcTime).toLocaleString() + " UTC").toLocaleDateString();

      let d1 = new Date(this.props.date);
      let d2 = new Date(date);

      // console.log('d1: ',d1 )
      // console.log('d2: ',d2 )

      let month1=d1.getMonth();
      let month2=d2.getMonth();

      let date1=d1.getDate();
      let date2=d2.getDate();

      
      if(month1>month2)
      {
        const diffTime = Math.abs(d1 - d2);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

        if(diffDays > 1)
        {
          this.setState({forgetModal:true})
        }

        else
        {
          this.CompletedTrack();
        }


        
      }

      else if(date1 - date2 > 1)
      { 
        this.setState({forgetModal:true})
      }

      else 
      {
        this.CompletedTrack();
      }

    }
  };

  checkForgetRender = () =>
  {
    let date = this.props.lastActivityOn;
    let TodaysDate = new Date().toLocaleDateString().replace(/[/]/gi,'-');
    let propsDate = new Date(this.props.actualDate).toLocaleDateString().replace(/[/]/gi,'-');

    
    // console.log(date);

    if(this.props.challenge != null)
    {
      return false;
    }
    
    
    else if(date==null)
    {
      return false;
    }

//to not give user access for tracking progress when not on current date
    else if(propsDate !== TodaysDate)
    {
      return false;
    }

    else 
    {

      // let removedUtcTime = date.replace(/[+]00:00/gi, "")
      // let progressDate =   new Date(new Date(removedUtcTime).toLocaleString() + " UTC").toLocaleDateString();

      let d1 = new Date(this.props.date);
      let d2 = new Date(date);

      // console.log('d1: ',d1 )
      // console.log('d2: ',d2 )

      let month1=d1.getMonth();
      let month2=d2.getMonth();

      let date1=d1.getDate();
      let date2=d2.getDate();

      


      
      if(month1>month2)
      {
        // this.setState({forgetModal:true})
        // console.log('here')
        // console.log('month1 ' ,month1)
        // console.log('month2 ' ,month2)

        const diffTime = Math.abs(d1 - d2);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

        if(diffDays > 1)
        return true;

        else return false;
      }

      else if(date1 - date2 > 1)
      { 
        // this.setState({forgetModal:true})
        return true;
      }

      else 
      {
        // this.CompletedTrack();
        return false;
      }

    }
  }

  onFailed = (response) => {
    console.log("error on tracker header => ", response);
    this.setState({ loading: false });
    alert("Error Completing Today");
  };

  CheckZero = (number) => {
    if (number <= 9) {
      return "0" + number;
    } else return number;
  };

  Continue = () =>
  {
    this.setState({ backdrop: true });
    PostData(this.props.HerokuHost+`/account/challenge/progress/${this.props.date}/continue`,{},200,this.props.token,this.setContinue,this.onFailed)
  }

 
  setContinue = (response) => {
    // console.log(response);

    if (response.response) {
      let progress = response.response.current;
      if(progress)
      {

        this.setState({ backdrop: false });


        // this.props.setProgress(progress); 
        let NewUser = this.props.user;
        NewUser['lastActivityOn']=response.response.lastActivityOn;
        NewUser['progress']=progress;

        this.props.setUser(NewUser)
        this.setCookie('user',JSON.stringify(NewUser),30)
      } 

      else {
        this.setState(
        {
          loading: false,
        });
      }
     
     
    } else if (response.validationErrorsList.length > 0)
      {
        this.setState({ loading: false });
        alert(response.validationErrorsList[0].errorMessage);
        console.log("validation error response on header => ", response);
      }
      else
      {
        this.setState({ loading: false });
        alert("Unknown error occured!");
        console.log("unknown error response on header => ", response);
      }
  };

  Restart = () =>
  {
    this.setState({ backdrop: true });
    let date = new Date().toLocaleDateString().replace(/[/]/g,"-")
    PutData(this.props.HerokuHost+`/account/challenge/end/${date}`,{},200,this.props.token,this.setRestart,this.onFailed)

    // PutData(props.HerokuHost+'/account/challenge/restart',{},200,props.token,setRestart,onFailed)
  }

  setRestart = (response) =>
  {
    if(response.responseCode==="Success")
    {
    
      let NewUser =  this.props.user;
      NewUser['challenge'] = response.response.challenge;

      let progress = this.props.progress;
      progress['completed']=null;
      progress['total']=null;

      NewUser['progress'] = progress;

      this.props.setUser(NewUser)          
      this.setCookie('user',JSON.stringify(NewUser),30);
      this.props.history.push('/progress')
      this.setState({ backdrop: false });

    }

    else
    {
      alert('Something Went Wrong, Please try again');
      console.log('error on restart response => ',response)
      this.setState({loading:false})
    }
  }

  // allowTrackProgressComplete = () =>
  // {
  //   if(challenge===null)
  //   {
      
  //   }
  // }

  render() {
    const { classes } = this.props;
    // console.log('check progress today =>' ,this.checkTrackProgressToday())
    const allowTrackProgress = (this.props.challenge == null) && this.checkTrackProgressToday();
    // let date = this.props.progressDate;
    // let removedUtcTime = date.replace(/[+]00:00/gi, "")
    // var generatedTime =   new Date(new Date(removedUtcTime).toLocaleString() + " UTC");
    // console.log('progressDate =>', generatedTime.toLocaleDateString(undefined,'dd-MM-yyyy'));
    // console.log("today's date =>",this.props.date)
    return (
      //   <div className="header-wrapper">
      <AppBar position="static" className={classes.headerAppbar} elevation={0}>
        <Backdrop className={classes.backdrop} open={this.state.backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <ForgetModal open={this.state.forgetModal} onClose={(state=null)=>this.closeForgetModal(state)} />
        {/* {console.log(this.props.user)} */}

        <IconButton onClick={() => this.props.openDrawer(true)} className="header-hamburger" size="medium">
          <Menu />
        </IconButton>
        <div className="header-toolbar">
          <Grid container direction="row" spacing={0}>
            <Grid item xs={12} sm={12} md={7} lg={6} xl={5}>
              <div className="header-avatar-div">

                {
                  this.state.badges.map((badge,id)=>
                  <div key={id} className={badge.challenge.days===21?"header-Badge21-div":"header-Badge90-div"}>
                    <div className={badge.challenge.days===21?"Badge21-text":"Badge90-text"} > x{badge.total} </div>
                    <img alt="21 Badge" src={badge.challenge.days===21?Badge21:Badge90} className="header-Badge" />
                  </div>
                  )
                }
                
                <Avatar alt="Image" src={this.props.photoUrl} className="header-avatar" />
              </div>
              <div className="header-user-name" variant="h6">
                Welcome, <br /> {this.props.userName.split(" ")[0]} <br /> {this.props.userName.split(" ")[1]}
              </div>
            </Grid>

            {this.props.userType === "User" ? (
              <Grid item xs={12} sm={12} md={5} lg={6} xl={5} className="header-tracker-div-wrapper">
                <div className="header-tracker-div">
                  <div className="header-tracker-upper-div">
                    <div className="header-tracker-upper-div-1">Tracker</div>
                    <div className="header-tracker-upper-div-2">

                      {this.checkForgetRender() === true ?
                      <React.Fragment>
                          <Button variant="contained" onClick={this.Continue} style={{borderRadius:'0px',background:'#E25601',color:'#ffffff'}}>
                            Continue
                          </Button>
                          <Button variant="contained" onClick={this.Restart} style={{borderRadius:'0px',background:'#225B76',color:'#ffffff'}}>
                            Restart
                          </Button>
                      </React.Fragment>
                        
                      :
                      <Button
                        id="header-tracker-upper-div-2-button"
                        variant="contained"
                        onClick={this.checkForget}
                        style={{ opacity: allowTrackProgress===false ? 0.4 : 1,background:allowTrackProgress===false?'grey':'' }}
                        disabled={ allowTrackProgress===false || this.state.loading ? true : false}>
                        {this.state.loading ? <TransverseLoading color="#fff" size="large" /> : "I COMPLETED TODAY"}
                      </Button>
                      }
                    </div>
                  </div>
                  <div className="header-tracker-middle-div">
                    <TrackerLinearProgress
                      variant="determinate"
                      value={ this.props.progress && this.props.progress!==null ?  ( ((this.props.progress.completed * 100) / this.props.progress.total) || 0) : 0}
                    />
                    {this.props.progress? 
                        this.props.progress.completed!=null ? 
                        <div
                          className="header-tracker-circle-indicator"
                          style={{
                            left: `calc(${(this.props.progress.completed * 100) / this.props.progress.total}% - 6px)`,
                          }}>
                          {" "}
                          
                          <span className="header-tracker-circle-indicator-text"> {this.props.progress.completed} </span>{" "}
                        </div>
                        :null
                      :null
                    
                    }
                  
                  </div>
                  <div className="header-tracker-bottom-div">
                    {this.props.progress?
                      this.props.progress.completed!=null ? (
                        this.checkForgetRender() === true?

                        <span className="header-tracker-bottom-div-forget">
                          It looks like you've missed some time with God <br/>
                          Would you like to continue your current challenge or start over?
                        </span>
                        
                        :
                        <React.Fragment>
                          You've completed Day {this.props.progress.completed} of {this.props.progress.total} <br />
                          {parseInt(this.props.progress.completed)>0? "Keep up the good work!" : null}
                        </React.Fragment>
                      ) : (
                        null
                      )
                    :null}
                  </div>
                </div>
              </Grid>
            ) : null}
          </Grid>
        </div>
      </AppBar>
      //   </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // userId: state.setUser.userId,
    user:state.setUser,
    userType: state.setUser.memberType,
    userName: state.setUser.name,
    lastActivityOn:state.setUser.lastActivityOn,
    photoUrl: state.setUser.photoUrl,
    HerokuHost: state.setHerokuHost,
    date: state.setDate.date,
    token: state.setUser.token,
    challenge:state.setUser.challenge,
    actualDate:state.setDate.actualDate,
    progress:state.setUser.progress,

    // progressDate:state.setUser.progressDate,
    // completed:state.setUser.progress.completed,
    // total:state.setUser.progress.total,

  };
};

export default compose(connect(mapStateToProps,{setProgress,setUser,setChallenge}), withStyles(styles))(Header);
