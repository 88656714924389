import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, TextField } from "@material-ui/core";
import { TransverseLoading } from "react-loadingg";
import PostData from "../../user/fetchDataPost";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import "../../user/listen/listen.css";

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
    marginTop: "20px",
  },
});

class AddAudio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAudio: null,
      loading: false,
      disableUpload: true,
      error1: false,
      error1Message: "",
      // narrationType: "",
      title: "",
    };
  }

  handleChange = (e) => {
    console.log(e.target);
    if (this.state.selectedAudio != null) {
      this.setState({
        [e.target.id || e.target.name]: e.target.value,
        // disableUpload: false,
      });
    } else this.setState({ [e.target.id || e.target.name]: e.target.value });

    this.handleCheckError(e);
  };

  handleCheckError(e) {
    if (e.target.id) {
      if (e.target.id.toString() === "title") {
        if (e.target.value.length > 2) {
          this.setState({ error1: false, error1Message: "", disableUpload: this.state.selectedAudio ? false : true });
        } else {
          this.setState({ error1: true, error1Message: "atleast 3 characters", disableUpload: true });
        }
      }
    }
    // else if (this.state.title.length > 2 && this.state.selectedAudio) {
    //   this.setState({ error1: false, error1Message: "", disableUpload: false });
    // }
  }

  handleUpload = async () => {
    this.setState({ loading: true });
    let fd = new FormData();
    fd.append("file", this.state.selectedAudio);
    await PostData(
      this.props.HerokuHost + "/contents/audio/upload",
      fd,
      200,
      this.props.token,
      (data) => {
        console.log(data.response.url);
        this.audioContentUpload(data.response.url);
      },
      this.onFailed,
      true
    );
  };

  audioContentUpload = (audioUrl) => {
    const newAudio = {
      url: audioUrl,
      // narationType: this.state.narrationType,
      title: this.state.title,
      // postDate: this.props.date,
      //   artist: "",
      //   artWork: "",
      //   duration: 0,
    };

    console.log(newAudio);

    PostData(
      this.props.HerokuHost + `/contents/speech/${this.props.date}`,
      newAudio,
      200,
      this.props.token,
      (data) => {
        console.log(data);
        this.setState({ loading: false });
        this.props.handleAddState(false);
      },
      this.onFailed
    );
  };

  handleAudioChange = (e) => {
    const audio = e.target.files[0];
    audio && this.setState({ selectedAudio: audio, disableUpload: this.state.title.length > 2 ? false : true });
    // if (this.state.narationType != null || this.state.narrationType != "") {
    //   this.setState({ selectedAudio: audio, disableUpload: false });
    // } else this.setState({ selectedAudio: audio });
  };

  onFailed = (response) => {
    console.log(response);
    this.setState({ loading: false });
    alert("Failed response");
  };

  render() {
    // const { classes } = this.props;
    return (
      <>
        <div>
          <label class="custom-file-upload">
            <input type="file" name="audio" onChange={this.handleAudioChange} accept="audio/*" />
            Select Audio
          </label>
        </div>
        <div style={{ marginTop: "20px", marginBottom: "10px" }}>
          <TextField
            label="Title"
            error={this.state.error1}
            helperText={this.state.error1Message}
            id="title"
            onChange={this.handleChange}
            value={this.state.title}
          />
        </div>

        <div className="Listen-AddAudio-Name">
          {this.state.selectedAudio ? this.state.selectedAudio.name : "No Audio Selected"}
        </div>
        <Button
          id="L-Upload-Audio-Button"
          variant="contained"
          onClick={this.handleUpload}
          style={{
            padding: this.state.loading ? "23px 0" : "11px 0",
            backgroundColor: this.state.disableUpload ? "rgb(226, 86, 1,0.5)" : "rgb(226, 86, 1)",
          }}
          disabled={this.state.disableUpload || this.state.loading}>
          {this.state.loading ? <TransverseLoading color="#ffffff" size="large" /> : "Upload Audio"}
        </Button>
        <div className="listen-addaudio-back-div">
          <Button
            id="GD-Back-Button"
            variant="contained"
            onClick={() => this.props.handleAddState(false)}
            style={{ margin: "10px 0" }}>
            Back
          </Button>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    HerokuHost: state.setHerokuHost,
    token: state.setUser.token,
    date: state.setDate.date,
  };
};

export default compose(connect(mapStateToProps, {}), withStyles(styles))(AddAudio);
