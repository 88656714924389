import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import "./App.css";
import GetRoutes from "./shared/routes";
import history from './helper/history';

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <GetRoutes history={history}/>
      </Router>
    </Provider>
  );
}

export default App;
